import React, { ReactElement, RefObject } from 'react';

import { css, cx } from '@emotion/css';
import { Listbox } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { font, SPACING } from '^/constant/CommonStyles2';
import { ExtractReactProps } from '^/types/utils/extractReactProps';
import { MobxInputValue } from '^/util/MobxInputValue';

export const DefaultRadioRenderer = observer(
  function DefaultRadioRenderer<T extends string | null>(p: {
    item: T,
    inputValue: MobxInputValue<T>,
    carsScrollAreaRef: RefObject<HTMLDivElement> | null,
  }) {
    const { t } = useTranslation();
    const translated = (p.item && t(p.item)) || p.item;
    return (
      <li className={cx(
        font.Body1_Regular,
        css`
          display: flex;
          justify-content: space-between;
          white-space: nowrap;

          @media (max-width: 768px) {
            gap: ${SPACING.spacing8};
          }
        `,
      )}
      >
        {translated}

        {p.inputValue.value === p.item
          ? (
            <img
              alt="checked"
              src="/ic_checkbox/ic_checkbox_n_checked.svg"
              onClick={() => {
                p.inputValue.set(p.item);
              }}
            />
          )
          : (
            <img
              alt="none-checked"
              src="/ic_checkbox/ic_checkbox_n_empty.svg"
              onClick={() => {
                p.inputValue.set(p.item);
                p.carsScrollAreaRef?.current?.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
            />
          )}
      </li>
    );
  },
);
export const RadioGroupInput = observer(
  function RadioGroupInput<
  T extends string | null,
>(p: {
    inputValue: MobxInputValue<T>,
    overrides?: {
      rootProps?: React.HTMLAttributes<HTMLUListElement>,
      optionProps?: Partial<ExtractReactProps<typeof Listbox['Option']>>,
      optionRender?: React.ComponentType<{
        item: T,
        inputValue: MobxInputValue<T>,
      }>,
      carsScrollAreaRef: RefObject<HTMLDivElement> | null,
    },
    choices: Readonly<T[]>,
    disabledItems?: Set<T>,
    carsScrollAreaRef: RefObject<HTMLDivElement> | null,
  }) {
    const OptionRenderOverride = p
      .overrides
      ?.optionRender || DefaultRadioRenderer;
    return (
      <ul
        {...p.overrides?.rootProps}
        className={css`
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 12px;

          @media (max-width: 768px) {
            flex-direction: row;
            justify-content: space-between;

            margin-top: ${SPACING.spacing16};
          }
        `}
      >
        {p.choices.map((choice) => {
          return (
            <OptionRenderOverride
              key={choice}
              item={choice}
              inputValue={p.inputValue}
              carsScrollAreaRef={p.carsScrollAreaRef}

            />
          );
        })}
      </ul>
    );
  });
