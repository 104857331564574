import { css } from '@emotion/css';

export const SPACING = {
  spacing8: '8px',
  spacing16: '16px',
  spacing24: '24px',
  spacing32: '32px',
  spacing40: '40px',
  spacing56: '56px',
  spacing64: '64px',
  spacing72: '72px',
  spacing80: '80px',
  spacing96: '96px',
  spacing120: '120px',
  spacing154: '154px',
} as const;

export const COLORS2 = {
  main: {
    MrtBlue1: '#55a9f3',
    MrtBlue2: '#a8d4f9',
    MrtBlack: '#1e1e1e',
    MrtWhite: '#fff',
  },
  sub: {
    MrtGray1: '#9f9f9f',
    MrtGray2: '#cecece',
    MrtGray3: '#f4f4f4',
  },
} as const;

export const font = {
  LargeTitle: css`
    font-family: Pretendard;
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: -0.04px;
    text-align: left;
    color: #1e1e1e;
  `,
  Title1: css`
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.03px;
    text-align: left;
    color: #1e1e1e;
  `,
  Title2: css`
    font-family: Pretendard;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.03px;
    text-align: left;
    color: #1e1e1e;
  `,
  Title3: css`
    font-family: Pretendard;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Headline1_Bold: css`
    font-family: Pretendard;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Headline1_Regular: css`
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Body1_Bold: css`
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Body1_Regular: css`
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.02px;
    text-align: left;
    color: #1e1e1e;
  `,
  Caption1_Bold: css`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
    text-align: left;
    color: #1e1e1e;
  `,
  Caption1_Light: css`
      font-family: Pretendard;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
    text-align: left;
    color: #1e1e1e;
  `,
  Caption2_Bold: css`
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.01px;
    text-align: left;
    color: #1e1e1e;
  `,
  Caption2_Light: css`
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: -0.01px;
    text-align: left;
    color: #1e1e1e;
  `,
} as const;

export const INPUT = {
  hideArrow: css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  overflowEllipsis: css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
  `,
};

export const FLEX = {
  allDirectionCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

export const SCROLL_BAR = {
  hide: css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &:: -webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
  }
  `,
};
