export const PreTagForDebugging: React.FC<
React.HTMLProps<HTMLPreElement> & {
  obj: any,
  isShow: boolean,
}
> = ({
  obj,
  isShow,
  ...props
}) => {
  return (
    <pre
      style={{
        backgroundColor: 'rgb(255, 164, 139)',
        display: isShow ? 'block' : 'none',
        pointerEvents: 'none',
      }}
      {...props}
    >
      {
        JSON.stringify(obj, null, 2)
      }
    </pre>
  );
};
