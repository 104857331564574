import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { AppContext } from '^/AppContext/AppContext';
import { MobxValue } from '^/util/MobxValue';

import { ListingPage2ViewModel } from './ListingPage2.viewmodel';
import { ListingPage2Desktop } from './ListingPage2Desktop';
import { ListingPage2Mobile } from './ListingPage2Mobile';
import { ListingPage2RouteParams } from './ListingPage2RouteParams';

export const ListingPage2Wrapper: React.FC<{
  appContext: AppContext,
} & ListingPage2RouteParams> = observer(function ListingPage2Wrapper(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const {
    appContext,
    ...routeParams
  } = props;
  console.error('ListingPage2Wrapper:: routeParams:', routeParams);

  const {
    steps,
    ...routeParamsWithoutStep
  } = routeParams;
  const viewmodel = useMemo(
    () => new ListingPage2ViewModel(
      appContext,
      routeParams,
      13,
      isMobile,
      undefined,
      routeParams.freshness,

    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      routeParams.pLocationId,
      routeParams.rLocationId,
      routeParams.pDatetime,
      routeParams.rDatetime,
      routeParams.birthday,
      routeParams.freshness,
    ],
  );

  useEffect(() => {
    viewmodel
      .carListingSearchBox
      .stepManager
      .updateRouteParams(routeParams);
  }, [steps]);
  // useEffect(() => {
  //   viewmodel.setRouteParams(
  //     routeParams,
  //   );
  // }, [routeParams]);

  if (isMobile) {
    return (
      <ListingPage2Mobile
        viewmodel={viewmodel}
      />
    );
  }

  return (
    <ListingPage2Desktop
      viewmodel={viewmodel}
    />
  );
});
