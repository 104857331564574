import { RefObject } from 'react';

import { css, cx } from '@emotion/css';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { CheckboxesWithAllToggleForFilter } from '^/commonComponents/inputs/CheckboxesWithAllToggleForFilter';
import { RadioGroupInput } from '^/commonComponents/inputs/RadioGroup';
import { MultiRangeSlider } from '^/components/MultiRangeSlider';
import { borders } from '^/constant/CommonStyles';
import { COLORS2, font, SPACING } from '^/constant/CommonStyles2';
import { INITIAL_VEHCLASS } from '^/constant/envConfig';
import type { CarListingSearchBoxViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import {
  FreePermissionType,
  VendorCompanyNameType,
} from '^/types/__BrandedTypes';

import {
  ListingPage2FilterViewModel,
  PriceSortFilter,
} from './ListingPage2.FilterViewModel';
import { ListingPage2LoadedViewModel } from './ListingPage2.LoadedViewModel';
import { defaultDropdown2Styles, ListingFilterDropdown } from './ui/ListingFilterDropdown';
import { ListingSearchFilterCheckButton } from './ui/ListingSearchFilterCheckButton';

const classNames = {
  rootWrapperBasic: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 16px;

    width: 100%;
    margin-top: 30px;
  `,
};
const overriedClass = {
  root: cx(
    defaultDropdown2Styles.root,
    css`
      width: 100%;
      height: ${SPACING.spacing56};
      padding: 0 ${SPACING.spacing24};
    `,
  ),
  titleContainer: cx(
    defaultDropdown2Styles.titleContainer,
    css`
      display: flex;
      align-items: center;
      height: 100%;
    `,
  ),
};
export const ListingPage2DesktopFilter: React.FC<{
  carsScrollAreaRef: RefObject<HTMLDivElement> | null,
  loadedViewModel: ListingPage2LoadedViewModel,
  filterViewModel: ListingPage2FilterViewModel,
  hasDifferentPickupReturnLocations: CarListingSearchBoxViewModel['i']['hasDifferentPickupReturnLocations']
  isOpenCarListingBottomSheetContenArea: boolean;
}> = observer(function ListingPage2DesktopFilter({
  carsScrollAreaRef,
  loadedViewModel,
  filterViewModel,
  hasDifferentPickupReturnLocations,
  isOpenCarListingBottomSheetContenArea,
}) {
  const { t } = useTranslation();
  return (
    <div
      id="ListingPage2DesktopFilter"
      className={cx(
        classNames.rootWrapperBasic,
        hasDifferentPickupReturnLocations.value && css`
          margin-top: 120px;
        `,
        isOpenCarListingBottomSheetContenArea
          ? css`
            `
          : css`
              position: relative;
              z-index: 50;
            `,
        // borders.DEBUG_redBorderDashed,
      )}
    >
      <ListingFilterDropdown
        title="정렬"
        classOverrides={overriedClass}
      >
        <RadioGroupInput
          carsScrollAreaRef={carsScrollAreaRef}
          // eslint-disable-next-line no-underscore-dangle
          choices={PriceSortFilter._def.values}
          inputValue={filterViewModel.priceSortFilter}

        />
      </ListingFilterDropdown>
      <ListingFilterDropdown
        title="금액"
        classOverrides={overriedClass}
      >
        <div
          className={cx(
            css`
              display: flex;
              width: 100%;
              justify-content: space-between;
            `,
            // borders.DEBUG_greenBorderDashed,
          )}
        >
          <p>최소</p>
          <p>최대</p>
        </div>
        <MultiRangeSlider
          classOverrides={{
            root: css`
              width: 100%;
              height: 50px;
              margin-top: 12px;
              position: relative;
            `,
          }}
          possibleMin={loadedViewModel.possiblePriceRange.min || 0}
          possibleMax={loadedViewModel.possiblePriceRange.max || 0}
          minValue={
            filterViewModel.minPrice.value
            || loadedViewModel.possiblePriceRange.min
            || 0
          }
          maxValue={
            filterViewModel.maxPrice.value
            || loadedViewModel.possiblePriceRange.max
            || 0
          }
          onClick={() => {
            carsScrollAreaRef?.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
          onChange={({ min, max }) => {
            if (min > max) return;
            runInAction(() => {
              filterViewModel.minPrice.set(min);
              filterViewModel.maxPrice.set(max);
            });
          }}
        />
        <div
          className={cx(
            css`
              width: 100%;
              display: flex;
              flex-direction: column;

              justify-content: center;

            `,
            // borders.DEBUG_greenBorderDashed,
          )}
        >
          <p className={cx(
            css`
              height: 38px;
              background-color: ${COLORS2.sub.MrtGray3};
              padding: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
            `,
            font.Body1_Regular,
          )}
          >
            {(filterViewModel.minPrice.value
              || loadedViewModel.possiblePriceRange.min
              || '')
              .toLocaleString('ko-kr')}원
          </p>
          <span className={css`
            margin: 0 auto;
          `}
          >~
          </span>
          <p className={cx(
            css`
              height: 38px;
              background-color: ${COLORS2.sub.MrtGray3};
              padding: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
            `,
            font.Body1_Regular,
          )}
          >
            {(filterViewModel.maxPrice.value
              || loadedViewModel.possiblePriceRange.max
              || '')
              .toLocaleString('ko-kr')}원
          </p>
        </div>
      </ListingFilterDropdown>
      <ListingFilterDropdown
        title="브랜드"
        classOverrides={overriedClass}
      >
        <CheckboxesWithAllToggleForFilter
          carsScrollAreaRef={carsScrollAreaRef}
          checked={filterViewModel.vendorsFilter}
          possibleValues={[
            'Hertz' as VendorCompanyNameType,
          ]}
        />
      </ListingFilterDropdown>
      <ListingFilterDropdown
        title="차종"
        classOverrides={overriedClass}
      >
        <CheckboxesWithAllToggleForFilter
          carsScrollAreaRef={null}
          checked={filterViewModel.carClass}
          possibleValues={INITIAL_VEHCLASS}
          overrides={{
            ItemRenderer(p) {
              return (
                <li className={cx(
                  css`
                    display: flex;
                    justify-content: space-between;
                  `,
                  font.Body1_Regular,
                )}
                >
                  <span>{t(p.value)}</span>
                  <ListingSearchFilterCheckButton
                    condition={p.checked.value.has(p.value)}
                    onClick={() => {
                      if (p.checked.value.has(p.value)) {
                        p.checked.value.delete(p.value);
                      } else {
                        p.checked.value.add(p.value);
                      }

                      carsScrollAreaRef?.current?.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      });
                    }}
                  />
                </li>
              );
            },
          }}
        />
      </ListingFilterDropdown>
      <ListingFilterDropdown
        title="주행거리"
        classOverrides={overriedClass}
      >
        <CheckboxesWithAllToggleForFilter
          carsScrollAreaRef={carsScrollAreaRef}
          checked={filterViewModel.freePermission}
          // eslint-disable-next-line no-underscore-dangle
          possibleValues={FreePermissionType._def.values}
        />
      </ListingFilterDropdown>
    </div>
  );
});
