import { css, cx } from '@emotion/css';
import { border } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { borders, textAlign } from '^/constant/CommonStyles';
import {
  COLORS2, FLEX, font, SPACING,
} from '^/constant/CommonStyles2';
import { VENDOR_LOGOS } from '^/constant/envConfig';

import { ListingPage2CardViewModel } from './ListingPage2.card.viewmodel';
import { translateServices } from './translateServices';
import {
  changeToUpperCaseOfWordOnlyFistAlpahbet,
  deleteGrade,
} from './utils';

const classNames = {
  priceBtn: cx(
    font.Caption1_Light,
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      padding: 12px 14px;
      color: ${COLORS2.sub.MrtGray1};
      background-color: ${COLORS2.sub.MrtGray3} !important;
      border-radius: 5px;
      width: 50%;
    `,
  ),
  priceBtnForeignCurrency: cx(
    font.Title3,
    css`
      text-align: center;
      `,
  ),
  priceBtnKoreanCurrency: cx(
    font.Caption2_Light,
    css`
      color: ${COLORS2.sub.MrtGray1};
    `,
  ),
};
export const ListingPage2MobileCard: React.FC<{
  viewmodel: ListingPage2CardViewModel
}> = observer(function ListingPage2MobileCard({
  viewmodel,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = viewmodel;
  const {
    POD,
    PPD,
    core,
    services,
  } = data;

  const convertedModelName = data.modelName
    && deleteGrade(
      changeToUpperCaseOfWordOnlyFistAlpahbet(String(data.modelName)),
    );

  const carSize = core.vehType.size;
  const carType = core.vehType.type;

  return (
    <div className={css`
      background-color: ${COLORS2.main.MrtWhite};
      padding: ${SPACING.spacing16};
      border-radius: 10px;
    `}
    >
      {/* car img */}
      <div
        id="CarListing2_Images"
        className={cx(
          css`
            display: flex;
            flex-direction: column;

            /* position: relative; */
          `,
          // borders.DEBUG_redBorderDashed,
        )}
      >
        <div className={cx(
          // borders.DEBUG_orangeBorderDashed,
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${SPACING.spacing8};
          `,
          font.Body1_Bold,
        )}
        >
          <img
            alt="vendor_logo"
            src={VENDOR_LOGOS[core.company.id]}
            className={[
              css`
              /* position: absolute;
              top: 0; left: 0; */
              width: 44px;
              height: ${SPACING.spacing16};
            `,
              'object-scale-down',
            // borders.DEBUG_blueBorderDashed,
            ].join(' ')}
          />
          <span>
            {`[${t(carSize)}] ${changeToUpperCaseOfWordOnlyFistAlpahbet(carSize)} ${carType !== 'car' ? carType : ''}`}
          </span>
        </div>
        <h3
          className={cx(
            css`
              display: flex;
              justify-content: center;
            `,
            // FLEX.allDirectionCenter,
            font.Caption2_Light,
          )}
        >
          {convertedModelName} {data.orSimilarCar ? '또는 동급차량' : ''}
        </h3>

        <img
          alt="car"
          src={core.image}
          className={[
            css`
              /* margin-left: 10px; */
              width: 147px;
              height: 128px;

              max-height: ${SPACING.spacing120};
              object-fit: contain;
              margin: 0 auto;
            `,
            // css`
            //   width: 20vw !important;
            // `,
            // borders.DEBUG_pinkDashed,
          ].join(' ')}
          onError={(e) => {
            e.currentTarget.src = '/NOT_FOUND_CAR.png';
          }}
        />
        <h4
          className={cx(
            font.Caption2_Light,
            css`
              margin: 0 auto;
              color: ${COLORS2.sub.MrtGray1};
            `,
          )}
        >
          {translateServices(services, t).join(' / ')}
        </h4>
        <div
          id="gotoBookingButtons"
          className={cx(
            css`
              display: flex;
              flex-direction: row;
              gap: ${SPACING.spacing16};

              margin-top: 12px;
              width: 100%;
              * {
                flex-grow: 1;
              }
            `,
            // borders.DEBUG_purpleBorderDashed,
          )}
        >
          { POD && (
            <button
              type="button"
              className={classNames.priceBtn}
              onClick={() => viewmodel.gotoBooking('POD', navigate)}
            >
              <span className={font.Body1_Bold}>
                후불 결제
              </span>
              <span className={classNames.priceBtnForeignCurrency}>
                {POD.payment.foreignCurrency.toLocaleString()} {POD.payment.foreignCurrencyCode}
              </span>
              <span className={classNames.priceBtnKoreanCurrency}>
                약 {POD.payment.domesticPrice?.toLocaleString('ko-KR')} 원
              </span>
            </button>
          )}
          { PPD && (
            <button
              type="button"
              className={classNames.priceBtn}
              onClick={() => viewmodel.gotoBooking('PPD', navigate)}
            >
              <span className={font.Body1_Bold}>
                선불 결제
              </span>
              <span className={classNames.priceBtnForeignCurrency}>
                {PPD.payment.foreignCurrency.toLocaleString()} {PPD.payment.foreignCurrencyCode}
              </span>
              <span className={classNames.priceBtnKoreanCurrency}>
                약 {PPD.payment.domesticPrice?.toLocaleString('ko-KR')} 원
              </span>
            </button>
          )}
        </div>
      </div>

    </div>
  );
});
