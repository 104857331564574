import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { CalendarStateViewModel } from '^/components/calendar2/Calendar.state.viewmodel';
import { CalendarListViewModel } from '^/components/calendar2/List/Calendar.List.viewmodel';
import { CarListingSearchBoxInputViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.InputViewmodel';
import {
  CarListingSearchBoxViewModel,
} from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { getCurrentFreshness } from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';
import { MobxValue } from '^/util/MobxValue';

import { HomePage2RouteParams } from './HomePage2RouteParams';
import { HomeSearchBoxStepManager } from './HomeSearchBoxStepManager';
import { HomeSearchBoxStepManagerDesktop } from './HomeSearchBoxStepManager.Desktop';

export class HomePage2ViewModel {
  constructor(
    public appContext: AppContext,
    public routeParams: MobxValue<HomePage2RouteParams>,
    public isMobile: boolean,
    public calendarsCount: number,

    public calendarPaginationOffset = new MobxInputValue(0),
    public freshness = getCurrentFreshness(),
    public calendarListViewModel = new CalendarListViewModel(
      calendarsCount,
    ),
    public calendarStateViewModel = new CalendarStateViewModel(
      calendarPaginationOffset,
    ),
    public carListingSearchBoxInputVM = appContext
      .carListingSearchBoxInput
      .value || new CarListingSearchBoxInputViewModel(
      appContext,
      freshness,
      calendarStateViewModel,
    ),
    public carListingSearchBox = new CarListingSearchBoxViewModel(
      appContext,
      freshness,
      calendarListViewModel,
      carListingSearchBoxInputVM,
      isMobile
        ? new HomeSearchBoxStepManager(
          routeParams.value,
          carListingSearchBoxInputVM,
          (n) => {
            typeRouter.session.back(n);
          },
        )
        : new HomeSearchBoxStepManagerDesktop(
          routeParams.value,
          carListingSearchBoxInputVM,
        ),
    ),
  ) {
    console.log('HomePage2ViewModel constructor');
    makeAutoObservable(this);
    if (appContext.carListingSearchBoxInput.value === null) {
      appContext.carListingSearchBoxInput.setValue(
        this.carListingSearchBox.i,
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
}
