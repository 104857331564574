import React, { RefObject } from 'react';

import { css, cx } from '@emotion/css';
import formatDate from 'date-fns/format';
import { observer } from 'mobx-react-lite';

import {
  ReactComponent as FilterIcon,
} from '^/assets/icons/ic_filter.svg';
import { borders } from '^/constant/CommonStyles';
import {
  COLORS2, font, INPUT, SPACING,
} from '^/constant/CommonStyles2';
import { CarClassChips } from '^/domainComponents/inputs/CarClassChips';

import { ListingPage2ViewModel } from './ListingPage2.viewmodel';

export const ListingPage2MobileHeader: React.FC<{
  isFilterButtonVisible: boolean,
  viewmodel: ListingPage2ViewModel,
  carClassChipsScrollRef: RefObject<HTMLDivElement>,
}> = observer(function ListingPage2MobileHeader({
  isFilterButtonVisible,
  viewmodel,
  carClassChipsScrollRef,
}) {
  const pickupDate = new Date(
    viewmodel.routeParamsValues
      .pDatetime,
  );
  const pickupDateStr = formatDate(
    pickupDate,
    'MM월 dd일 HH:mm',
  );
  const returnDate = new Date(
    viewmodel.routeParamsValues
      .rDatetime,
  );
  const returnDateStr = formatDate(
    returnDate,
    'MM월 dd일 HH:mm',
  );

  const pickupLocationName = viewmodel
    .routeParamsValues
    .pLocationName;

  const returnLocationName = viewmodel
    .routeParamsValues
    .rLocationName;

  const pickupReturnDiff = viewmodel
    .routeParamsValues
    .pIsDiff;

  const pickupReturnLocationName = pickupReturnDiff
    ? `${pickupLocationName} - ${returnLocationName}`
    : pickupLocationName;

  return (
    <div
      className={cx(
        css`
          position: sticky;
          /* position: fixed; */
          top: 0px;
          height: 141px;
          background-color: ${COLORS2.main.MrtWhite};
          padding: ${SPACING.spacing24};
          padding-top: ${SPACING.spacing16};

        `,
        // borders.DEBUG_purpleBorderDashed,
      )}
    >
      <section
        id="search-filter-box-container"
        onClick={() => viewmodel.carListingSearchBox
          .stepManager
          .pushStep({
            type: '0_none_selected',
          })}
        className={css`
          height: 60px;
          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          /* margin-left: ${SPACING.spacing16}; */
          /* margin-right: ${SPACING.spacing16}; */
          padding: 0 ${SPACING.spacing16};
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
        tabIndex={-1}
        role="button"
      >
        <img
          src="./ic_search.svg"
          alt="search"
          className={css`
            width: 20.3px;
            height: 20.3px;
          `}
        />
        <div
          id="rentalCity"
          className={cx(css`
            flex-grow: 1;
            padding-left: 16px;
            display: flex;
            flex-direction: column;
          `,
          INPUT.overflowEllipsis,
          )}
        >
          <span
            className={cx(
              font.Caption1_Bold,
              INPUT.overflowEllipsis,
              // borders.DEBUG_pinkDashed,
            )}
          >
            {pickupReturnLocationName}
          </span>
          <span className={cx(
            font.Caption2_Light,
            INPUT.overflowEllipsis,
          )}
          >
            {pickupDateStr}
            ~
            {returnDateStr}
          </span>
        </div>
        {
          isFilterButtonVisible
            ? (
              <button
                id="filter"
                type="button"
                onClick={(e) => {
                  viewmodel
                    .carListingSearchBox
                    .stepManager
                    .pushStep({
                      type: 'FILTER',
                    });
                  e.stopPropagation();
                  return false;
                }}
                className={cx(
                  css`
                width: 42px;
                height: 42px;
                background-color: ${COLORS2.sub.MrtGray3} !important;
                border-radius: ${SPACING.spacing8};
                display: flex;
                justify-content: center;
                align-items: center;
              `,
                  // borders.DEBUG_greenBorderDashed,
                )}
              >
                <FilterIcon
                  className={css`
                width: 24px;
                height: 24px;
              `}
                />
              </button>
            )
            : <div></div>
        }

      </section>

      <div
        id="chips"
        className={css`
          margin-top: ${SPACING.spacing16};
        `}
      >
        <CarClassChips
          filterViewModel={viewmodel.filterViewModel}
          carClassChipsScrollRef={carClassChipsScrollRef}
        />
      </div>
    </div>
  );
});
