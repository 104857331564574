export const TermsAndCondition1UsageContents: React.FC<{
  termsItemHeaderStyle?: string;
  termsItemContentsStyle?: string;
}> = ({
  termsItemContentsStyle,
  termsItemHeaderStyle,
}) => {
  return (
    <>
      <h3 className={termsItemHeaderStyle}>
        제 1 조 (목적)
      </h3>
      <p className={termsItemContentsStyle}>
        본 이용 약관은 아이엠에스모빌리티 주식회사(이하 “회사”)가 제공하는 트리모 서비스의 렌터카 상품 예약 서비스를 이용하는 자(이하 “고객”)와 “회사” 사이의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 2 조 (정의)
      </h3>
      <p className={termsItemContentsStyle}>
        본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />① “트리모”라 함은 “회사”가 렌터카 검색, 예약 서비스를 고객에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 재화, 용역을 거래할 수 있도록 설정한 가상의 영업장 또는 회사가 운영하는 웹사이트를 말하며, 아울러 “트리모”를 운영하는 사업자의 의미로도 사용합니다.
        <br />② “서비스”라 함은 구현되는 단말기(PC, 태블릿, 휴대용 단말기 등의 각종 유무선 장치)와 상관없이 브랜드명 “트리모”를 사용하여 “회사”가 제공하는 제반 서비스를 말합니다.
        <br />③ “고객”이라 함은 “트리모”에 접속하여 본 약관에 따라 “회사”와 이용계약을 체결하고 “회사”가 제공하는 “트리모”를 이용하는 자를 의미합니다.
        <br />④ “업체”라 함은 “회사”와 공급계약을 체결하고 “트리모”를 통해 업체가 보유 중인 대여용 차량들의 대여와 차량 이용, 반납 서비스를 제공하는 국내・외 소재한 차량임대업체를 의미합니다.
        <br />⑤“파트너”라 함은 “회사”와 파트너 제휴를 체결하고 고객에게 “회사”의 서비스를 이용할 수 있도록 제공하는 자를 말합니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 3 조 (약관의 명시와 개정)
      </h3>
      <p className={termsItemContentsStyle}>
        ①  본 약관은 트리모 서비스를 이용하고자 하는 모든 고객에 대하여 그 효력을 발생합니다.
        <br />② “회사”는 본 약관의 내용을 “고객”이 쉽게 알 수 있도록 트리모 서비스의 첫 화면에 게시합니다.
        <br />③ “회사”는 필요하다고 인정되는 경우 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)” 등 관련 법규를 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
        <br />④ “회사”가 본 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자로부터 최소한 7일 이전(“고객”에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 일정기간 서비스 초기화면 또는 초기화면과의 연결화면을 통해 공지하고, 제7조 제1항에서 정한 방법으로 “고객”에게 개별 통지합니다. 단, “고객”의 연락처 미기재, 변경 후 미수정 등으로 인하여 개별 통지가 어려운 경우에 한하여 본 항의 공지를 함으로써 개별 통지를 한 것으로 봅니다.
        <br />⑤ “회사”가 전항에 따라 개정약관을 공지 또는 통지하면서 “고객”에게 일정기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 “고객”이 명시적으로 거부의 의사표시를 하지 아니한 경우 “고객”이 개정약관에 동의한 것으로 봅니다.
        <br />⑥ “고객”이 개정약관의 적용에 동의하지 않는 경우 ”고객”은 이용계약을 해지할 수 있습니다. 다만, “고객”의 예약 내역과 결제 내역이 존재하는 경우, 해지에 따른 환불 및 취소 위약금 등의 정산은 “회사”와 체결한 별도의 계약조건에 의하여 결정됩니다.
        <br />⑦ “회사”는 개별 “서비스”에 대해서는 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 별도의 이용약관 및 정책이 우선하여 적용됩니다.
        <br />⑧ 본 약관과 개별 약관에도 정하지 아니한 사항이나 해석에 대해서는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 정부가 제정한 전자 상거래 등에서의 소비자보호 지침 등 관계 법령, 정부 기관의 해석 또는 상관례에 따릅니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 4 조 (이용계약 체결)
      </h3>
      <p className={termsItemContentsStyle}>
        ① 이용계약은 “고객”이 대여 차량의 예약 정보를 확인하고 예약자 정보를 기입한 이후, 예약을 완료하거나 대금을 결제하는 시점에 “서비스 화면”을 통해 본 약관에 동의하여 신청하고, 회사가 이를 승낙함으로써 체결됩니다.
        <br />② “회사”는 다음 각 호에 해당하는 “고객”에 대하여는 그 사유가 해소될 때까지 이용계약 체결의 승낙을 유보 (추가)또는 취소할 수 있습니다.
        <ol className="list-decimal">
          <li>신청자가 본 약관에 의하여 이전에 “고객”의 자격을 상실한 적이 있는 경우, 단 “회사”의 “고객” 재이용 승낙을 얻은 경우에는 예외로 함.</li>
          <li>실명이 아니거나 타인의 명의를 이용한 경우</li>
          <li>허위의 정보를 기재하거나, “회사”가 요청하는 내용을 기재하지 않은 경우</li>
          <li>기타 이용자의 귀책 사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우</li>
        </ol>
        <br />③ 본 조 제1항에 따른 신청에 있어 “회사”는 전문기관을 통한 실명 확인 및 본인인증을 요청할 수 있습니다.
        <br />④ “회사”는 “서비스” 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 “고객”의 이용 신청의 승낙을 유보할 수 있습니다.
        <br />⑤ 본 조 제2항과 제4항에 따라 “고객” 이용 신청의 승낙을 유보한 경우, “회사”는 원칙적으로 이를 승낙 유보의 사유와 함께 고객에게 알리도록 합니다.
        <br />⑥ “회사”는 “고객”에 대해 회사 정책에 따라 등급별로 구분하고 이용 금액, 이용 횟수, “서비스” 메뉴 등을 세분화하여 그 이용에 차등을 둘 수 있습니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 5 조 (“고객”정보의 변경)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “고객”은 대여용 차량 예약 당시 기입한 예약자 정보를 “서비스” 내 예약조회 화면을 통해 언제든지 열람할 수 있습니다. 다만, “서비스” 관리를 위하여 “고객”은 자신의 예약자 정보를 임의대로 수정할 수 없습니다.
        <br />② “고객”은 예약 당시 기재한 사항에 변경이 있을 경우 전자우편, 유선통화 등 기타 방법으로 “회사”에게 그 변경사항을 요청하고 반영 여부를 확인 해야합니다.
        <br />③ 본 조 제2항의 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 6 조 (예약확인 통지 및 예약취소)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”는 “고객”이 렌터카 예약을 완료한 경우 “고객”으로부터 제공받은 이메일 주소를 통해 예약 확인 통지를 합니다.
        <br />② 예약 취소는 웹사이트 내에서 신청해야 하며 취소 시점, 상품에 따라 취소 수수료가 부과될 수 있습니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 7 조 (현지 도착 후 예약 변경)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “고객”은 예약 완료 후 현지에 도착한 이후 예약 변경이 발생할 때, 현지 영업소로 연락하여 변경 가능 여부 및 추가 금액 여부를 확인하여야 합니다.
        <br />② 현장 변경은 예약시 적용된 조건과 다른 기준이 적용될 수 있으며 그에 따라 추가 결제하는 부분에 대해서는 “회사”가 책임지지 않습니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 8 조 (손해배상 및 면책)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”는 “회사”의 고의, 과실로 인하여 “고객”이나 제 3자에게 발생한 손해에 관하여 통상 손해를 그 한도로 하여 배상할 책임이 있으며,
        특별한 사정으로 인한 손해는 “회사”가 그 사정을 알았거나 알 수 있었다는 점에 관한 객관적 증빙이 되었을 경우에
        그 한도에 한하여 배상할 책임이 있습니다.
        단 “회사”가 계약 해지를 통보하며 계약 관계를 해소한 건에 대해서는 배상할 책임이 없습니다. <br />
        ② “고객”이 이 약관의 규정을 위반함으로 인하여 “회사”에 손해가 발생하게 되는 경우
        이 약관을 위반한 “고객”은 “회사”에 발생하는 손해에 관하여 통상손해를 그 한도로 하여 배상할 책임이 있으며,
        특별한 사정으로 인한 손해는 “고객”이 그 사정을 알았거나 알 수 있었을 때에 한하여 배상할 책임이 있습니다. <br />
        ③ “회사”는 현장의 이유로 “고객”이 예약한 차량이 없어 차량 인수가 불가한 경우,
        “고객”에게 예약 금액 전체 환불 및 예약 금액의 10% 혹은 100 USD 중 낮은 금액을 보상합니다. 전체 환불은 “서비스”에서 사전 결제가 이루어진 선불 상품에만 해당합니다. 단, “고객”이 현장에서 기존 예약한 차량과 조건이 다른 차량을 인수한 경우에는 현장 사정 및 차량 변경에 동의했다고 판단, 전체 환불 및 보상은 이루어지지 않습니다. <br />
        ④ “회사”는 다음 각 호의 경우에는 면책됩니다. <br />
        <ol className="list-decimal">
          <li> “회사”는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다. </li>
          <li> “회사”는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다. </li>
          <li> “회사”는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다. </li>
          <li> “회사”는 고객의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다. </li>
          <li> “회사”는 “고객”의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 “고객”이 개인정보 및 전자우편주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다. </li>
          <li> “회사”는 “고객”이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다. </li>
          <li> “회사”는 “고객”이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 “회사”는 “고객”이 서비스를 이용하며 타 “고객”으로 인해 입게 되는 정신적 피해에 대해 보상할 책임을 지지 않습니다. </li>
          <li> “회사”는 “고객”이 “회사”에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다. </li>
          <li> “회사”는 “고객” 상호간 및 “고객”과 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다. 다만 “회사”는 내부 지침에 따라 분쟁의 해결을 위한 중재를 제안하거나, 자율 분쟁 조정 센터를 운영할 수 있습니다. </li>
        </ol>
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 9 조 (“고객“에 대한 통지)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”가 “고객”에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 “서비스” 내 전자우편, 전화, SMS, MMS, 메신저 등에서 1가지 이상의 방법으로 통보할 수 있습니다.
        <br />② “회사”는 “고객” 전체에 대한 통지의 경우 7일 이상 “서비스 화면”의 게시판에 게시함으로써 본 조 제1항의 통지에 갈음할 수 있습니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 10 조 (“회사“의 의무)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”는 관련 법령 및 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며,
        이 약관이 정하는 바에 따라 지속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여 노력합니다.<br />
        ② “회사”는 “고객”이 안전하게 “서비스”를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며
        관련 법령 및 개인정보 취급방침을 공시하고 준수합니다.<br />
        ③ “회사”는 “서비스” 이용과 관련하여 “고객”으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.
        “고객”이 제기한 의견이나 불만 사항에 대해서는 전자우편, 전화, SMS, MMS, 메신저 등에서 1가지 이상 방법을 통하여 “고객”에게 처리 과정 및 결과를 전달합니다.<br />
        ④ “회사”가 제공하는 서비스로 인하여 “고객”에게 손해가 발생한 경우 그러한 손해가 “회사”의 귀책사유에 의하여 발생한 경우에 한하여
        “회사”가 책임을 부담합니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 11 조 (“고객“의 의무 및 책임)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “고객”은 다음 행위를 하여서는 안 됩니다.
        <ol className="list-decimal">
          <li>“고객” 정보 등록 또는 변경 시 허위내용의 등록</li>
          <li>타인의 정보도용</li>
          <li>“회사”가 게시한 정보의 변경</li>
          <li>“회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
          <li>“회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
          <li>“회사” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
          <li>“회사”의 각종 이벤트(쿠폰, 프로모션 등) 서비스를 “회사” 서비스 정책에 맞지 않도록 사용하는 행위</li>
          <li>“회사”의 동의 없이 영리를 목적으로 “서비스”를 사용하는 행위</li>
          <li>기타 불법적이거나 부당한 행위</li>
        </ol>
        ② “고객”은 관련 법규, 본 약관의 규정, 이용 안내 및 “서비스”와 관련하여 공지한 주의사항, “회사”가 통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다. <br />
        ③ “고객”이 본 약관의 규정을 위반하거나 서비스의 이용과정에서 행한 불법행위로 인하여 “회사” 또는 제3자에게 손해가 발생하는 경우, “고객”은 그 손해를 배상하여야 하고, 제3자가 “회사”를 상대로 소송 등 이의를 제기하는 경우 “고객”이 자신의 책임과 비용으로 “회사”를 면책시켜야 합니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 12 조 (“서비스“의 제공 등)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”는 “고객”에게 아래와 같은 “서비스”를 제공합니다.
        <ol className="list-decimal">
          <li>“업체”의 대여용 차량 정보 검색 서비스</li>
          <li>“업체”의 대여용 차량 예약 서비스</li>
          <li>“고객”의 예약내역 조회 서비스</li>
          <li>기타 “회사”가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해 “고객”에게 제공하는 일체의 “서비스”</li>
        </ol>
        ② “서비스”는 연중무휴 1일 24시간 제공함을 원칙으로 합니다. <br />
        ③ “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 “서비스”의 제공을 일시적으로 중단할 수 있습니다. 이 경우 “회사”는 본 약관 제7조[“고객”에 대한 통지]에 정한 방법으로 “고객”에게 통지합니다. 다만, “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다. <br />
        ④ “회사”는 “서비스”의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 “서비스 화면”에 공지한 바를 따릅니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 13 조 (“서비스“의 변경)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 “서비스”를 변경할 수 있습니다
        <br />② “서비스”의 내용, 이용 방법, 이용 시간에 대하여 변경이 있는 경우에는 변경 사유, 변경될 “서비스”의 내용 및 제공 일자 등은 그 변경 전에 해당 “서비스 화면”의 최초 화면에 게시하고 이를 제7조 제1항에서 저한 방법으로 “고객”에게 통지합니다.
        <br />③ “회사”는 무료로 제공되는 “서비스”의 일부 또는 전부를 “회사”의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법규에 특별한 규정이 없는 한 “고객”에게 별도의 보상을 하지 않습니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 14 조 (정보의 제공 및 광고의 게재)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”는 “고객”이 “서비스” 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, 전화, SMS, MMS, 메신저 등의 방법으로 “고객”에게 제공할 수 있습니다. 다만, “고객”은 관련 법규에 따른 거래 관련 정보 및 고객 문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
        <br />② 본 조 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 “고객”의 사전 동의를 받아서 전송합니다. 다만, “고객”의 대여 예약 및 이용 관련 정보와 고객 문의 등에 대한 회신에 있어서는 제외됩니다.
        <br />③ “회사”는 “서비스”의 운영과 관련하여 서비스 화면, 전자우편, “업체”의 대여용 차량을 통해 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 “고객”은 “회사”에게 수신 거절을 할 수 있습니다. “고객”이 해당 광고를 이용하거나 광고주의 판촉 활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 “고객”과 광고주 사이의 문제입니다. 이와 관련한 분쟁 기타 문제에 관하여 “회사”는 귀책사유가 없는 한 책임을 부담하지 않습니다.
        <br />④ ”서비스”의 이용자(“고객”, “비고객” 포함)는 “회사”가 제공하는 “서비스”와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 15 조 (계약해제, 해지 등)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “고객”은 다음 각 호에서 정한 바에 따라 이용계약을 해지할 수 있습니다.
        <br /><span className="pl-4">1.      “고객”은 언제든지 “회사”에 이용계약 해지의 의사를 통지함으로써 이용계약을 해지할 수 있습니다. 단, 해지의사를 통지하기 전 현재 진행 중인 모든 서비스의 거래를 완료하거나 철회 또는 취소하여야 하며, “회사”는 귀책사유가 없는 한 해당 서비스 거래에 관하여 책임을 부담하지 않습니다.</span>
        <br /><span className="pl-4">2.      탈퇴로 인해 발생할 수 있는 사항에 대하여 “회사”는 귀책사유가 없는 한 책임을 부담하지 아니합니다.</span>
        <br /><span className="pl-4">3.      이용계약이 종료되면 “회사”가 이용계약에 기초하여 “고객”에게 부가적으로 제공한 혜택도 함께 종료됩니다.</span>
        <br />② “회사”는 “고객”이 제9조에 규정한 의무를 이행하지 않을 경우 즉시 이용계약을 해지할 수 있습니다.
        <br />③ “고객”이 이용계약을 해지할 경우, 관련법 및 개인정보 취급방침에 따라 “회사”가 “고객” 정보를 보유하는 경우를 제외하고는 해지 즉시 “고객”의 모든 데이터는 소멸됩니다. 단, “고객”이 본 약관의 동의를 철회하는 경우에도 “고객”의 대여용 차량 예약, 결제, 이용 및 반납 내역은 소멸되지 않고 유지됩니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 16 조 (이용제한 등)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”는 “고객”이 본 약관의 의무를 위반하거나 “서비스”의 정상적인 운영을 방해한 경우, 경고, 일시 정지, 영구이용정지 등으로 “서비스” 이용을 단계적으로 제한할 수 있습니다.
        <br />② “회사”는 전항에도 불구하고, “주민등록법”을 위반한 명의도용 및 결제 도용, “저작권법” 및 “컴퓨터프로그램 보호법”을 위반한 불법프로그램의 제공 및 운영방해, “정보통신망법”을 위반한 불법 통신 및 해킹, 악성 프로그램의 배포, 접속 권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 “서비스” 이용을 통해 획득한 어떠한 유형의 혜택은 모두 소멸되며, “회사”는 이에 대해 별도로 보상하지 않습니다.
        <br />③ “회사”는 본 조의 이용 제한 범위 내에서 제한의 조건 및 세부내용은 이용 제한정책 및 개별 “서비스”상의 운영정책에서 정하는 바에 의합니다.
        <br />④ 본 조에 따라 “서비스” 이용을 제한하거나 계약을 해지하는 경우에는 “회사”는 제7조[“고객”에 대한 통지]에 따라 통지합니다.
        <br />⑤ “고객”은 본 조에 따른 이용 제한 등에 대해 “회사”가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 “회사”가 인정하는 경우 “회사”는 즉시 “서비스”의 이용을 재개합니다.
      </p>

      <h3 className={termsItemHeaderStyle}>
        제 17 조 (준거법 및 재판관할)
      </h3>
      <p className={termsItemContentsStyle}>
        ① “회사”와 “고객” 간에 발생한 분쟁에 관한 소송의 관할은 당사자 간의 합의에 따르며, 사전 합의된바가 없는 경우에는 민사소송법의 관할 규정에 따릅니다.<br />
        ② “회사”와 “고객”간에 제기된 소송에는 대한민국법을 적용합니다.
      </p>
      <p className={termsItemContentsStyle}>
        트리모 이용약관 버전번호: 1.2
        <br />트리모 이용약관 적용일자: 2023년 02월 10일
      </p>
    </>
  );
};
