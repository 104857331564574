import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const locationsInfoSample = [
  {
    // title: 'Pick-up location',
    title: 'pickUpLocation',
    location: 'Los Angles airport',
    des: 'Outside the airport Accessible by free bus',
    time: '16 Apr 2022 10:00',
  },
  {
    // title: 'Drop-off location',
    title: 'dropOffLocation',
    location: 'Los Angles airport',
    des: 'Outside the airport Accessible by free bus',
    time: '19 Apr 2022 10:00',
  },
];

const LocationDetails = ({ info, des = false }) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-2 text-neutral-300 pb-8'>
      <h4 className='text-primary-gray font-bold'>{t(info.title)}: {info.location}</h4>
      {des && <p className='text-secondary-green'>{info.des}</p>}
      <p>{t('date')}: {info.time}</p>
    </div>
  );
};

LocationDetails.propTypes = {
  info: PropTypes.object,
  des: PropTypes.bool,
};

const Location = ({ des = false, locationsInfo = locationsInfoSample }) => {
  return (
    <>
      <div className='flex tablet:gap-4 relative gap-2'>
        <div
          className={`w-4 h-4 bg-secondary-green rounded-full 
                      after:absolute after:h-full after:w-[3px] 
                      after:bg-secondary-green tablet:after:left-[6px] after:left-[5px]`}
        ></div>
        <LocationDetails info={locationsInfo[0]} des={des} />
      </div>
      <div className='flex tablet:gap-4 gap-2'>
        <div className='w-4 h-4 p-px'>
          <div className='w-full h-full bg-secondary-blue rounded-full'></div>
        </div>
        <LocationDetails info={locationsInfo[1]} des={des} />
      </div>
    </>
  );
};

Location.propTypes = {
  des: PropTypes.bool,
  locationsInfo: PropTypes.array,
};

export default Location;
