import React, { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { TextInput } from '^/commonComponents/inputs/TextInput';
import { borders } from '^/constant/CommonStyles';
import {
  COLORS2, font, INPUT, SPACING,
} from '^/constant/CommonStyles2';

import { CarListingSearchBoxViewModel } from '../CarListingSearchBox.viewmodel';
import { classNames } from '../components/CarListingSearchBox.contentsArea.style';

function getStyleByStatus(
  viewmodel: CarListingSearchBoxViewModel,
) {
  if (viewmodel.birthdayAgeValidation.status === 'failure') {
    return css`
      border: 1px solid red;
    `;
  }
  if (viewmodel.stepManager.currentStep?.type === '4_birthday') {
    return css`
      border: 1px solid ${COLORS2.main.MrtBlue1};
      color: ${COLORS2.main.MrtBlack};
    `;
  }
  return '';
}
export const BirthDayInput: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  title?: React.ReactNode,
  onClickBirthDayInput?: () => void,
}> = observer(function BirthDayInput({
  viewmodel,
  title = '운전자 생년월일',
  onClickBirthDayInput,
}) {
  const birthdayInputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (viewmodel.stepManager.currentStep?.type === '4_birthday') {
      birthdayInputRef.current?.focus();
    }
  }, [viewmodel.stepManager.currentStep]);

  return (
    <div className={cx(
      classNames.common.searchBoxInputWrapper,
      css`
      display: flex;
      gap: 12px;
  `,
      // borders.DEBUG_blueBorderDashed,
    )}
    >
      <div className={classNames.common.searchBoxInputTitle}>
        {title}
      </div>
      <div
        onClick={onClickBirthDayInput}
        className={cx(
          css`
            position: relative;
          `,
          // borders.DEBUG_greenBorderDashed,
        )}
      >
        <TextInput
          rootRef={birthdayInputRef}
          maxLength={6}
          onBlur={() => { viewmodel.stepManager.clearStep(); }}
          className={cx(
            classNames.common.searchBoxInputs,
            INPUT.hideArrow,
            INPUT.overflowEllipsis,
            css`
              outline: none;
              background-color:${COLORS2.sub.MrtGray3};
              padding: ${SPACING.spacing16};
              border-radius: 5px;
              width: 100%;

              &::placeholder {
                color: ${COLORS2.sub.MrtGray1};
              }
              /* &:focus &:focus-within { */
              &:focus-within {
                background-color: ${COLORS2.main.MrtWhite};
                border: 1px solid ${COLORS2.main.MrtBlue1};
                color: ${COLORS2.main.MrtBlack};
              }
            `,
            font.Body1_Bold,
            // viewmodel.ageInput.isFocused
            getStyleByStatus(viewmodel),
          )}
          placeholder={viewmodel.i.birthdayInput.isFocused
            ? 'YYMMDD'
            : '운전자 생년월일'}
          viewmodel={viewmodel.i.birthdayInput}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
        />
        {viewmodel.birthdayAgeValidation.status === 'success'
          && (
            <div
              className={cx(
                css`
                  position: absolute;
                  left: 75px;
                  top: 0px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                `,
                font.Body1_Bold,
              )}
            >
              (만 {viewmodel.birthdayAgeValidation.value.age}세)
            </div>
          )}
        {viewmodel.birthdayAgeValidation.status === 'failure'
          && (
            <div
              className={cx(
                font.Caption1_Light,
                css`
                  position: absolute;
                  color: red;
                  left: 0px;
                  top: 100%;
                  width: max-content;
                `,
              )}
            >
              {viewmodel.birthdayAgeValidation.failure.reason}
            </div>
          )}
      </div>
    </div>
  );
});
