import React, { useRef } from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { MY_RESERVATION_PATH } from '^/constant/routes';

import { ReservationCheckForm } from './ReservationCheck.form';
import {
  loadPhoneEmailFromStorage,
  ReservationCheckPageFormViewModel,
} from './ReservationCheck.form.viewmodel';
import {
  ReservationCheckPageDesktopLayout,
} from './ReservationCheck.page.desktopLayout';
import {
  ReservationCheckPageMobileLayout,
} from './ReservationCheck.page.mobileLayout';


export const ReservationCheckPageWrapper: React.FC<{

}> = observer(function ReservationCheckPageWrapper() {
  const viewmodel = useRef(new ReservationCheckPageFormViewModel());
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (loadPhoneEmailFromStorage()) {
    window.location.pathname = MY_RESERVATION_PATH;
  }

  if (isMobile) {
    return (
      <ReservationCheckPageMobileLayout
        formViewModel={viewmodel.current}
      >
        <ReservationCheckForm
          viewmodel={viewmodel.current}
        />
      </ReservationCheckPageMobileLayout>
    );
  }
  return (
    <ReservationCheckPageDesktopLayout
      formViewModel={viewmodel.current}
    >
      <ReservationCheckForm
        viewmodel={viewmodel.current}
      />
    </ReservationCheckPageDesktopLayout>
  );
});
