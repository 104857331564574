import { makeObservable } from 'mobx';

import {
  decodeRawRentalCarOfficeFromAPI,
} from '^/api2/getRentalCarOfficeLocationsByCity/GetCategoriesByCity.convert';
import { BaseItemsRepository } from '^/BaseListCore/BaseItemRepository';
import {
  LocationCodeTypeRel_OAG,
  RentalCarOfficeId,
} from '^/types/__BrandedTypes';

export type RentalCarOfficeLocationType = ReturnType<typeof decodeRawRentalCarOfficeFromAPI>;

export class RentalCarOfficeLocationRepo
  extends BaseItemsRepository<RentalCarOfficeLocationType, RentalCarOfficeId > {
  constructor(
    public data = new Map<RentalCarOfficeId, RentalCarOfficeLocationType>(),
    public oagCodeToOffice = new Map<LocationCodeTypeRel_OAG, RentalCarOfficeLocationType>(),
  ) {
    super(data);
    makeObservable(this, {
      setById: false,
      getByOAGCode: true,
    });
  }

  public override setById(
    id: RentalCarOfficeId,
    item: RentalCarOfficeLocationType,
  ) {
    this.data.set(id, item);
    this.oagCodeToOffice.set(item.oag_code, item);
  }

  getByOAGCode(oagCode: LocationCodeTypeRel_OAG) {
    return this.oagCodeToOffice.get(oagCode);
  }
}
