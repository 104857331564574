/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  RefObject, useEffect, useRef,
} from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as Search } from '^/assets/icons/ic_search.svg';
import { ReactComponent as SearchGray } from '^/assets/icons/ic_search_gray.svg';
import { InputElemProps } from '^/commonComponents/inputs/TextInput';
import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import { borders } from '^/constant/CommonStyles';
import { COLORS2, SPACING } from '^/constant/CommonStyles2';
import { CitySearchInput } from '^/domainComponents/inputs/CitySearchInput/CitySearchInput';
import { CitySearchInputViewModel } from '^/domainComponents/inputs/CitySearchInput/CitySearchInput.viewmodel';
import { useClickOutsideComponent } from '^/hooks/useClickOutsideComponent';
import { CityCodeType } from '^/types/__BrandedTypes';

import { classNames } from '../components/CarListingSearchBox.contentsArea.style';

export const CitySearchInputButton: React.FC<{
  placeholder: string,
  inputRef?: RefObject<HTMLInputElement>,
  viewmodel: CitySearchInputViewModel,
  title: string | null;
  onSelectCity(cityCode: CityCodeType): void;
  showSuggestions?: boolean;
  inputElemProps?: InputElemProps;
  smaller?: boolean;
  onClickCitySearchInputBtn?: () => void;
}> = observer(function CitySearchInputButton({
  placeholder,
  inputRef,
  viewmodel,
  title,
  onSelectCity,
  showSuggestions,
  inputElemProps,
  smaller,
  onClickCitySearchInputBtn,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const rootElem = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (showSuggestions) {
      // viewmodel.showAutosuggestList.set(true);
    }
  }, [showSuggestions]);
  useClickOutsideComponent(rootElem, () => {
    viewmodel.showAutosuggestList.set(false);
  });
  return (
    <div
      ref={rootElem}
      className={cx(
        classNames.common.searchBoxInputWrapper,
        // borders.DEBUG_blueBorderDashed,
      )}
      tabIndex={-1}
      role="button"
      // onClick={() => {
      //   viewmodel.showAutosuggestList.set(true);
      // }}
      onBlur={(e) => {
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }
        viewmodel.showAutosuggestList.set(false);
      }}
    >
      <div className={cx(
        classNames.common.searchBoxInputTitle,
      )}
      >
        {title}
      </div>
      <PreTagForDebugging
        obj={{
          showSuggestions: viewmodel.showAutosuggestList.value,
        }}
        isShow={false}
      />
      <div
        onClick={onClickCitySearchInputBtn}
        className={cx(
          classNames.common.searchBoxInputs,
          css`
            gap: 12px;
            margin-top :12px;
            width: 100%;
            position: relative;
            box-sizing: border-box;
            /* border-top-left-radius: 5px;
            border-top-right-radius: 5px; */
            /* border-bottom: 5px; */
            /* :focus-within {
              background-color: ${COLORS2.main.MrtWhite};
              border: 1px solid ${COLORS2.main.MrtBlue1};
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            } */
          `,

          viewmodel.showAutosuggestList.value === true
            ? css`
              background-color: ${COLORS2.main.MrtWhite};
              border: 1px solid ${COLORS2.main.MrtBlue1};
            `
            : css`
              background-color:${COLORS2.sub.MrtGray3};
            `,
          viewmodel.showAutosuggestList.value === true
            && viewmodel.searchResultList.status === 'success'
            && viewmodel.searchResultList.value.length === 0
            && viewmodel.searchInput.value
            && css`
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            `,
          viewmodel.showAutosuggestList.value === true
          && (
            viewmodel.searchResultList.status === 'success'
            && viewmodel.searchResultList.value.length > 0
          ) && css`
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          `,
          isMobile && css`
            margin-top: 0;
            width: 100%;
            padding: ${SPACING.spacing16} 12px;
          `,
          smaller && css`
            margin-top: 0;
          `,
          // borders.DEBUG_greenBorderDashed,
        )}
      >
        {!isMobile && (
          <div className={css`
            width: ${SPACING.spacing24};
            height: ${SPACING.spacing24};
          `}
          >
            {(viewmodel.searchInput.value.length > 0
              || viewmodel.searchInput.isFocused)
              ? (
                <Search />
              )
              : (
                <SearchGray />
              )}
          </div>
        )}
        {/* <img
          alt="search-city"
          src={viewmodel.searchInput.isFocused
            ? './ic_search.svg'
            : './ic_search_gray.svg'}
        /> */}
        <CitySearchInput
          placeholder={placeholder}
          {...inputElemProps}
          inputRef={inputRef}
          viewmodel={viewmodel}
          onSelectCity={onSelectCity}
        />
      </div>
    </div>
  );
});
