/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { TextInput } from '^/commonComponents/inputs/TextInput';
import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { Layout2Mobile } from '^/components/Layout/Layout2Mobile';
import Loading from '^/components/Loading';
import { borders } from '^/constant/CommonStyles';
import { COLORS2, SPACING } from '^/constant/CommonStyles2';
import { CarListingBottomSheetWrapper } from '^/domainComponents/CarListingBottomSheet/CarListingBottomSheetWrapper';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { ListingPage2MobileCard } from './ListingPage2.Mobile.Card';
import { ListingPage2MobileHeader } from './ListingPage2.Mobile.Header';
import { ListingPage2ViewModel } from './ListingPage2.viewmodel';
import { ListingPage2MobileFilterBottomSheet } from './ListingPage2MobileFilter.BottomSheet';
import { ListingPage2mobileNoCar } from './ui/ListingPage2.Mobile.NoCar';
import { SkeletonCarInfoMobile } from './ui/Skeleton.CarInfo.Mobile';

export const ListingPage2Mobile: React.FC<{
  viewmodel: ListingPage2ViewModel,
}> = observer(function ListingPage2Mobile({
  viewmodel,
}) {
  const pickupCityInputRef = useRef<HTMLInputElement>(null);
  const pickupCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);
  const returnCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);

  const carsScrollAreaRef = useRef<HTMLDivElement>(null);
  const carClassChipsScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!carsScrollAreaRef.current) return;
    carsScrollAreaRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  },
  [viewmodel.filterViewModel.carClass.value.size]);

  useEffect(() => {
    const unlisten = typeRouter.session.listen((
      nextRoute,
    ) => {
      if (nextRoute.action === 'pop') {
        console.log('detected go back!', nextRoute);
        viewmodel
          .stepManager
          .updatePushCountBy(-1);
      }
    });
    return unlisten;
  });

  return (
    <>
      <TextInput
        id="DummyInputForPickupCityIOSKeyboardPopup"
        rootRef={pickupCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel
          .carListingSearchBoxInputVm
          .pickupCitySearch
          .searchInput}
        className={css`
          position: fixed;
          top:0; left: 0;
          bottom: 0;
          opacity: 0.2;
          z-index: -500;
        `}
      />
      <TextInput
        id="DummyInputForReturnCityIOSKeyboardPopup"
        rootRef={returnCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel
          .carListingSearchBoxInputVm
          .returnCitySearch
          .searchInput}
        className={css`
          position: fixed;
          top:0; left: 0;
          bottom: 0;
          opacity: 0.2;
          z-index: -500;
        `}
      />
      {viewmodel.stepManager.currentStep?.type === 'FILTER'
        && (
        <ListingPage2MobileFilterBottomSheet
          viewmodel={viewmodel}
        />
        )}
      {(viewmodel.carListingSearchBox
        .stepManager
        .getSteps()
        .length || null) && (
        <CarListingBottomSheetWrapper
          viewmodel={viewmodel.carListingSearchBox}
          pickupCityInputRef={pickupCityInputRef}
          pickupCityInputDummyRefForIOSKeyboard={
            pickupCityInputDummyRefForIOSKeyboard
          }
          returnCityInputDummyRefForIOSKeyboard={
            returnCityInputDummyRefForIOSKeyboard
          }
          carsScrollAreaRef={carsScrollAreaRef}
          carClassChipsScrollRef={carClassChipsScrollRef}
        />
      )}

      <Layout2Mobile
        withoutFooter
        withoutOverflowScroll
        withoutNavigationFullRefresh
        classOverrides={{
          layout2MobileContentWrapperBg: COLORS2.sub.MrtGray3,
        }}
      >
        <div
          className={cx(css`
            width: 100%;
            height: 100%;

            background-color: ${COLORS2.sub.MrtGray3};
            display: flex;
            flex-direction: column;`,
            // borders.DEBUG_greenBorderDashed,
          )}
        >
          <ViewByState
            errorView={(e) => {
              if (e.reason === 'Timeout') {
                return (
                  <>
                    <ListingPage2MobileHeader
                      viewmodel={viewmodel}
                      carClassChipsScrollRef={carClassChipsScrollRef}
                      isFilterButtonVisible={viewmodel.allCarList.status === 'success'}
                    />
                    <ListingPage2mobileNoCar />
                  </>
                ); // FIXME: mobile please
              }
              return (
                <h1> 에러: {e.reason} </h1>
              );
            }}
            loadingView={(
              <>
                <ListingPage2MobileHeader
                  viewmodel={viewmodel}
                  carClassChipsScrollRef={carClassChipsScrollRef}
                  isFilterButtonVisible={viewmodel.allCarList.status === 'success'}
                />
                <div
                  className={css`
                    background-color: ${COLORS2.sub.MrtGray3};
                    width: 100%;
                  `}
                >
                  <div className={css`
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    padding: ${SPACING.spacing24};
                  `}
                  >
                    <SkeletonCarInfoMobile />
                    <SkeletonCarInfoMobile />
                    <SkeletonCarInfoMobile />
                  </div>
                  <Loading />
                </div>
              </>
          )}
            data={viewmodel.allCarList}
          >
            {(carsList) => {
              return (
                <>
                  <ListingPage2MobileHeader
                    carClassChipsScrollRef={carClassChipsScrollRef}
                    isFilterButtonVisible={viewmodel.allCarList.status === 'success'}
                    viewmodel={viewmodel}
                  />
                  {carsList.filteredList.length === 0
                    ? <ListingPage2mobileNoCar />
                    : (
                      <section
                        ref={carsScrollAreaRef}
                        className={css`
                          height: 100%;
                          overflow-y: auto;
                          display: flex;
                          flex-direction: column;
                          gap: ${SPACING.spacing24};
                          padding: ${SPACING.spacing24};
                        `}
                      >
                        {carsList
                          .filteredList
                          .filter((b) => b.data.POD?.payment.domesticPrice)
                          .map((c) => {
                            return (
                              <ListingPage2MobileCard
                                key={c.data.core.id}
                                viewmodel={c}
                              />
                            );
                          })}
                      </section>
                    )}
                </>
              );
            }}
          </ViewByState>
        </div>
      </Layout2Mobile>
    </>
  );
});
