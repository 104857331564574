import { css } from '@emotion/css';

import { borders } from '^/constant/CommonStyles';

import { EmptyCar2 } from './EmptyCar2';

export const ListingPage2DesktopNoCar = () => {
  return (
    <div
      id="ListingPageDesktopNoCar"
      className={[
        css`
          /* background-color: #fff; */
          flex-grow: 1;
          /* top: 0; */
          /* left: 0; */

          /* width: 100%;
          height: 100%; */

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `,
        // borders.DEBUG_pinkDashed,
      ].join(' ')}
    >
      <div
        className={css`
          margin-top: 166px;
        `}
      >
        <EmptyCar2 />
      </div>
    </div>
  );
};
