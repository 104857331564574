import { css, cx } from '@emotion/css';
import { useMediaQuery } from 'react-responsive';

import { COLORS2, font, SPACING } from '^/constant/CommonStyles2';

import { WEEK_KO } from '../constants';

export const WeekDays = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (

    <div
      className={cx(
        css`
          display: flex;
          width: 100%;
          color: ${COLORS2.main.MrtBlack}
          
        `,
        isMobile
          ? font.Caption1_Light
          : cx(
            css`
              margin-top: ${SPACING.spacing16};
            `,
            font.Body1_Regular,
          ),
      )}
    >
      {WEEK_KO.map((day) => (
        <div
          key={day}
          className={
            css`
              display: flex;
              justify-content: center;
              width: 14.28%;
            `
          }
        >
          <span
            className={
              css`
                display: flex;
                justify-content:center;
                align-items:center;
              `
            }
          >
            {day}
          </span>
        </div>
      ))}
    </div>
  );
};
