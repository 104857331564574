import { css } from '@emotion/css';

import Loading from '^/components/Loading';
import { SPACING } from '^/constant/CommonStyles2';
import { SkeletonCarInfoDesktop } from '^/pages/Listing/components/Skeleton.CarInfo.Desktop';

export const ListingPageDesktopLoading = () => {
  return (
    <>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: ${SPACING.spacing32};

          max-width: 1320px;
          margin-left: auto;
          margin-right: auto;
        `}
      >
        <SkeletonCarInfoDesktop />
        <SkeletonCarInfoDesktop />
        <SkeletonCarInfoDesktop />
      </div>
      <Loading />
    </>
  );
};
