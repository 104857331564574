/* eslint-disable jsx-a11y/no-static-element-interactions */

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { borders } from '^/constant/CommonStyles';
import {
  COLORS2,
} from '^/constant/CommonStyles2';

import { CarListingSearchBoxViewModel } from './CarListingSearchBox.viewmodel';
import {
  PickupReturnDifferentInputsDesktop,
} from './PickupReturnDifferentInputs.desktop';
import {
  PickupReturnSameInputsDesktop,
} from './PickupReturnSameInputs.desktop';

export const CarListingSearchBoxDesktop: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  onBlur: () => void,
  smaller?: boolean,
}> = observer(function CarListingSearchBoxDesktop({
  viewmodel,
  onBlur,
  smaller,
}) {
  return (
    <div
      tabIndex={-1}
      onBlur={(e) => {
        // see: https://stackoverflow.com/a/60094794/19531789
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }
        if (onBlur) {
          onBlur();
        }
      }}
      className={cx(
        css`
          /* position: absolute; */
          position: relative;
          /* padding: 24px; */
          margin-bottom: 24px;
          width: 100%;
          max-width: 1320px;
          min-height: 80px;

          z-index: 10;
          background-color: ${COLORS2.main.MrtWhite};
          /* box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.1); */
          filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.1));
          border-radius: 10px;

          &:focus-visible {
            outline: none;
          }
        `,
        // borders.DEBUG_redBorderDashed,
      )}
    >
      {
        viewmodel.i.hasDifferentPickupReturnLocations.value
          ? (
            <PickupReturnDifferentInputsDesktop
              viewmodel={viewmodel}
              smaller={smaller}
            />
          )
          : (
            <PickupReturnSameInputsDesktop
              viewmodel={viewmodel}
              smaller={smaller}
            />
          )
      }
    </div>
  );
});
