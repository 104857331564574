import { action, makeObservable } from 'mobx';

import { CarListingSearchBoxInputViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.InputViewmodel';
import { BaseSearchBoxStepManager, SearchBoxSteps } from '^/domainComponents/forms/CarListingSearchBox/SearchBoxStepManager';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { HomePage2RouteParams } from './HomePage2RouteParams';

export class HomeSearchBoxStepManager
  extends BaseSearchBoxStepManager<HomePage2RouteParams> {
  constructor(
    public routeParams: HomePage2RouteParams,
    public carListingSearchInputVM: CarListingSearchBoxInputViewModel,
    public goBackFn: (n?: number) => void,
    public pushCount = 0,
  ) {
    super();
    // eslint-disable-next-line mobx/exhaustive-make-observable
    makeObservable(this, {
      getInputViewModel: true,
      getSteps: true,
      pushStepsToRoute: action.bound,
      replaceStepsInRoute: action.bound,
      updateRouteParams: action.bound,
      guardStep: true,
      pushStep: action.bound,
      clearStep: action.bound,
      replaceStep: true,
      currentStep: true,
      routeParams: true,
      carListingSearchInputVM: true,
      pushCount: true,
    });
  }

  getInputViewModel() {
    return this.carListingSearchInputVM;
  }

  getSteps() {
    return this.routeParams.steps || [];
  }

  updateRouteParams(routeParams: HomePage2RouteParams) {
    this.routeParams = routeParams;
  }

  pushStepsToRoute(newSteps: SearchBoxSteps[]) {
    this.pushCount += 1;
    console.log(
      'HomeSearchBoxStepManager::pushStepsToRoute: pushCount: ',
      this.pushCount,
    );
    typeRouter
      .routes
      .home2({
        ...(this.routeParams || {}),
        steps: newSteps || undefined,
      }).push();
  }

  replaceStepsInRoute(newSteps: SearchBoxSteps[]) {
    try {
      typeRouter
        .routes
        .home2({
          ...(this.routeParams || {}),
          steps: newSteps || [],
        }).replace();
    } catch (e) {
      debugger;
    }
    // }
  }

  clearStep() {
    console.log(
      'HomeSearchBoxStepManager::clearStep()',
      'pushCount: ',
      this.pushCount);
    for (let i = 0; i < this.pushCount; i += 1) {
      this.goBackFn(1);
    }
  }

  updatePushCountBy(n: number): void {
    this.pushCount += n;
  }
}
