import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { font } from '^/constant/CommonStyles2';

import { CalendarStateViewModel } from '../Calendar.state.viewmodel';
import { YearMonth } from '../YearMonth';

const size32px = css`
  width: 32px;
  height: 32px;
`;

export const CalendarHeader: React.FC<{
  yearMonth: YearMonth;
  calendarStateVM: CalendarStateViewModel,
  showPrevButton?: boolean;
  showNextButton?: boolean;
}> = observer(function CalendarHeader({
  yearMonth,
  calendarStateVM,
  showNextButton,
  showPrevButton,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className={css`
      display: flex;
      align-items: center;
    `}
    >
      {showPrevButton ? (
        <input
          type="image"
          alt="이전 달로 가기"
          src="./ic_arrow_left_32px.svg"
          onClick={() => {
            const current = calendarStateVM
              .calendarPaginationOffset
              .value;
            calendarStateVM
              .calendarPaginationOffset
              .set(current - 1);
          }}
          className={size32px}
        />
      )
        : isMobile ? <></> : <div className={size32px} />}
      {/* : <></>} */}
      <h1
        className={cx(
          css`
            width: 100%;
            display:flex;
            margin: 0 auto;

            // background-color: pink;
          `,
          isMobile
            ? css`
                justify-content: start;
                margin-top: 12px;
              `
            : css`
                justify-content: center;
              `,
          font.Body1_Bold,
          !isMobile && font.Headline1_Bold,
        )}
      >
        {yearMonth.year}년 {yearMonth.monthFrom0 + 1}월
      </h1>
      {showNextButton ? (
        <input
          type="image"
          alt="다음 달로 가기"
          src="./ic_arrow_right_32px.svg"
          onClick={() => {
            const current = calendarStateVM
              .calendarPaginationOffset
              .value;
            calendarStateVM
              .calendarPaginationOffset
              .set(current + 1);
          }}
          className={size32px}
        />
      ) : <div className={size32px} />}
    </div>

  );
});
