import { RefObject } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';
import { ViewByState } from '^/commonComponents/ui/ViewByState';

import { CarListingSearchBoxViewModel } from '../forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import { RentalOfficeInput } from '../inputs/RentalOfficeInput/RentalOfficeInput';

import { CarListingBottomSheetSearchConditionHeader } from './ui/CarListingBottomSheet.SearchCondition.Header';

export const Step2RentalOffice: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  pickupCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
}> = observer(function Step2RentalOffice({
  viewmodel,
  pickupCityInputDummyRefForIOSKeyboard,
}) {
  return (
    <CarListingBottomSheetSearchConditionHeader
      citySearchInputPlaceholder={
        viewmodel.i.hasDifferentPickupReturnLocations.value
          ? '대여 도시 선택'
          : '대여/반납 도시 선택'
        }
      viewmodel={viewmodel}
      locationViewModel={viewmodel.pickupLocation}
      citySearch={viewmodel.i.pickupCitySearch}
      isCityInputSelected={false}
      onClickCityInputButton={() => {
        viewmodel.stepManager
          .replaceStep({
            type: '1_rental_city',
          });
        pickupCityInputDummyRefForIOSKeyboard
          .current
          ?.focus();
      }}
      onClickRentalOfficeInputButton={() => {
        // do nothing
        // alert('Step2.rentalOffice onClickRentalOfficeInputButton');
      }}
      onSelectCity={() => {
        // CAN'T HAPPEN...
        viewmodel.stepManager
          .replaceStep({
            type: '2_rental_office',
          });
      }}
      title={viewmodel.i.hasDifferentPickupReturnLocations.value === true
        ? '대여 위치'
        : '대여 / 반납 위치'}
      isRentalOfficeInputSelected
    >
      <div
        className={css`
          height: 100%;
          // background-color: pink;
          margin-top: 12px;
        `}
      >
        <ViewByState
          data={viewmodel.getLocationInputViewModelByCityCode(
            viewmodel.i.pickupCityCode.value,
            viewmodel.i.tempPickupLocationId,
          )}
          loadingView={(
            <BlueLoadingSpinner
              classOverrides={{
                root: css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  width: 100%;
                  margin-bottom: 100px;
                `,
                spinner: css`
                  margin-bottom: 70px;
                  width: 45px;
                  height: 45px;
                `,
              }}
            />
)}
          errorView={() => {
            return <h1> error </h1>;
          }}
        >

          {(pickupVM) => {
            return (
              <RentalOfficeInput
                viewmodel={pickupVM}
                onBlur={() => {}}
                onSelectRentalOffice={() => {
                  viewmodel.stepManager
                    .replaceStep({
                      type: '2_5_selected_rental_office',
                    });
                }}
              />
            );
          }}
        </ViewByState>
      </div>
    </CarListingBottomSheetSearchConditionHeader>
  );
});
