/* eslint-disable jsx-a11y/no-static-element-interactions */
import { observer } from 'mobx-react-lite';

import { CarListingSearchBoxViewModel } from '../forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import { PopularCity } from '../forms/CarListingSearchBox/components/PopularCity';
import { popularCityDefaultStyles } from '../forms/CarListingSearchBox/components/PopularCity.defaultStyles';

import { CarListingBottomSheetSearchConditionHeader } from './ui/CarListingBottomSheet.SearchCondition.Header';

export const Step5ReturnCity: React.FC<{
  viewmodel: CarListingSearchBoxViewModel
}> = observer(function Step5ReturnCity({
  viewmodel,
}) {
  return (
    <CarListingBottomSheetSearchConditionHeader
      citySearchInputPlaceholder={
      viewmodel.i.hasDifferentPickupReturnLocations.value
        ? '반납 도시 선택'
        : '대여/반납 도시 선택'
      }
      rentalOfficeInputPlaceHolder={
        viewmodel.i.hasDifferentPickupReturnLocations.value
          ? '반납 장소 선택'
          : '대여/반납 장소 선택'
      }
      viewmodel={viewmodel}
      locationViewModel={viewmodel.returnLocation}
      citySearch={viewmodel.i.returnCitySearch}
      citySearchInputProps={{
        autoFocus: true,
      }}
      isCityInputSelected
      onClickCityInputButton={() => {
        console.log('Step5.returnCity onClickCityInputButton');
      }}
      onClickRentalOfficeInputButton={() => {
        console.log('Step5.returnCity onClickRentalOfficeInputButton');
        if (viewmodel.i.returnCityCode.value !== null) {
          viewmodel
            .stepManager
            .replaceStep({
              type: '6_return_office',
            });
        }
      }}
      onSelectCity={() => {
        console.log('Step5.returnCity onSelectCity');
        viewmodel
          .stepManager
          .replaceStep({
            type: '6_return_office',
          });
      }}
      title="반납 위치"
      isRentalOfficeInputSelected={false}
    >
      <div>
        <PopularCity
          viewmodel={viewmodel.i.returnCitySearch}
          onBlur={() => null}
          cityCount={15}
          onSelectCity={() => {
            viewmodel.stepManager
              .replaceStep({
                type: '6_return_office',
              });
          }}
          classOverrides={{
            cityButton: popularCityDefaultStyles.mobile.cityButton,
          }}
        />
      </div>
    </CarListingBottomSheetSearchConditionHeader>
  );
});
