import { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { COLORS2, SPACING } from '^/constant/CommonStyles2';
import { ReactComponent as CheckBlue } from '^/lotties/ic_check_blue.svg';
import { HTMLButtonProps } from '^/types/utils/HTMLButtonProps';

export const CarClassChipsItem: React.FC<PropsWithChildren<{
  active: boolean;
  hideActiveCheckbox?: boolean;
}> & HTMLButtonProps> = ({
  active,
  hideActiveCheckbox,
  children,
  ...buttonProps
}) => {
  return (
    <button
      type="button"
      className={cx(
        css`
          border-radius: 16.5px;
          height: 33px;
          min-width: fit-content;
          font-size: 14px;
          justify-content: center;
          align-items: center;

          border-width: 1px;
          border-style: solid;
          /* margin-left: 10px; */
          display: flex;
          /* padding: 5px 10px 4px 6px; */
          letter-spacing: -0.01px;
          width: 100%;
          white-space: nowrap;
          padding: ${SPACING.spacing8} 12px;
        `,
        active && children === '전체'
          ? css`
              border-color: ${COLORS2.main.MrtBlue1};
              color: ${COLORS2.main.MrtBlue1};
              background-color: #f2f9ff !important;
              /* background-color: pink !important; */
            `
          : css`
              color: ${COLORS2.sub.MrtGray1};
              border-color: ${COLORS2.sub.MrtGray2} ;
              background-color: ${COLORS2.main.MrtWhite} !important;
              
              /* background-color: yellow !important; */
          `,
        (!hideActiveCheckbox && active) // beacause checkBtn size
          && css`
            padding: ${SPACING.spacing8} 10px ${SPACING.spacing8} 6px;
            border-color: ${COLORS2.main.MrtBlue1};
            background-color: #f2f9ff !important;
            color: ${COLORS2.main.MrtBlue1};
          `,
      )}
      {...buttonProps}
    >
      {((!hideActiveCheckbox && active) || null) && (
        <CheckBlue
          className={css`
            width: ${SPACING.spacing24};
            height: ${SPACING.spacing24};
          `}
        />
      )}
      {children}
    </button>
  );
};
