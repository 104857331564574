/* eslint-disable jsx-a11y/no-static-element-interactions */
import { RefObject, useEffect, useRef } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import {
  CarListingSearchBoxViewModel,
} from '../forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import {
  PopularCity,
} from '../forms/CarListingSearchBox/components/PopularCity';
import { popularCityDefaultStyles } from '../forms/CarListingSearchBox/components/PopularCity.defaultStyles';
import { Step1RentalCityStep } from '../forms/CarListingSearchBox/SearchBoxStepManager';

import {
  CarListingBottomSheetSearchConditionHeader,
} from './ui/CarListingBottomSheet.SearchCondition.Header';

export const Step1RentalCity: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  step: Step1RentalCityStep,
  pickupCityInputRef?: RefObject<HTMLInputElement>,
}> = observer(function Step1RentalCity({
  viewmodel,
  step,
  pickupCityInputRef,
}) {
  console.log('foo', viewmodel.stepManager.currentStep?.type === '1_rental_city');
  const defaultCitySearchInputRef = useRef<HTMLInputElement>(null);
  const citySearchInputRef = pickupCityInputRef || defaultCitySearchInputRef;

  useEffect(() => {
    if (
      step.autofocus === 'CitySearchInput'
      && citySearchInputRef.current
    ) {
      citySearchInputRef.current.focus();
    }
  }, [step.autofocus]);
  return (
    <CarListingBottomSheetSearchConditionHeader
      citySearchInputPlaceholder={
        viewmodel.i.hasDifferentPickupReturnLocations.value
          ? '대여 도시 선택'
          : '대여/반납 도시 선택'
      }
      rentalOfficeInputPlaceHolder={
        viewmodel.i.hasDifferentPickupReturnLocations.value
          ? '대여 장소 선택'
          : '대여/반납 장소 선택'
      }
      citySearchInputRef={citySearchInputRef}
      citySearchInputProps={{
        autoFocus: true,
      }}
      viewmodel={viewmodel}
      locationViewModel={viewmodel.pickupLocation}
      citySearch={viewmodel.i.pickupCitySearch}
      isCityInputSelected
      onClickCityInputButton={() => {
        // do nothing
        // alert('Step1.rentalCity onClickCityInputButton');
      }}
      onClickRentalOfficeInputButton={() => {
        // do nothing (as of now)
        // alert('Step1.rentalCity onClickRentalOfficeInputButton');
        if (viewmodel.i.pickupCityCode.value !== null) {
          viewmodel.stepManager
            .replaceStep({
              type: '2_rental_office',
            });
        }
      }}
      onSelectCity={() => {
        viewmodel.stepManager
          .replaceStep({
            type: '2_rental_office',
          });
      }}
      title={viewmodel.i.hasDifferentPickupReturnLocations.value === true
        ? '대여 위치'
        : '대여 / 반납 위치'}
      isRentalOfficeInputSelected={false}
    >
      <div className={css`
        /* padding-bottom: 50px; */
      `}
      >
        <PopularCity
          viewmodel={viewmodel.i.pickupCitySearch}
          onBlur={() => null}
          cityCount={15}
          onSelectCity={() => {
            viewmodel.stepManager
              .replaceStep({
                type: '2_rental_office',
              });
          }}
          classOverrides={{
            cityButton: popularCityDefaultStyles.mobile.cityButton,
          }}
        />
      </div>
    </CarListingBottomSheetSearchConditionHeader>
  );
});
