import itiriri from 'itiriri';

import { RawGetCarListSuccessBodyItem } from './getCarList.successtype';

export function decodeRawCarListItem(
  item: RawGetCarListSuccessBodyItem,
) {
  const rawModelName = item.core.modelName;
  const orSimilarCar = rawModelName
    .toUpperCase()
    .endsWith('OR SIMILAR');
  const modelName = orSimilarCar
    ? rawModelName.slice(0, -10)
    : rawModelName;
  return {
    ...item,
    services: itiriri(item.core.services)
      .toMap((a) => a.shortName),
    modelName,
    orSimilarCar,
  };
}

export type CarListItemType = ReturnType<typeof decodeRawCarListItem>;
