import { getCarListAPI } from '^/api2/getCarList/getCarListAPI';
import { getRentalCarOfficeLocationsByCityAPI } from '^/api2/getRentalCarOfficeLocationsByCity/byCity';
import { searchCityAPI } from '^/api2/SearchOLD/searchCity';
import { createListCoreFromAPI } from '^/BaseListCore/createListCoreFromAPI';
import { CarListingSearchBoxInputViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.InputViewmodel';
import { MobxValue } from '^/util/MobxValue';

import { RentalOfficeLocationsByCityCodeCore } from './RentalOfficeLocationByCityCodeCore';
import { RentalCarOfficeLocationRepo } from './RentalOfficeLocationRepo';

const searchCityListCore = createListCoreFromAPI(
  searchCityAPI,
)(
  (item) => item.code,
)();

const rentalCarOfficeLocationsRepo = new RentalCarOfficeLocationRepo();
const rentalCarOfficeLocationsCore = new RentalOfficeLocationsByCityCodeCore(
  getRentalCarOfficeLocationsByCityAPI,
  rentalCarOfficeLocationsRepo,
);

const getCarListCore = createListCoreFromAPI(
  getCarListAPI,
)(
  (item) => item.core.id,
)();

const carListingSearchBoxInput = new MobxValue<CarListingSearchBoxInputViewModel | null>(
  null,
);

export const defaultAppContext = {
  apis: {
    searchCityAPI,
  },
  searchCityListCore,
  searchCityRepo: searchCityListCore.repo,

  rentalCarOfficeLocationsRepo,
  rentalCarOfficeLocationsCore,

  getCarListCore,

  // hackish, but...
  carListingSearchBoxInput,
} as const;

export type AppContext = typeof defaultAppContext;

if (window) {
  (window as any).defaultAppContext = defaultAppContext;
}
