import { observer } from 'mobx-react-lite';

import { AppContext } from '^/AppContext/AppContext';
import Layout1 from '^/components/Layout/index';
import { Layout2 } from '^/components/Layout/Layout2';
import { Help } from '^/pages/FAQ';
import { HomePage2Wrapper } from '^/pages/Home2/HomePage2Wrapper';
import { ListingPage2Wrapper } from '^/pages/Listing2/ListingPage2Wrapper';
import NoticeDetail from '^/pages/Notice/NoticeDetail';
import { ReservationCheckPageWrapper } from '^/pages/ReservationCheck/ReservationCheck.page.wrapper';
import { getCurrentFreshness } from '^/types/__ResultType';

import { typeRouter } from './typeRoutes';

export const TypeRouteRenderer: React.FC<{
  appContext: AppContext,
}> = observer(function TypeRouteRenderer({
  appContext,
}) {
  const route = typeRouter.useRoute();

  if (route.name === 'home2') {
    if (!route.params.freshness) {
      setTimeout(() => {
        typeRouter
          .routes.home2({
            ...route.params,
            freshness: getCurrentFreshness(),
          }).replace();
      }, 0);
      return null;
    }
    return (
      <HomePage2Wrapper
        appContext={appContext}
        {...route.params}
      />
    );
  }
  if (route.name === 'listing2') {
    if (!route.params.freshness) {
      setTimeout(() => {
        typeRouter
          .routes.listing2({
            ...route.params,
            freshness: getCurrentFreshness(),
          }).replace();
      }, 0);
      return null;
    }
    return (
      <ListingPage2Wrapper
        key="listing2"
        appContext={appContext}
        {...route.params}
      />
    );
  }
  // if (route.name === 'checkReservation') {
  //   return (
  //     <ReservationCheckPageWrapper />
  //   );
  // }
  // if (route.name === 'help') {
  //   return (
  //     <Layout2><Help /></Layout2>
  //   );
  // }
  // if (route.name === 'noticeDetail') {
  //   return (
  //     <Layout1><NoticeDetail route={route} /></Layout1>
  //   );
  // }
  return (
    <h1> Not Found here </h1>
  );
});
