import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DynamicIcon from '../Icon/index';
import Spinner from '../Spinner/index';

const COLOR_MAPPING = {
  primary: 'text-white disabled:hover:text-white bg-primary-gray hover:bg-neutral-500 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-neutral-300',
  secondary:
    'text-primary-gray disabled:hover:text-primary-gray bg-secondary-gray hover:bg-primary-yellow hover:text-primary-gray disabled:bg-neutral-100',
  yellow: 'text-primary-gray disabled:hover:text-primary-gray bg-primary-yellow focus:outline-none disabled:bg-trimo-shadow',
  noneActive: 'text-primary-gray disabled:hover:text-primary-gray bg-secondary-gray hover:text-primary-gray disabled:bg-neutral-100',
  skyblue: 'text-white disabled:hover:text-primary-gray bg-secondary-gray hover:bg-primary-skyblue hover:text-primary-white disabled:bg-neutral-100',
  secondarySkyblue: 'rounded-xl text-primary-white disabled:hover:text-primary-gray bg-primary-skyblue focus:outline-none disabled:bg-trimo-shadow',
  textBlackSkyblue:
    'text-primary-gray disabled:hover:text-primary-gray bg-secondary-gray hover:bg-primary-skyblue hover:text-primary-white disabled:bg-neutral-100',
  secondaryBorder:
    'text-primary-gray disabled:text-neutral-200 disabled:border-neutral-200 bg-primary-white hover:bg-primary-gray hover:text-white focus:outline-none focus:ring-4 focus:ring-neutral-300 disabled:bg-neutral-100 border border-primary-gray',
  secondaryBorderWhite:
    'text-primary-gray disabled:text-neutral-200 disabled:border-neutral-200 bg-white hover:bg-primary-gray hover:text-primary-white focus:outline-none focus:ring-4 focus:ring-neutral-300 disabled:bg-neutral-100 border border-primary-gray',
  noneHover: 'hover:bg-white',
  trimoYellow: 'bg-trimo-light-yellow hover:bg-primary-yellow focus:outline-none disabled:bg-neutral-300 text-trimo-dark rounded-xl min-w-[200px] max-h-[56px]',
  trimoSkyblue: 'bg-primary-skyblue-light hover:bg-primary-skyblue focus:outline-none disabled:bg-neutral-300 text-white rounded-xl min-w-[200px] max-h-[56px]',
  // login: 'bg-login-gray focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-neutral-300',
  login: 'focus:outline-none disabled:bg-trimo-shadow hover:bg-primary-yellow',
  trimoDarkYellow: 'bg-primary-yellow hover:bg-primary-yellow focus:outline-none disabled:bg-neutral-300 text-trimo-dark rounded-trimo-5 min-w-[200px] max-h-[56px]',
  white:
    'text-primary-gray disabled:hover:text-primary-gray bg-white hover:bg-primary-yellow hover:text-primary-gray disabled:bg-neutral-100',
};

const SPINNER_COLOR_MAPPING = {
  primary: 'text-primary-white fill-white',
  secondary: 'text-primary-gray fill-primary-gray',
  yellow: 'text-primary-gray fill-primary-gray',
  secondaryBorder: 'text-primary-gray fill-primary-white',
  secondaryBorderWhite: 'text-primary-gray fill-white',
  login: 'text-primary-gray fill-white',
};
const DEFAULT_CLASSNAME = `px-5 py-2.5 text-center min-h-[24px]
                          flex flex-row justify-center items-center
                          disabled:cursor-not-allowed disabled:transform-none
                          disabled:transition-none`;

const Button = ({
  content,
  onClick,
  size,
  color = 'primary',
  loading = false,
  disabled,
  rounded = false,
  classNames,
  fullWidth,
  type = 'button',
  height,
  id,
  form,
  icon = '',
  iconStyle = '',
  leftIcon,
  rightIcon,
}) => {
  const { t } = useTranslation();
  const className = `${DEFAULT_CLASSNAME} ${rightIcon && 'pr-2'} ${leftIcon && 'pl-2'} ${COLOR_MAPPING[color]} ${classNames} min-w-[${size}] ${rounded ? 'rounded-full' : ''} ${
    fullWidth ? 'w-full' : ''
  } ${height ? `h-[${height}px]` : ''}`;
  return (
    <button id={id} type={type} className={className} disabled={disabled} onClick={onClick} form={form}>
      {loading && <Spinner size={4} color={SPINNER_COLOR_MAPPING[color]} />}
      {/* {content} */}
      {leftIcon && <DynamicIcon name={leftIcon} style="h-4 w-4" />}
      {icon && <DynamicIcon name={icon} style={iconStyle} />}
      {typeof content === 'string' ? <b>{t(content)}</b> : <b>{content}</b>}
      {rightIcon && <DynamicIcon name={rightIcon} style="h-4 w-4 ml-4" />}
    </button>
  );
};

Button.propTypes = {
  content: PropTypes.any,
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  classNames: PropTypes.string,
  fullWidth: PropTypes.bool,
  height: PropTypes.number,
  id: PropTypes.string,
  form: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  style: PropTypes.object,
};

export default Button;
