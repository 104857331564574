import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { RentalOfficeLocationsByCityCodeCore } from '^/AppContext/RentalOfficeLocationByCityCodeCore';
import { LocationCategoryNameType, RentalCarOfficeId } from '^/types/__BrandedTypes';
import { ExtractPromiseSuccess } from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';

type LocationCategoryToRentalOffices = ExtractPromiseSuccess<
ReturnType<RentalOfficeLocationsByCityCodeCore['getRentalOfficeLocations']>
>;
export class RentalOfficeInputViewModel {
  constructor(
    public appContext: AppContext,
    public data: LocationCategoryToRentalOffices,
    public selectedOfficeId = new MobxInputValue<RentalCarOfficeId | null>(null),
    public selectedCategory = new MobxInputValue<LocationCategoryNameType | null>(null),
    public hoverOfficeId = new MobxInputValue<RentalCarOfficeId | null>(null),
  ) {
    makeAutoObservable(this);
  }

  get selectedOfficeData() {
    if (this.selectedOfficeId.value === null
      || this.selectedOfficeId.value === undefined) {
      return this.selectedOfficeId.value;
    }
    return this.appContext
      .rentalCarOfficeLocationsRepo
      .getById(this.selectedOfficeId.value);
  }

  get hoveredOfficeData() {
    if (this.hoverOfficeId.value === null
      || this.hoverOfficeId.value === undefined) {
      return this.hoverOfficeId.value;
    }
    return this.appContext
      .rentalCarOfficeLocationsRepo
      .getById(this.hoverOfficeId.value);
  }
}
