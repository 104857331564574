import { css, cx } from '@emotion/css';

import { COLORS2, font, SPACING } from '^/constant/CommonStyles2';

export const classNames = {
  btnRendererBasic: cx(
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      height: ${SPACING.spacing56};

      padding: ${SPACING.spacing16};
      box-sizing: border-box;
      // flex-grow: 1 !important;
      @media (max-width: 768px) {
        padding: 0 ${SPACING.spacing8};
        // width: 50%;
      }
    `,
    font.Body1_Bold,
  ),
};
