import { atom } from 'recoil';

export const continentsState = atom({
  key: 'continentsState',
  default: {},
});

export const isDiffLocationState = atom({
  key: 'isDiffLocationState',
  default: false,
});

export const isErrorServerState = atom({
  key: 'isErrorServerState',
  default: false,
});

export const birthdayState = atom({
  key: 'birthdayState',
  default: '',
});

export const errorState = atom({
  key: 'errorState',
  default: false,
});

export const errorMsgState = atom({
  key: 'errorMsgState',
  default: '',
});

export const faqContentsState = atom({
  key: 'faqContents',
  default: '',
});

export const footerColorState = atom({
  key: 'footerColorState',
  default: 'white',
});

export const subdomainState = atom({
  key: 'subdomainState',
  default: 'myrealtrip',
});
