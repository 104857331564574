/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { RefObject } from 'react';

import { observer } from 'mobx-react-lite';

import ReactPortal from '^/components/ReactPortal';

import { CarListingSearchBoxMobile } from '../forms/CarListingSearchBox/CarListingSearchBox.mobile';
import { CarListingSearchBoxViewModel } from '../forms/CarListingSearchBox/CarListingSearchBox.viewmodel';

export const CarListingBottomSheetWrapper: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  pickupCityInputRef?: RefObject<HTMLInputElement>,
  pickupCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
  returnCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
  carsScrollAreaRef: RefObject<HTMLDivElement> | null,
  carClassChipsScrollRef: RefObject<HTMLDivElement> | null,
}> = observer(function CarListingBottomSheetWrapper({
  viewmodel,
  pickupCityInputRef,
  pickupCityInputDummyRefForIOSKeyboard,
  returnCityInputDummyRefForIOSKeyboard,
  carsScrollAreaRef,
  carClassChipsScrollRef,
}) {
  return (
    <ReactPortal
      wrapperId="for-searchBox"
    >
      <CarListingSearchBoxMobile
        viewmodel={viewmodel}
        pickupCityInputRef={pickupCityInputRef}
        pickupCityInputDummyRefForIOSKeyboard={
          pickupCityInputDummyRefForIOSKeyboard
        }
        returnCityInputDummyRefForIOSKeyboard={
          returnCityInputDummyRefForIOSKeyboard
        }
        carsScrollAreaRef={carsScrollAreaRef}
        carClassChipsScrollRef={carClassChipsScrollRef}
      />
    </ReactPortal>
  );
});
