import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { typeRouter } from '^/typeRoute/typeRoutes';

import { MRT_LOGO } from './constants';

const classNames = {
  headerWrapper: css`
    width: 100%;
    height: 60px;

    display: flex;
    align-items: center;
    padding: 0 24px;
  `,
};
export const Header3Mobile = observer(function Header3Mobile(props: {
  withoutFullRefresh?: boolean
}) {
  return (
    <div>
      <header className={classNames.headerWrapper}>
        <button
          type="button"
          onClick={() => {
            if (props.withoutFullRefresh) {
              typeRouter.routes.home2().push();
            } else {
              window.location.href = window.location.origin;
              window.scrollTo(0, 0);
            }
          }}
        >
          <img
            src={MRT_LOGO}
            alt="trimo-logo"
            className={
            css`
              width: 225px;
              height: 28px;
            `
          }
          />
        </button>
      </header>
      <div
        className={css`
          width: 100%;
          height: 12px;
          background-image: linear-gradient(to bottom, #f2f2f2, rgba(255, 255, 255, 0));
        `}
      />
    </div>
  );
});
