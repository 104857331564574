import { css } from '@emotion/css';

import { FixedEnumMap } from '^/types/utils/MapTypeUtils';

export const breakpoints = {
  tabletMax: '@media (max-width: 768px)',
} as const;

export const COLORS = {
  SKY_BLUE: '#55a9f3',
  SKY_BLUE_LIGHT: '#f2f9ff',
  LIGHT_GREY: '#e6e6e6',
  LIGHT_GREY_0: '#cecece',
  LIGHT_GREY_2: '#f4f4f4',
  NEUTRAL_100: '#E6E6E6',
  NEUTRAL_200: '#CECECE',
  NEUTRAL_300: '#9F9F9F',
  NEUTRAL_400: '#737373',
  NEUTRAL_500: '#404040',
  WHITE: '#fff',
  BLACK: '#1e1e1e',
} as const;

// TODO: need design system rules...
export const UNITS = new FixedEnumMap([
  '16px',
  '24px',
  '56px',
]);

export const textAlign = {
  center: css`
    text-align: center;
  `,
} as const;

export const fontSize = {
  big: css`
    font-size: 16px;
    line-height: 19px;
  `,
  semiHuge: css`
    font-size: 22px;
    line-height: 26px;
  `,
} as const;

export const fontWeights = {
  bold: css`
    font-weight: 700;
  `,
  normal: css`
    font-weight: normal;
  `,
} as const;

export const borders = {
  solid1pxLightGrey0: css`
    border: solid ${COLORS.LIGHT_GREY_0} 1px;
  `,
  radius10: css`
    border-radius: 10px;
  `,
  no_radius_left: css`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  `,
  no_radius_right: css`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  `,
  full_radius: css`
    border-radius: 50%;
  `,
  full_radius_left: css`
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  `,
  full_radius_right: css`
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  `,
  bottom_border_1px: css`
    border-bottom-width: 1px;
  `,
  // DEBUG
  DEBUG_greenBorderDashed: css`
    border: green 2px dashed;
  `,
  DEBUG_blueBorderDashed: css`
    border: blue 2px dashed;
  `,
  DEBUG_purpleBorderDashed: css`
    border: purple 2px dashed;
  `,
  DEBUG_redBorderDashed: css`
    border: red 2px dashed;
  `,
  DEBUG_orangeBorderDashed: css`
  border: orange 2px dashed;
`,
  DEBUG_pinkDashed: css`
    border: pink 2px dashed;
  `,
  DEBUG_goldDashed: css`
  border: gold 2px dashed;
`,
} as const;

export const hideScrollBar = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const PAGE_MAX_WIDTH = 1320;

export const cursor = {
  hover_pointer: css`
    &:hover {
      cursor: pointer; 
    }
  `,
};
