import { css } from '@emotion/css';

import { COLORS2 } from '^/constant/CommonStyles2';

export const classNames = {
  root: css`
    position: fixed;

    width: 100%;
    height: 100%;

    background-color: rgba(34,34,34, 0.2);
  `,
  container: css`
    position: fixed;
    bottom: 0; left: 0;

    width: 100%;
    height: calc(100% - 60px);

    background-color: ${COLORS2.main.MrtWhite};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
};
