/* eslint-disable jsx-a11y/no-static-element-interactions */
import { RefObject } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import { borders } from '^/constant/CommonStyles';
import {
  COLORS2, font, INPUT, SPACING,
} from '^/constant/CommonStyles2';

import { CarListingSearchBoxViewModel } from '../forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import { classNames as contentsAreaStyles } from '../forms/CarListingSearchBox/components/CarListingSearchBox.contentsArea.style';
import { RentalOfficeInputButton } from '../forms/CarListingSearchBox/headerArea/RentalOfficeInputButton';
import { ScheduleInputButton } from '../forms/CarListingSearchBox/headerArea/ScheduleInputButton';
import { SearchBoxSteps } from '../forms/CarListingSearchBox/SearchBoxStepManager';
import { CitySearchInput } from '../inputs/CitySearchInput/CitySearchInput';

import { ages } from './Step4.birthday.ages';
import { CarListingBottomSheetHeader } from './ui/CarListingBottomSheet.header';

const classNames = {
  citySearchInputWrapper: cx(
    css`
    padding: 19px 12px;
    height: 56px;
    background-color: ${COLORS2.sub.MrtGray3};

    display: flex;
    align-items: center;

    border-radius: 5px;

    overflow-x: hidden;
    white-space: nowrap;
  `,
    INPUT.overflowEllipsis,
    // borders.DEBUG_blueBorderDashed,
  ),
  citySearchRentalOfficeWrapper: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    margin-top: ${SPACING.spacing16};
  `,
};
export const Step0DifferentRentalLocation: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  pickupCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
  returnCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
  carsScrollAreaRef: RefObject<HTMLDivElement> | null,
  carClassChipsScrollRef: RefObject<HTMLDivElement> | null,
}> = observer(function Step0DifferentRentalLocation({
  viewmodel,
  pickupCityInputDummyRefForIOSKeyboard,
  returnCityInputDummyRefForIOSKeyboard,
  carsScrollAreaRef,
  carClassChipsScrollRef,
}) {
  const stepManagerStepChangeOptions = {
    afterStepChange(newStep: SearchBoxSteps) {
      if (newStep.type === '1_rental_city') {
        pickupCityInputDummyRefForIOSKeyboard?.current?.focus();
      }
      if (newStep.type === '5_return_city') {
        returnCityInputDummyRefForIOSKeyboard.current?.focus();
      }
    },
  } as const;
  return (
    <form
      className={cx(
        // borders.DEBUG_blueBorderDashed,
        css`
          display: flex;
          flex-direction: column;
          height: 100%;
      `)}
      onSubmit={(e) => {
        if (viewmodel.submitActionVM.status === 'success') {
          viewmodel.stepManager.clearStep();
          const submitFn = viewmodel.submitActionVM.value;
          setTimeout(() => {
            submitFn.submit();
          }, 50);
          setTimeout(() => {
            carsScrollAreaRef?.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            carClassChipsScrollRef?.current?.scrollTo({
              left: 0,
              behavior: 'smooth',
            });
          }, 50);
        }
        e.preventDefault();
        return false;
      }}
    >
      <CarListingBottomSheetHeader
        title="검색 조건 설정"
        leftCornorBtnType="close"
        stepManager={viewmodel.stepManager}
      />

      <section
        className={
          css`
            padding: 12px ${SPACING.spacing24};
            overflow-y: scroll;
          `
        }
      >
        <div>
          <div className={font.Caption1_Light}>
            대여 위치
          </div>

          <div
            className={classNames.citySearchRentalOfficeWrapper}
          >
            <div
              onClick={() => {
                viewmodel.stepManager.pushStepWithOptions({
                  type: '1_rental_city',
                }, stepManagerStepChangeOptions);
              }}
              className={classNames.citySearchInputWrapper}
            >
              <CitySearchInput
                placeholder="대여 도시 선택"
                disabled
                viewmodel={viewmodel.i.pickupCitySearch}
                onSelectCity={() => {
                }}
              />
            </div>
            <div
              onClick={() => {
                viewmodel.stepManager.pushStepWithOptions({
                  type: '2_rental_office',
                }, stepManagerStepChangeOptions);
              }}
              className={css`
                /* width: 50%; */
                overflow: hidden;
                white-space: nowrap;
                border-radius: 5px;
              `}
            >
              <RentalOfficeInputButton
                viewmodel={viewmodel.pickupLocation}
                isSelected={viewmodel.stepManager.currentStep?.type === '2_rental_office'}
                placeholder="대여 장소 선택"
              />
            </div>
          </div>
        </div>

        <div
          className={
            css`
              margin-top: ${SPACING.spacing16};
            `
          }
        >
          <div className={font.Caption1_Light}>
            반납 위치
          </div>

          <div
            className={classNames.citySearchRentalOfficeWrapper}
          >
            <div
              onClick={() => {
                viewmodel.stepManager.pushStepWithOptions({
                  type: '5_return_city',
                }, stepManagerStepChangeOptions);
                returnCityInputDummyRefForIOSKeyboard
                  .current
                  ?.focus();
              }}
              className={classNames.citySearchInputWrapper}
            >
              <CitySearchInput
                placeholder="반납 도시 선택"
                disabled
                viewmodel={viewmodel.i.returnCitySearch}
                onSelectCity={() => {
                  console.error(
                    '**SHOULD NOT HAPPEN**  ',
                    'Step0.differentRentalLocation',
                    'returnCitySearch',
                    'onSelectCity',
                  );
                }}
              />
            </div>

            <div
              onClick={() => {
                viewmodel.stepManager.pushStepWithOptions({
                  type: '6_return_office',
                }, stepManagerStepChangeOptions);
              }}
              className={css`
                overflow-x: hidden;
                white-space: nowrap;
              `}
            >
              <RentalOfficeInputButton
                viewmodel={viewmodel.returnLocation}
                isSelected={viewmodel.stepManager.currentStep?.type === '6_return_office'}
                placeholder="반납 장소 선택"
              />
            </div>

          </div>
        </div>

        {/* 대여/반납 장소가 다릅니다. */}
        <div
          onClick={() => {
            viewmodel
              .i
              .hasDifferentPickupReturnLocations
              .set(!viewmodel.i.hasDifferentPickupReturnLocations.value);
            // FIXME: calendar reset as requested...(?????)
            //          better: do validation...
            viewmodel
              .i
              .scheduleTimeViewModel
              .reset();
            viewmodel
              .i
              .calendarStateViewModel
              .reset();
          }}
          className={cx(
            css`
              display: flex;
              gap: ${SPACING.spacing8};
              align-items: center;

              margin-top: ${SPACING.spacing8};
              width: fit-content;
            `,
            font.Caption2_Light,
          )}
        >
          <img
            alt="rental-location-different"
            src={viewmodel.i.hasDifferentPickupReturnLocations.value
              ? 'ic_checkbox/ic_checkbox_a2.svg'
              : 'ic_checkbox/ic_checkbox_n2.svg'}
          />
          대여/반납 장소가 다릅니다.
        </div>

        <section
          className={css`
            margin-top: ${SPACING.spacing24};
          `}
        >
          <ScheduleInputButton
            viewmodel={viewmodel}
            onClick={() => {
              viewmodel.stepManager.pushStepWithOptions({
                type: '3_schedule',
              }, stepManagerStepChangeOptions);
            }}
            isSelected={viewmodel.stepManager.currentStep?.type === '3_schedule'}
          />
        </section>

        <section
          className={css`
            margin-top: ${SPACING.spacing24};
          `}
        >
          <div className={contentsAreaStyles.common.searchBoxInputTitle}>
            운전자 연령
          </div>
          <div
            onClick={() => {
            // viewmodel.stepManager.pushStepWithOptions({ type: '4_birthday' });
              viewmodel.stepManager.pushStepWithOptions({
                type: '4_birthday',
              }, stepManagerStepChangeOptions); // FIXME: maybe can delete
            }}
            className={cx(
              font.Body1_Bold,
              css`
                background-color:${COLORS2.sub.MrtGray3};
                height: ${SPACING.spacing56};
                margin-top: ${SPACING.spacing16};
                border-radius: 5px;
                display: flex;
                padding-left: 12px;
                align-items: center;
              `,
            )}
          >
            { viewmodel.i.birthdayInput.value
              ? ages
                .filter((obj) => obj.age === viewmodel.i.birthdayInput.value)
                .map((obj) => <div>{obj.text}</div>)
              : <div>운전자 나이를 입력해주세요.</div>}
          </div>
        </section>
      </section>
      {/* Search Button */}
      <button
        type="submit"
        disabled={viewmodel.submitActionVM.status !== 'success'}
        className={cx(
          font.Body1_Bold,
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${COLORS2.main.MrtBlue1} !important;
            min-height: 56px;

            margin-left: ${SPACING.spacing24};
            margin-right: ${SPACING.spacing24};
            margin-top: auto;
            margin-bottom: ${SPACING.spacing16};

            border-radius: 5px;
            color: ${COLORS2.main.MrtWhite};

            &:disabled {
              background-color: ${COLORS2.main.MrtBlue2} !important;
            }
          `,
        )}
      >
        검색
        {/* {viewmodel.submitActionVM.status !== 'success' && (
          <PreTagForDebugging
            className={css`
              position: fixed;
              bottom: 0;
              left: 0;
              color: red;
              background-color: yellow;
              opacity: 0.4;
            `}
            obj={viewmodel.submitActionVM.failure}
            isShow
          />
        )} */}
      </button>
    </form>
  );
});
