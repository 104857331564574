import React, { useRef, useLayoutEffect } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { borders } from '^/constant/CommonStyles';
import { COLORS2, SPACING } from '^/constant/CommonStyles2';
import { CarListingBottomSheetSelectedLocationCategoryItems } from '^/domainComponents/CarListingBottomSheet/ui/CarListingBottomSheet.selectedLocationCategoryItems';
import { ExtractReactProps } from '^/types/utils/extractReactProps';

import {
  RentalOfficeInputLocationCategory,
} from './RentalOfficeInput.locationCategory';
import { RentalOfficeInputViewModel } from './RentalOfficeInput.viewmodel';
import { SelectedOfficeInfoRoot } from './ui/SelectedOfficeInfoRoot';

export const RentalOfficeInput: React.FC<{
  viewmodel: RentalOfficeInputViewModel,
  onBlur: () => void,
  onSelectRentalOffice: ExtractReactProps<
    typeof CarListingBottomSheetSelectedLocationCategoryItems
  >['onSelectRentalOffice'],
  // onSelectRentalOffice(id: RentalCarOfficeId): void;
}> = observer(function RentalOfficeInput({
  viewmodel,
  onBlur,
  onSelectRentalOffice,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const elemRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    elemRef.current?.focus();
    console.log('elemRef.current focus', elemRef.current);
  }, []);

  const selectedCategory = viewmodel.selectedCategory.value
    || viewmodel.selectedOfficeData?.category_id;

  return (
    <div
      tabIndex={-1}
      ref={elemRef}
      className={cx(
        css`
          margin-top: ${SPACING.spacing24};
          display: flex;
          flex-direction: column;
          @media (max-width: 768px) {
            /* height: 100%;
            overflow: scroll; */
            margin-top: 0;
          }
        `,
        // borders.DEBUG_blueBorderDashed,
      )}
      onBlur={(e) => {
        // see: https://stackoverflow.com/a/60094794/19531789
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }
        if (onBlur) {
          onBlur();
        }
      }}
    >
      <RentalOfficeInputLocationCategory
        viewmodel={viewmodel}
      />
      {/* {JSON.stringify({ selectedCategory: viewmodel.selectedCategory.value }, null, 2)} */}
      {/* specific Rental Shop */}
      {/* horizon */}
      {isMobile
        ? (
          <div style={{ position: 'relative', marginTop: '12px' }}>
            <div
              className={css`
              position: absolute;
              /* top: ${SPACING.spacing56}; */

              width: calc(100% + 48px);
              height: 12px;
              margin-left: -24px;

              background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.05),
                rgba(84, 84, 84, 0)
              );
              // background-color: red;
            `}
            />
          </div>
        )
        : (
          <div
            className={
              css`
                width: 100%;
                height: 2px;
                background-color: ${COLORS2.sub.MrtGray3};
                margin: ${SPACING.spacing24} 0;
              `
            }
          />
        )}
      {selectedCategory && (
        <div className={css`
          display: flex;
          flex-direction: column;

          margin-top: atuo;
          @media (max-width: 768px) {
            height: 100%;
          }
        `}
        >

          <CarListingBottomSheetSelectedLocationCategoryItems
            viewmodel={viewmodel}
            onSelectRentalOffice={onSelectRentalOffice}

          />

          {/* 선택된 location의 영업 시간 및 구글 맵 */}
          {(viewmodel.selectedOfficeId.value
            || viewmodel.hoverOfficeId.value)
            && !isMobile
            && (
            <SelectedOfficeInfoRoot
              viewmodel={viewmodel}
            />
            )}
        </div>
      )}
    </div>
  );
});
