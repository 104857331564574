import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { CalendarRoot } from '^/components/calendar2/Calendar.root';
import { COLORS2, SCROLL_BAR, SPACING } from '^/constant/CommonStyles2';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { CarListingSearchBoxViewModel } from '../forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import { CalendarWithTimeMobile } from '../inputs/CalendarWithTime/CalendarWithTime.Mobile';

import { CarListingBottomSheetHeader } from './ui/CarListingBottomSheet.header';

const classNames = {
  calendarRootWrapper: cx(
    SCROLL_BAR.hide,
    css`
      position: absolute;
      // overflow-y: scroll;

      height: 100%;
      width: 100%;

      display: flex;
      justify-content:center;
    `,
  ),
  calendarWithTimeWrapper: cx(
    css`
      position: absolute;
      bottom: 0; left: 0;

      // height: 272px;
      width: 100%;

      // background-color: pink;
      background-color: ${COLORS2.main.MrtWhite};
      padding: 0 ${SPACING.spacing24} ${SPACING.spacing16};
    `,
  ),
};
export const Step3Schedule: React.FC<{
  viewmodel: CarListingSearchBoxViewModel
}> = observer(function Step3Schedule({
  viewmodel,
}) {
  const tempCalendarState = viewmodel
    .i
    .tempCalendarStateViewModel;
  const tempTimeScheduleState = viewmodel
    .i
    .tempScheduleTimeViewModel;

  const startDay = tempCalendarState.selection.start;
  const endDay = tempCalendarState.selection.end;
  const returnLocation = viewmodel.i.hasDifferentPickupReturnLocations.value === true
    ? viewmodel.returnLocation : viewmodel.pickupLocation;
  if (!viewmodel.pickupLocation || !returnLocation) {
    console.error(
      'Step3.schedule:',
      '!viewmodel.pickupLocation || !returnLocation',
      'viewmodel.pickupLocation: ',
      viewmodel.pickupLocation,
      'returnLocation: ',
      returnLocation,
    );
    return null;
  }
  return (

    <div>
      <CarListingBottomSheetHeader
        title="대여 / 반납 일정"
        leftCornorBtnType="prevStack"
        stepManager={viewmodel
          .stepManager}
      />

      <div className={classNames.calendarRootWrapper}>
        <CalendarRoot
          selectedReturnDayOffsetTop={viewmodel.i.calendarStateViewModel.selectedReturnDayOffsetTop}
          hasDifferentPickupReturnLocations={viewmodel.i.hasDifferentPickupReturnLocations}
          pickupLocation={viewmodel.pickupLocation}
          returnLocation={returnLocation}
          calendarStateViewModel={tempCalendarState}
          calendarListViewModel={viewmodel.calendarListViewModel}
          onBlur={() => null}
          isMobile
        />
      </div>

      {/* calendar with time */}
      {startDay && endDay
        && viewmodel.pickupLocation
        && returnLocation
        && (
        <div className={classNames.calendarWithTimeWrapper}>
          <CalendarWithTimeMobile
            onSubmit={() => {
              viewmodel
                .i
                .calendarStateViewModel
                .copyFrom(tempCalendarState);
              viewmodel
                .i
                .scheduleTimeViewModel
                .copyFrom(tempTimeScheduleState);
              typeRouter.session.back();
            }}
            stepManager={viewmodel.stepManager}
            calendarStateViewModel={tempCalendarState}
            calendarListViewModel={viewmodel.calendarListViewModel}
            scheduleTimeViewModel={tempTimeScheduleState}
            pickupOpenCloseTimes={viewmodel.pickupLocation.openCloseTimes}
            returnOpenCloseTimes={returnLocation.openCloseTimes}
          />
        </div>
        )}
    </div>
  );
});
