/* eslint-disable max-len */
import { makeObservable } from 'mobx';

import { NthDayOfWeek } from '^/types/__BrandedTypes';

import type { CalendarMonthViewModel } from '../Month/Calendar.Month.viewmdel';
import { WEEK_DAY_NAMES } from '../WEEK_DAY_NAMES';

import { DayType } from './DayType';

export class CalendarDayViewModel extends DayType {
  constructor(
    public readonly monthViewModel: CalendarMonthViewModel,
    public readonly day: number,
    public readonly isLastDateOfMonth: boolean,
    public readonly isFirstDateOfMonth = day === 1,
    public readonly dayOfWeek = (monthViewModel.startWeekDay + day - 1) % 7 as NthDayOfWeek,
    public readonly isWeekDay = 0 < dayOfWeek && dayOfWeek < 6,
    public readonly dayNameInWeek = WEEK_DAY_NAMES[dayOfWeek],
  ) {
    super(
      monthViewModel.yearMonth,
      day,
      dayOfWeek,
    );
  }
}
