import { useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useRecoilValue } from 'recoil';

import { footerColorState } from '../../recoil/atoms';
import TermsPrivacyPopup from '../Popup/TermPopup/TermsPrivacy';
import TermsServicePopup from '../Popup/TermPopup/TermsService';

import './index.scss';

export const Footer: React.FC<{}> = () => {
  const [termServiceOpen, setTermServiceOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const footerColorValue = useRecoilValue(footerColorState);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className={[
      `bg-${footerColorValue}`,
      'footer text-primary-gray flex-start  mx-auto pt-[24] z-20 bottom-0',
      isMobile ? 'mt-[16rem] overflow-hidden' : 'max-w-[1320px] top-20',
    ].join(' ')}
    >
      {
        isMobile
          ? (
            <div className="flex flex-row px-[24px] pt-[30px] ">
              <div className="flex flex-col">
                <div className="justify-top flex flex-col ">
                  <div className="flex flex-col gap-[20px] text-default font-bold">
                    고객센터 운영안내
                  </div>
                  <div className="flex flex-col gap-[50px] pt-[30px] text-small leading-5 ">
                    고객센터 운영안내<br />
                    연중무휴 09:00~18:00 (점심시간 12:00~13:00)<br />
                    주말/공휴일 포함, 한국시간 기준<br />
                    유선상담 1670-8208<br />
                    ※ 그 외 시간은 예약하신 현지 영업소로 연락해주세요.<br />
                  </div>
                </div>
                <div className="mt-[30px] flex flex-row gap-[20px] text-default font-bold">
                  <div
                    className="flex-col hover:cursor-pointer hover:text-primary-gray"
                    onClick={() => setPrivacyOpen(true)}
                  >
                    개인정보 취급방침
                  </div>
                  <div
                    className="flex-col hover:cursor-pointer hover:text-primary-gray"
                    onClick={() => setTermServiceOpen(true)}
                  >
                    이용약관
                  </div>
                </div>
                <div className="mt-[45px] w-[100px]">
                  <img src="/ims_logo@3x.png" />
                </div>
                <div className="flex flex-col gap-[50px] pt-[30px] text-small leading-5 ">
                  상호명 아이엠에스모빌리티 주식회사 | 대표 조영탁 |<br />
                  개인정보보호책임자 조영탁 | 사업자등록번호 280-86-00274 |<br />
                  통신판매업신고번호 제 2018-서울광진-0968호<br />
                  주소 서울특별시 광진구 아차산로 355, 3층 광진아크로텔<br />
                  이메일 info@trimo.ai<br />
                </div>
                <div className="flex flex-col gap-[50px] pt-[10px] pb-10 text-small leading-5">
                  아이엠에스모빌리티 주식회사는 통신판매중개자로 통신판매의<br />
                  당사자가 아닙니다. 상품의 예약, 이용 및 환불 등 상품·거래정보 및 거래에 대하여 책임을 지지않으며 관련한 의무와 책임은 각 판매자에게 있습니다.<br />
                </div>
              </div>
            </div>
          )
          : (
            <div className=" flex flex-row py-[50px] tablet:px-[24px] desktop:px-0">
              <div className="mobile:justify-top flex flex-col">
                <div className="flex flex-row gap-[20px] text-default font-bold tablet-max:text-small">
                  <div
                    className="hover:cursor-pointer hover:text-primary-gray"
                    onClick={() => setPrivacyOpen(true)}
                  >
                    개인정보 취급방침
                  </div>
                  <div
                    className="hover:cursor-pointer hover:text-primary-gray"
                    onClick={() => setTermServiceOpen(true)}
                  >
                    이용약관
                  </div>
                </div>
                <div className="mt-[30px] w-[100px]">
                  <img src="/ims_logo@3x.png" />
                </div>
                <div className="flex flex-row gap-[50px] pt-[10px] text-small leading-5 tablet-max:text-small">
                  아이엠에스모빌리티 주식회사는 통신판매중개자로 통신판매의 당사자가 아닙니다.<br />
                  상품의 예약, 이용 및 환불 등 상품·거래정보 및 거래에 대하여 책임을 지지않으며 관련한 의무와 책임은 각 판매자에게 있습니다.<br />
                </div>
                <div className="flex flex-row gap-[50px] pt-[30px] text-small leading-5 tablet-max:text-small">
                  상호명 아이엠에스모빌리티 주식회사 | 대표 조영탁 | 개인정보보호책임자 조영탁 |<br />
                  사업자등록번호 280-86-00274 | 통신판매업신고번호 제 2018-서울광진-0968호<br />
                  주소 서울특별시 광진구 아차산로 355, 3층 광진아크로텔 이메일 info@trimo.ai<br />
                </div>
              </div>
              <div className="justify-top mx-[120px] flex flex-col">
                <div className="flex flex-row gap-[20px] text-default font-bold tablet-max:text-small">
                  고객센터 운영안내
                </div>
                <div className="flex flex-row gap-[50px] pt-[30px] text-small leading-5 tablet-max:text-small">
                  고객센터 운영안내<br />
                  연중무휴 09:00~18:00 (점심시간 12:00~13:00)<br />
                  주말/공휴일 포함, 한국시간 기준<br />
                  유선상담 1670-8208<br />
                  ※그 외 시간은 예약하신 현지 영업소로 연락해주세요.<br />
                </div>
              </div>
            </div>
          )
      }
      <TermsPrivacyPopup open={privacyOpen} setOpen={setPrivacyOpen} />
      <TermsServicePopup open={termServiceOpen} setOpen={setTermServiceOpen} />
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
