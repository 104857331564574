import itiriri from 'itiriri';
import { makeAutoObservable } from 'mobx';

import { filterNotDefineds } from '^/types/utils/isDefined';
import { maxNullable, minNullable } from '^/util/nullableCompare';

import { ListingPage2CardViewModel } from './ListingPage2.card.viewmodel';
import { ListingPage2FilterViewModel } from './ListingPage2.FilterViewModel';

// TODO: maybe separation is bad idea...?
export class ListingPage2LoadedViewModel {
  constructor(
    public items: ListingPage2CardViewModel[],
    public filterVM: ListingPage2FilterViewModel,
  ) {
    makeAutoObservable(this);
  }

  get itemsIter() {
    return itiriri(this.items);
  }

  get possiblePriceRange() {
    const podPrices = filterNotDefineds(
      this.itemsIter.map(
        (a) => a
          .data
          .POD
          ?.payment
          .domesticPrice,
      ),
    );
    const podPriceMax = podPrices.max();
    const podPriceMin = podPrices.min();
    const ppdPrices = filterNotDefineds(
      this.itemsIter.map((a) => a
        .data
        .PPD
        ?.payment
        .domesticPrice,
      ),
    );
    const ppdPriceMax = ppdPrices.max();
    const ppdPriceMin = ppdPrices.min();
    return {
      min: minNullable([podPriceMin, ppdPriceMin]),
      max: maxNullable([podPriceMax, ppdPriceMax]),
    };
    // this.items.map(a => a.data.)
  }

  get possibleVendors() {
    const vendors = new Set(this.itemsIter
      .map((a) => a
        .data
        .core
        .company
        .name,
      ),
    );
    return [...vendors];
  }

  get filteredList() {
    const minPrice = this.filterVM.minPrice.value || 0;
    const maxPrice = this.filterVM.maxPrice.value || Number.MAX_VALUE;
    //
    return this.itemsIter
      .filter((car) => {
        if (car.data.POD) {
          const { domesticPrice } = car.data.POD.payment;
          return (
            (domesticPrice >= minPrice
            && domesticPrice <= maxPrice)
          );
        }
        const domesticPrice = car.data.PPD?.payment.domesticPrice || 0;
        return (
          (domesticPrice >= minPrice
          && domesticPrice <= maxPrice)
        );
      })
      .filter((car) => {
        const {
          vehType,
        } = car.data.core;
        if (this.filterVM.carClass.value.size === 0) {
          return true;
        }
        const carClassFilter = this.filterVM.carClass;
        if ((
          // '경차' in ko.json file
          vehType.size === 'mini'
          || vehType.size === 'minielite'
          || vehType.size === 'economy'
          || vehType.size === 'economy elite'
          || vehType.size === 'subcompact'
        ) && carClassFilter.value.has('mini')) {
          return true;
        }
        if ((
          // '준중형차'
          vehType.size === 'compact elite'
        ) && carClassFilter.value.has('midsize')) {
          return true;
        }
        if ((
          // '중형차'
          vehType.size === 'standard'
          || vehType.size === 'standard elite'
          || vehType.size === 'fullsize'
          || vehType.size === 'fullsize elite'
          || vehType.size === 'intermediate'
          || vehType.size === 'intermediate elite'
        ) && carClassFilter.value.has('intermediate')) {
          return true;
        }
        if ((
          vehType.size === 'premium'
          || vehType.size === 'premium elite'
          || vehType.size === 'luxury elite'
          || vehType.size === 'luxury'
        ) && carClassFilter.value.has('luxury')) {
          return true;
        }
        return carClassFilter.value.has(vehType.size as any);
      })
      .sort((a, b) => {
        const aPrices = a.data.core.prices.map(Number);
        const bPrices = b.data.core.prices.map(Number);
        if (this.filterVM.priceSortFilter.value === 'lowest') {
          return Math.min(...aPrices) - Math.min(...bPrices);
        }
        return Math.max(...bPrices) - Math.max(...aPrices);
      })
      .toArray();
  }
}
