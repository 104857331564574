import { useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { AppContext } from '^/AppContext/AppContext';
import { Layout2 } from '^/components/Layout/Layout2';
import { MobxValue } from '^/util/MobxValue';

import { HomePage2Desktop } from './HomePage2.desktop';
import { HomePage2Mobile } from './HomePage2.mobile';
import { HomePage2ViewModel } from './HomePage2.viewmodel';
import { HomePage2RouteParams } from './HomePage2RouteParams';

export const HomePage2Wrapper: React.FC<{
  appContext: AppContext,
} & HomePage2RouteParams> = observer(function HomePage2Wrapper(
  props,
) {
  const {
    appContext,
    ...routeParams
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const viewmodel = useMemo(
    () => new HomePage2ViewModel(
      appContext,
      new MobxValue(routeParams),
      isMobile,
      13,

    ),
    [isMobile],
  );

  (window as any).viewmodel = viewmodel;

  useEffect(() => {
    viewmodel.routeParams.setValue(routeParams);
  }, [routeParams]);

  useEffect(() => {
    viewmodel
      .carListingSearchBox
      .stepManager
      .updateRouteParams(routeParams);
  }, [routeParams, viewmodel.carListingSearchBox.stepManager]);

  if (isMobile) {
    return (
      <Layout2
        // withoutFooter
        // withoutFooterTopLine
        withoutMaxWidth
        // withoutOverflowScroll
      >
        <HomePage2Mobile
          viewmodel={viewmodel}
        />
      </Layout2>
    );
  }
  return (
    <Layout2>
      <div className={css`
        width: 100%;
        display: flex;
        justify-content: center;

        margin-top: 56px;
      `}
      >
        <div
          className={css`
            max-width: 1320px;
            /* width: 1320px; */
        `}
        >
          <HomePage2Desktop
            viewmodel={viewmodel}
          />

        </div>
      </div>
    </Layout2>
  );
});
