import { CityCodeType } from '^/types/__BrandedTypes';

export const popularCityList2 = [
  {
    name: 'Honolulu',
    code: 'HNL' as CityCodeType,
    nameKo: '호놀룰루',
  },
  {
    name: 'Kahului',
    code: 'OGG' as CityCodeType,
    nameKo: '카훌루이',
  },
  {
    name: 'Kailua-Kona',
    code: 'KOA' as CityCodeType,
    nameKo: '카일루아 코나',
  },
  {
    name: 'Guam',
    code: 'GUM' as CityCodeType,
    nameKo: '괌',
  },
  {
    name: 'Los Angeles',
    code: 'LAX' as CityCodeType,
    nameKo: '로스앤젤레스',
  },
  {
    name: 'Las Vegas',
    code: 'LAS' as CityCodeType,
    nameKo: '라스베가스',
  },
  {
    name: 'San Francisco',
    code: 'SFO' as CityCodeType,
    nameKo: '샌프란시스코',
  },
  {
    name: 'Saipan',
    code: 'SPN' as CityCodeType,
    nameKo: '사이판',
  },
  {
    name: 'Madrid',
    code: 'MAD' as CityCodeType,
    nameKo: '마드리드',
  },
  {
    name: 'Rome',
    code: 'ROM' as CityCodeType,
    nameKo: '로마',
  },
  {
    name: 'Paris',
    code: 'PAR' as CityCodeType,
    nameKo: '파리',
  },
  {
    name: 'Sevilla',
    code: 'SVQ' as CityCodeType,
    nameKo: '세비야',
  },
  {
    name: 'Venice',
    code: 'VCE' as CityCodeType,
    nameKo: '베니스',
  },
  {
    name: 'Milan',
    code: 'MIL' as CityCodeType,
    nameKo: '밀라노',
  },
  {
    name: 'Florence',
    code: 'FLR' as CityCodeType,
    nameKo: '피렌체',
  },
];
