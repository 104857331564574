import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { CalendarStateViewModel } from '^/components/calendar2/Calendar.state.viewmodel';
import {
  CitySearchInputViewModel,
} from '^/domainComponents/inputs/CitySearchInput/CitySearchInput.viewmodel';
import { popularCityList } from '^/pages/Home2/popularCityList';
import { ListingPage2RouteParams } from '^/pages/Listing2/ListingPage2RouteParams';
import {
  CityCodeType,
  RentalCarOfficeId,
  ShortBirthdayType,
  toShortBirthday,
} from '^/types/__BrandedTypes';
import {
  FreshnessType,
} from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';

import {
  createScheduleTimeViewModelFromDefaults,
  ScheduleTimeViewModel,
} from './ScheduleTimeViewModel';

export class CarListingSearchBoxInputViewModel {
  constructor(
    public appContext: AppContext,
    public freshness: FreshnessType,
    public calendarStateViewModel: CalendarStateViewModel,
    public defaultValues?: ListingPage2RouteParams,
    public pickupCityCode = new MobxInputValue<CityCodeType | null>(
      defaultValues?.pCityCode || null,
      (cityCode) => {
        this.pickupLocationId.set(null);
        if (!cityCode) {
          return;
        }
        const city = this.appContext.searchCityRepo.getById(cityCode);
        if (!city?.korean_name) {
          return;
        }
        this.pickupCitySearch.searchInput.set(city?.korean_name);
      },
    ),
    public pickupLocationId = new MobxInputValue<RentalCarOfficeId | null>(
      defaultValues?.pLocationId || null,
      (v) => {
        this.calendarStateViewModel.selection = {
          start: null,
          end: null,
        };
        this.scheduleTimeViewModel.reset();
        console.error('CarListingSearchBoxViewModel::pickuplocationId.set', v);
      },
    ),
    public returnCityCode = new MobxInputValue<CityCodeType | null>(
      defaultValues?.rCityCode || null,
      (cityCode) => {
        this.returnLocationId.set(null);
        if (!cityCode) {
          return;
        }
        const city = this.appContext.searchCityRepo.getById(cityCode);
        if (!city?.korean_name) {
          return;
        }
        this.returnCitySearch.searchInput.set(city?.korean_name);
      },
    ),
    public pickupCitySearch = new CitySearchInputViewModel(
      appContext,
      pickupCityCode,
      freshness,
      new MobxInputValue(
        defaultValues?.pCityName || '',
        (v) => {
          this.pickupLocationId.set(null);
        },
      ),
    ),
    public birthdayInput = new MobxInputValue<ShortBirthdayType>(
      defaultValues?.birthday
        ? toShortBirthday(defaultValues.birthday)
        : '' as ShortBirthdayType,
      (v) => v
        .replace(/\D/g, '')
        .slice(0, 6) as ShortBirthdayType,
    ),

    public scheduleInput = new MobxInputValue(''),
    public hasDifferentPickupReturnLocations = new MobxInputValue(
      defaultValues?.pIsDiff || false,
    ),
    public returnCitySearch = new CitySearchInputViewModel(
      appContext,
      returnCityCode,
      freshness,
      new MobxInputValue<string>(
        defaultValues?.rCityName || '',
        () => {
          this.returnLocationId.set(null);
        },
      ),
    ),
    public returnLocationId = new MobxInputValue<RentalCarOfficeId | null>(
      defaultValues?.rLocationId || null,
      () => {
        this.calendarStateViewModel.selection = {
          start: null,
          end: null,
        };
        this.scheduleTimeViewModel.reset();
      },
    ),
    public scheduleTimeViewModel = createScheduleTimeViewModelFromDefaults(
      defaultValues?.pDatetime,
      defaultValues?.rDatetime,
    ),
  ) {
    makeAutoObservable(this);
    popularCityList.forEach((city) => {
      this.appContext.searchCityRepo.setById(city.code, {
        code: city.code,
        korean_name: city.nameKo,
        name: city.name,
      });
    });
  }

  get tempScheduleTimeViewModel() {
    return new ScheduleTimeViewModel(
      this.scheduleTimeViewModel.pickupHour,
      this.scheduleTimeViewModel.pickupMinute,
      this.scheduleTimeViewModel.returnHour,
      this.scheduleTimeViewModel.returnMinute,
    );
  }

  get tempCalendarStateViewModel() {
    return new CalendarStateViewModel(
      this.calendarStateViewModel.calendarPaginationOffset.clone(),
      this.calendarStateViewModel.today,
      this.calendarStateViewModel.enabledLastDay,
      this.calendarStateViewModel.selection,
      this.calendarStateViewModel.hoverDay,
    );
  }

  get tempBirthdayInput() {
    return this.birthdayInput.clone();
  }

  get tempPickupLocationId() {
    return this.pickupLocationId.clone();
  }

  get tempReturnLocationId() {
    return this.returnLocationId.clone();
  }
}
