import { CityCodeType } from '^/types/__BrandedTypes';

export const popularCityList = [
  {
    name: 'Guam',
    code: 'GUM' as CityCodeType,
    nameKo: '괌',
  },
  {
    name: 'Honolulu',
    code: 'HNL' as CityCodeType,
    nameKo: '호놀룰루',
  },
  {
    name: 'Saipan',
    code: 'SPN' as CityCodeType,
    nameKo: '사이판',
  },
  {
    name: 'Los Angeles',
    code: 'LAX' as CityCodeType,
    nameKo: '로스앤젤레스',
  },
  {
    name: 'San Francisco',
    code: 'SFO' as CityCodeType,
    nameKo: '샌프란시스코',
  },
  {
    name: 'Las Vegas',
    code: 'LAS' as CityCodeType,
    nameKo: '라스베가스',
  },
  {
    name: 'Okinawa',
    code: 'OKA' as CityCodeType,
    nameKo: '오키나와',
  },
  {
    name: 'Fukuoka',
    code: 'FUK' as CityCodeType,
    nameKo: '후쿠오카',
  },
  {
    name: 'Sapporo',
    code: 'SPK' as CityCodeType,
    nameKo: '삿포로',
  },
  {
    name: 'Paris',
    code: 'PAR' as CityCodeType,
    nameKo: '파리',
  },
  {
    name: 'Rome',
    code: 'ROM' as CityCodeType,
    nameKo: '로마',
  },
  {
    name: 'Frankfurt',
    code: 'FRA' as CityCodeType,
    nameKo: '프랑크푸르트',
  },
  {
    name: 'Sydney',
    code: 'SYD' as CityCodeType,
    nameKo: '시드니',
  },
  {
    name: 'Lisbon',
    code: 'LIS' as CityCodeType,
    nameKo: '리스본',
  },
  {
    name: 'Milan',
    code: 'MIL' as CityCodeType,
    nameKo: '밀라노',
  },
];
