import React, { ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { Listbox } from '@headlessui/react';
import { observer } from 'mobx-react-lite';

import { borders } from '^/constant/CommonStyles';
import { COLORS2, font, SCROLL_BAR } from '^/constant/CommonStyles2';
import { ExtractReactProps } from '^/types/utils/extractReactProps';
import { MobxInputValue } from '^/util/MobxInputValue';

export const SelectBoxInput = observer(function SelectBoxInput<
  T extends string | null,
>(args: {
  inputValue: MobxInputValue<T>,
  overrides?: {
    openDirection?: 'UP',
    rootProps?: Partial<ExtractReactProps<typeof Listbox>>,
    buttonProps?: Partial<ExtractReactProps<typeof Listbox['Button']>>,
    buttonRender?: React.ComponentType<{
      value: T,
      isOpen?: boolean,
      isListItem?: boolean
    }>,
    optionsProps?: Partial<ExtractReactProps<typeof Listbox['Options']>>,
    optionProps?: Partial<ExtractReactProps<typeof Listbox['Option']>>,
    optionRender?: React.ComponentType<{
      value: T, isChosen?: boolean,
      isListItem?: boolean,
      disabled?: boolean,
    }>,
  },
  choices: Readonly<T[]>,
  disabledItems?: Set<T>,
  enabledItems?: Set<T>,
}) {
  const ButtonRenderer = args.overrides?.buttonRender;
  const OptionRenderer = args.overrides?.optionRender;
  return (
    <Listbox
      as="div"
      style={{
        position: 'relative',
        width: '50%',
      }}
      {...args.overrides?.rootProps}
      value={args.inputValue.value}
      tabIndex={-1}
      onChange={(value) => args.inputValue.set(value)}
    >

      {({ open }) => {
        return (
          <>
            <Listbox.Button
              {...args.overrides?.buttonProps}
              className={cx(
                css`
                  width: 100%;
                  border: 1px solid ${COLORS2.sub.MrtGray2};
                  border-radius: 5px;
                  
                  background-color: ${COLORS2.main.MrtWhite} !important;

                  `,
                open
                  && args.overrides?.openDirection === 'UP'
                  && css`
                    border: 1px solid ${COLORS2.main.MrtBlue1};
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                  `,
                open
                  && !(args.overrides?.openDirection)
                  && css`
                    border: 1px solid ${COLORS2.main.MrtBlue1};
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0; 
                  `,
              )}
            >
              {ButtonRenderer
                ? (
                  <ButtonRenderer
                    value={args.inputValue.value}
                    isOpen={open}
                  />
                ) : args.inputValue.value}
            </Listbox.Button>
            <Listbox.Options
              id="LISTBOX_OPTIONS"
              className={cx(
                // borders.DEBUG_greenBorderDashed,
                SCROLL_BAR.hide,
                font.Body1_Regular,
                css`
                  position: absolute;

                  width: 100%;
                  max-height: 224px;

                  z-index: 50;
                  background-color: white;
                  overflow-y: scroll;

                  & > li:not(:last-child) {
                    border-bottom: 1px solid ${COLORS2.sub.MrtGray3};
                  }
                `,
                args.overrides?.openDirection === 'UP'
                  ? css`
                      bottom: 58px;
                      left: 0;
                      filter: drop-shadow(10px -10px 30px rgba(0, 0, 0, 0.1));
                      /* for safari issue (https://github.com/mdn/browser-compat-data/issues/17726) */
                      transform: translateZ(0); 
                      
                      border-top-left-radius: 5px;
                      border-top-right-radius: 5px;
                    `
                  : css`
                      /* down by default */
                      top: 100%; left: 0;
                      filter: drop-shadow(10px 30px 30px rgba(0, 0, 0, 0.1));
                      /* for safari issue (https://github.com/mdn/browser-compat-data/issues/17726) */
                      transform: translateZ(0); 

                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    `,
              )}
              {...args.overrides?.optionsProps}
            >
              {args.choices.map((choice) => {
                const disabled = (
                  args.disabledItems?.has(choice)
                ) || (args.enabledItems && !args.enabledItems.has(choice));
                return (
                  <Listbox.Option
                    {...args.overrides?.optionProps}
                    key={choice}
                    value={choice}
                    disabled={disabled}
                  >

                    {OptionRenderer
                      ? (
                        <OptionRenderer
                          value={choice}
                          isListItem
                          disabled={disabled}
                        />
                      )
                      : choice}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </>
        );
      }}

    </Listbox>
  );
});
