import {
  CarListingSearchBoxInputViewModel,
} from './CarListingSearchBox.InputViewmodel';
import {
  guardStepImpl,
} from './SearchBoxStepManager.stepGuard';

export type Step1RentalCityStep = {
  type: '1_rental_city',
  autofocus?: 'CitySearchInput',
};

export const SearchBoxSteps = [
  { type: '0_none_selected' },
  { type: '1_rental_city' } as Step1RentalCityStep, // 1_rental_city_with_focus
  { type: '1_city_suggestion' },
  { type: '2_rental_office' },
  { type: '2_5_selected_rental_office' },
  { type: '3_schedule' },
  { type: '4_birthday' },
  { type: '5_return_city' },
  { type: '6_return_office' },
  { type: '6_5_selected_return_office' },
  { type: 'FILTER' },
] as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchBoxSteps = typeof SearchBoxSteps[number];
// eslint-disable-next-line @typescript-eslint/no-redeclare
// export type SearchBoxSteps = z.infer<typeof SearchBoxSteps>;

export type WithSteps = {
  steps?: SearchBoxSteps[];
};

export type StepChangeOptions = {
  afterStepChange(actualNextStep: SearchBoxSteps): void;
};

export abstract class BaseSearchBoxStepManager<
  TRouteParams extends WithSteps = any,
> {
  abstract getInputViewModel(): CarListingSearchBoxInputViewModel;
  abstract getSteps(): SearchBoxSteps[];
  abstract pushStepsToRoute(
    steps: SearchBoxSteps[],
  ): void;

  abstract replaceStepsInRoute(
    steps: SearchBoxSteps[],
  ): void;

  abstract updateRouteParams(routeParams: TRouteParams): void;

  abstract updatePushCountBy(by: number): void;

  guardStep(maybeNextStep: SearchBoxSteps): SearchBoxSteps {
    const inputVM = this.getInputViewModel();
    const result = guardStepImpl(
      maybeNextStep,
      inputVM,
    );
    console.log(
      `guardStep:: input: ${maybeNextStep.type} / output: ${result.type}`);
    return result;
  }

  pushStep(
    step: SearchBoxSteps,
    options?: Partial<StepChangeOptions>,
  ) {
    console.log(
      'pushStep: ',
      'was: ',
      this.getSteps(),
      ' / to: ',
      step,
    );
    const guardProcessedStep = this
      .guardStep(step);
    const oldSteps = this.getSteps() || [];
    this.pushStepsToRoute([...oldSteps, guardProcessedStep]);
    if (options?.afterStepChange) {
      options.afterStepChange(guardProcessedStep);
    }
  }

  pushStepWithOptions(
    step: SearchBoxSteps,
    options: Partial<StepChangeOptions>,
  ) {
    this.pushStep(step, options);
  }

  clearStep() {
    this.replaceStepsInRoute([]);
  }

  replaceStep(
    step: SearchBoxSteps,
    options?: Partial<StepChangeOptions>,
  ) {
    console.error('replaceStep', step);
    const guardProcessedStep = this
      .guardStep(step);
    const oldStepsSlice = (this.getSteps()
      ?.slice(0, -1)) || [];
    this.replaceStepsInRoute([...oldStepsSlice, guardProcessedStep]);
    if (options?.afterStepChange) {
      options.afterStepChange(guardProcessedStep);
    }
  }

  replaceStepWithOptions(
    step: SearchBoxSteps,
    options: Partial<StepChangeOptions>,
  ) {
    this.replaceStep(step, options);
  }

  get currentStep() {
    return this.getSteps().slice(-1)[0] || null;
  }

  set currentStep(newValue: SearchBoxSteps | null) {
    if (!newValue) {
      this.clearStep();
      return;
    }
    if ((this.getSteps()?.length || 0) > 0) {
      this.replaceStep(newValue, {});
    } else {
      this.pushStep(newValue, {});
    }
  }
}
