import React, { useRef, useState, useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import { ViewByState } from '^/commonComponents/ui/ViewByState';
import { Layout2Desktop } from '^/components/Layout/Layout2Desktop';
import { borders } from '^/constant/CommonStyles';
import { COLORS2, SPACING } from '^/constant/CommonStyles2';
import { CarListingSearchBoxDesktop } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.desktop';
import { useClickOutsideComponent } from '^/hooks/useClickOutsideComponent';

import { ListingPage2DesktopCard } from './ListingPage2.Desktop.Card';
import { ListingPage2DesktopFilter } from './ListingPage2.Desktop.Filter';
import { ListingPage2ViewModel } from './ListingPage2.viewmodel';
import { ListingPageDesktopLoading } from './ui/ListingPage.Desktop.Loading';
import { ListingPage2DesktopNoCar } from './ui/ListingPage2.Desktop.NoCar';

const classNames = {
  listingPage2DesktopCardWrapper: css`
    width: 100%;

    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: ${SPACING.spacing56};

    display: flex;
    flex-direction: column;
    gap: ${SPACING.spacing32};
  `,
};

export const ListingPage2Desktop: React.FC<{
  viewmodel: ListingPage2ViewModel,
}> = observer(function ListingPage2Desktop({
  viewmodel,
}) {
  const [hoverSearchBox, setHoverSearchBox] = useState(false);
  const carListngSearchBoxRef = useRef<HTMLDivElement>(null);
  useClickOutsideComponent(carListngSearchBoxRef, () => {
    viewmodel.carListingSearchBox
      .stepManager.clearStep();
  });

  const isOpenCarListingBottomSheetContenArea = viewmodel.stepManager.currentStep !== null
    && viewmodel.stepManager.currentStep?.type !== '4_birthday';

  const carsScrollAreaRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    carsScrollAreaRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [
    viewmodel.routeParamsValues.pCityCode,
    viewmodel.routeParamsValues.pLocationId,
    viewmodel.routeParamsValues.pDatetime,
    viewmodel.routeParamsValues.rCityCode,
    viewmodel.routeParamsValues.rLocationId,
    viewmodel.routeParamsValues.rDatetime,
    viewmodel.routeParamsValues.birthday,
    viewmodel.routeParamsValues.freshness,
    viewmodel.routeParamsValues.pIsDiff,
  ]);
  return (
    <Layout2Desktop
      withoutFooter
      withoutMaxWidth
      withoutOverflowScroll
      withoutNavigationFullRefresh
    >
      <div
        ref={carsScrollAreaRef}
        className={cx(
          css`
            background-color: ${COLORS2.sub.MrtGray3};
            width: 100%;
            height: 100%;
            overflow-y: scroll;

          `,
          // borders.DEBUG_blueBorderDashed,
        )}
      >
        <div
          className={cx(
            css`
              position: sticky;
              top: 0px;
              width: 100%;
              max-width: 1320px;
              margin-left: auto;
              margin-right: auto;
              background-color: ${COLORS2.sub.MrtGray3};
              padding-top: ${SPACING.spacing24};
              padding-bottom: ${SPACING.spacing32};
              z-index: 30;
            `,
            // borders.DEBUG_purpleBorderDashed,
          )}
        >
          <div>
            <div
              ref={carListngSearchBoxRef}
              // tabIndex={-1}
              onFocus={() => setHoverSearchBox(true)}
              onBlur={(e) => {
                if (e.currentTarget.contains(e.relatedTarget)) {
                  return;
                }
                setHoverSearchBox(false);
              }}
            >
              <CarListingSearchBoxDesktop
                // smaller={!hoverSearchBox}
                smaller
                viewmodel={viewmodel.carListingSearchBox}
                onBlur={() => viewmodel.carListingSearchBox
                  .stepManager
                  .clearStep()}
              />
            </div>

            <PreTagForDebugging
              obj={{
                allCarListStatus: viewmodel.allCarList.status,
                getSteps: viewmodel.stepManager.getSteps(),
                currentStep: viewmodel.stepManager.currentStep,
              }}
              isShow={false}
              className={css`
                position: absolute;
                top: 500px;
              `}
            />

            {viewmodel.allCarList.status === 'success' && (
              <ListingPage2DesktopFilter
                carsScrollAreaRef={carsScrollAreaRef}
                isOpenCarListingBottomSheetContenArea={isOpenCarListingBottomSheetContenArea}
                loadedViewModel={viewmodel.allCarList.value}
                filterViewModel={viewmodel.filterViewModel}
                hasDifferentPickupReturnLocations={viewmodel.carListingSearchBox.i.hasDifferentPickupReturnLocations}
              />
            )}
          </div>

        </div>

        <ViewByState
          errorView={(e) => {
            if (e.reason === 'Timeout') {
              return <ListingPage2DesktopNoCar />;
            }
            return (
              <h1> 에러: {e.reason} </h1>
            );
          }}
          loadingView={<ListingPageDesktopLoading />} // TODO: fffffff
          data={viewmodel.allCarList}
        >
          {(carsList) => {
            if (carsList.filteredList.length === 0) {
              return (
                <ListingPage2DesktopNoCar />
              );
            }
            return (
              <section
                className={classNames.listingPage2DesktopCardWrapper}
              >

                {carsList
                  .filteredList
                  .filter((b) => b.data.POD?.payment.domesticPrice)
                  .map((c) => {
                    return (
                      <ListingPage2DesktopCard
                        key={c.data.core.id}
                        viewmodel={c}
                      />
                    );
                  })}
              </section>
            );
          }}
        </ViewByState>
      </div>
    </Layout2Desktop>
  );
});
