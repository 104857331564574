import { format, sub } from 'date-fns';

import { ShortBirthdayType } from '^/types/__BrandedTypes';

export const now = new Date();
// FIXME: Too ugly code

export const ages = [
  {
    text: '만 30세 이상',
    age: `${String((format(sub(now, { years: 31 }), 'yyyy'))).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 29세',
    age: `${String(format(sub(now, { years: 30 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 28세',
    age: `${String(format(sub(now, { years: 29 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 27세',
    age: `${String(format(sub(now, { years: 28 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 26세',
    age: `${String(format(sub(now, { years: 27 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 25세',
    age: `${String(format(sub(now, { years: 26 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 24세',
    age: `${String(format(sub(now, { years: 25 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 23세',
    age: `${String(format(sub(now, { years: 24 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 22세',
    age: `${String(format(sub(now, { years: 23 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 21세',
    age: `${String(format(sub(now, { years: 22 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
  {
    text: '만 20세',
    age: `${String(format(sub(now, { years: 21 }), 'yyyy')).slice(2)}0101` as ShortBirthdayType,
  },
] as const;
