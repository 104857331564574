import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { CityCodeType } from '^/types/__BrandedTypes';
import { getCurrentFreshness } from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';
import { ExtendedMobxInputValue } from '^/util/MobxInputValue.utils';

export class CitySearchInputViewModel {
  constructor(
    public appContext: AppContext,
    public selectedCityCode: MobxInputValue<CityCodeType | null>,
    public freshness = getCurrentFreshness(),
    public searchInput = new MobxInputValue(''),
    public showAutosuggestList = new MobxInputValue(false),
    // TODO: need better name...
    public searchInputThatResetsCityCode
    = new ExtendedMobxInputValue(searchInput, {
      afterOnChange: (value) => {
        this.selectedCityCode.set(null);
        this.showAutosuggestList.set(true);
      },
    }),
  ) {
    makeAutoObservable(this);
  }

  get searchResultList() {
    return this.appContext
      .searchCityListCore
      .getList({
        search: this.searchInput.value,
      }, this.freshness);
  }
}
