import React from 'react';

import { css, cx } from '@emotion/css';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';

import { BlueLoadingSpinner } from '^/commonComponents/inputs/BlueLoadingSpinner';
import { PreTagForDebugging } from '^/commonComponents/ui/PreTagForDebugging';
import { COLORS2, SPACING } from '^/constant/CommonStyles2';
import { CalendarWithTimeDesktop } from '^/domainComponents/inputs/CalendarWithTime/CalendarWithTime.Desktop';
import { RentalOfficeInput } from '^/domainComponents/inputs/RentalOfficeInput/RentalOfficeInput';

import { CarListingSearchBoxViewModel } from '../CarListingSearchBox.viewmodel';

import { PopularCity } from './PopularCity';
import { popularCityDefaultStyles } from './PopularCity.defaultStyles';

export const CarListingSearchBoxDesktopContentsArea: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
}> = observer(function CarListingSearchBoxDesktopContentsArea({
  viewmodel,
}) {
  const {
    stepManager,
  } = viewmodel;
  if (stepManager.currentStep?.type === '1_rental_city') {
    return (
      <PopularCity
        viewmodel={viewmodel.i.pickupCitySearch}
        onBlur={() => {
          stepManager.clearStep();
        }}
        cityCount={15}
        onSelectCity={() => {
          stepManager.replaceStep({
            type: '2_rental_office',
          });
        }}
        classOverrides={{
          cityButton: popularCityDefaultStyles.desktop.cityButton,
        }}
      />
    );
  }

  if (stepManager.currentStep?.type === '2_rental_office') {
    if (viewmodel.pickupLocationViewModel.status === 'loading') {
      return (
        <BlueLoadingSpinner
          classOverrides={{
            spinner: css`
              width: 24px;
            `,
          }}
        />
      );
    }
    if (viewmodel.pickupLocationViewModel.status === 'failure') {
      return <h1> 에러가 발생하였습니다 </h1>;
    }

    return (
      <RentalOfficeInput
        viewmodel={viewmodel.pickupLocationViewModel.value}
        onBlur={() => {
          // stepManager.clearStep();
          // // see: https://stackoverflow.com/a/60094794/19531789
          // viewmodel
          //   .pickupLocationId
          //   .setHasBlurred(true);
        }}
        onSelectRentalOffice={() => {
          if (viewmodel.i.hasDifferentPickupReturnLocations.value === false) {
            stepManager.replaceStep({
              type: '3_schedule',
            });
          } else {
            stepManager.replaceStep({
              type: '5_return_city',
            });
          }
        }}
      />
    );
  }
  if (stepManager.currentStep?.type === '3_schedule') {
    const returnLocation = viewmodel
      .i.hasDifferentPickupReturnLocations.value === true
      ? viewmodel.returnLocation
      : viewmodel.pickupLocation;
    if (!viewmodel.pickupLocation || !returnLocation) {
      console.error(
        'SHOULD NOT HAPPEN!',
        '!viewmodel.pickupLocation || !viewmodel.returnLocation',
        'inside: ',
      );
      return null;
    }
    return (
      <motion.div
        className={cx(
          css`
            margin-top: ${SPACING.spacing24};
            z-index: -10;
            background-color: ${COLORS2.main.MrtWhite};
            display: grid;
            grid-template-columns: 2fr clamp(340px, 1fr, 480px);
          `,
          viewmodel.i.hasDifferentPickupReturnLocations.value && css`
            margin-top: ${SPACING.spacing40};
          `,
        )}
      >
        <PreTagForDebugging
          obj={{
            temp: viewmodel.i.tempCalendarStateViewModel.calendarPaginationOffset.value,
            real: viewmodel.i.calendarStateViewModel.calendarPaginationOffset.value,
          }}
          isShow={false}
        />
        <CalendarWithTimeDesktop
          hasDifferentPickupReturnLocations={viewmodel.i.hasDifferentPickupReturnLocations}
          pickupLocation={viewmodel.pickupLocation}
          returnLocation={returnLocation}
          stepManager={stepManager}
          calendarStateViewModel={viewmodel.i.tempCalendarStateViewModel}
          calendarListViewModel={viewmodel.calendarListViewModel}
          scheduleTimeViewModel={viewmodel.i.tempScheduleTimeViewModel}
          onAllDateSelected={() => {
            viewmodel
              .stepManager
              .replaceStep({
                type: '4_birthday',
              });
          }}
          onSubmit={() => {
            viewmodel
              .i
              .calendarStateViewModel
              .copyFrom(viewmodel.i.tempCalendarStateViewModel);
            viewmodel
              .i
              .scheduleTimeViewModel
              .copyFrom(viewmodel.i.tempScheduleTimeViewModel);
          }}
          isMobile={false}
          onBlur={() => {
            viewmodel.i.tempScheduleTimeViewModel.copyFrom(
              viewmodel.i.scheduleTimeViewModel,
            );
          }}
        />
        {/* <CalendarRoot
          selectedStepModel={viewmodel.selectedStep}
          calendarListViewModel={viewmodel.calendarListViewModel}
          onBlur={() => {
            stepManager.clearStep();

            viewmodel.scheduleInput
              .setHasBlurred(true);
          }}
        /> */}

      </motion.div>
    );
  }
  return null;
});
