import { ConfigType } from './ConfigType';

export const DevConfig: ConfigType = {
  REACT_APP_API_KEY: 'MDRjMDMxNTAtZjUzNC00MTI3LWI2YTItM2ExYjZhZjM4NmEw',
  REACT_APP_BASE_API_URL: 'https://api-dev.trimo.ai/',
  REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyDNH-AGkfsyTCfNlLU4877jUIbW5wOP3T0',
  REACT_APP_IMP_CREDENTIAL: 'imp73412023',
  REACT_APP_IMP_INICIS: 'INIpayTest',
  REACT_APP_KAKAO_REST_API_KEY: '43c23c7ecfadc7c4b22337d8e0fbfe6c',
  REACT_APP_KAKAO_CLIENT_SECRET: 'K4RNOfAE12D2mFLvL2ZdaUEbL9a2spZW',
  REACT_APP_ROOT: 'trimo',
  REACT_APP_TRANSLATION_PATH: 'https://trimo-rental-car.s3.ap-southeast-1.amazonaws.com/translation/{{lng}}.json',

  CONFIG_NAME: 'dev',
};

export default function getDevConfig() {
  return DevConfig;
}
