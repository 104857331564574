/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { TextInput } from '^/commonComponents/inputs/TextInput';
import { COLORS2, font, SPACING } from '^/constant/CommonStyles2';
import { HELP_PAGE, RESERVATION_CHECK } from '^/constant/routes';
import { CarListingBottomSheetWrapper } from '^/domainComponents/CarListingBottomSheet/CarListingBottomSheetWrapper';
import { PopularCity } from '^/domainComponents/forms/CarListingSearchBox/components/PopularCity';
import { popularCityDefaultStyles } from '^/domainComponents/forms/CarListingSearchBox/components/PopularCity.defaultStyles';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { HomePage2ViewModel } from './HomePage2.viewmodel';

export const HomePage2Mobile: React.FC<{
  viewmodel: HomePage2ViewModel,
}> = observer(function HomePage2Mobile({
  viewmodel,
}) {
  const navigate = useNavigate();
  const pickupCityInputRef = useRef<HTMLInputElement>(null);
  const pickupCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);
  const returnCityInputDummyRefForIOSKeyboard = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const unlisten = typeRouter.session.listen((
      nextRoute,
    ) => {
      if (nextRoute.action === 'pop') {
        console.log(
          'detected go back!',
          nextRoute,
        );
        viewmodel
          .carListingSearchBox
          .stepManager
          .updatePushCountBy(-1);
      }
    });
    return unlisten;
  });

  return (
    <>
      <TextInput
        id="DummyInputForPickupCityIOSKeyboardPopup"
        rootRef={pickupCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel
          .carListingSearchBoxInputVM
          .pickupCitySearch
          .searchInput}
        className={css`
          position: fixed;
          top:0; left: 0;
          bottom: 0;
          opacity: 0.5;
          z-index: -200;
          /* hide caret from screen */
          transform: translate(-100px, -100px);
        `}
      />
      <TextInput
        id="DummyInputForReturnCityIOSKeyboardPopup"
        rootRef={returnCityInputDummyRefForIOSKeyboard}
        viewmodel={viewmodel
          .carListingSearchBoxInputVM
          .returnCitySearch
          .searchInput}
        className={css`
          position: fixed;
          top:0; left: 0;
          bottom: 0;
          opacity: 0.2;
          z-index: -500;
          /* hide caret from screen */
          transform: translate(-100px, -100px);
        `}
      />
      {/* <pre
        className={css`
          position: fixed;
          top: 0;
          left: 10px;
          background: yellow;
          z-index: 11111111;
          opacity: 0.3;
          pointer-events: none;
        `}
      >
        {JSON.stringify(
          viewmodel.carListingSearchBox
            .stepManager
            .getSteps(),
          null,
          2,
        )}
      </pre> */}
      {(viewmodel.carListingSearchBox
        .stepManager
        .getSteps()
        .length || null) && (
        <CarListingBottomSheetWrapper
          pickupCityInputRef={pickupCityInputRef}
          pickupCityInputDummyRefForIOSKeyboard={
            pickupCityInputDummyRefForIOSKeyboard
          }
          returnCityInputDummyRefForIOSKeyboard={
            returnCityInputDummyRefForIOSKeyboard
          }
          viewmodel={viewmodel.carListingSearchBox}
          carsScrollAreaRef={null}
          carClassChipsScrollRef={null}
        />
      )}
      <div
        className={css`
          padding-left: ${SPACING.spacing24};
          padding-right: ${SPACING.spacing24};
          padding-top: ${SPACING.spacing24};
        `}
      >
        {/* <pre> {JSON.stringify(viewmodel.routeParams)} </pre> */}
        <h1 className={cx(
          font.Title2,
          css`
          display: flex;
          flex-direction: column;
          gap: 12px;
        `,
        )}
        >
          <div>해외 렌터카</div>
          <div>최저가로 찾아보세요.</div>
        </h1>

        <div
          onClick={() => {
            if (viewmodel
              .carListingSearchBox
              .pickupLocation
            ) {
              // goto: step0...?
              viewmodel.carListingSearchBox
                .stepManager
                .pushStep({ type: '0_none_selected' });
            } else {
              viewmodel.carListingSearchBox
                .stepManager
                .pushStep({ type: '0_none_selected' });
              viewmodel.carListingSearchBox
                .stepManager
                .pushStep({
                  type: '1_rental_city',
                  autofocus: 'CitySearchInput',
                });
              pickupCityInputDummyRefForIOSKeyboard.current?.focus();
            }
          }}
          className={cx(
            font.Body1_Bold,
            css`
              display: flex;
              align-items: center;
              gap: ${SPACING.spacing16};

              padding: ${SPACING.spacing16};
              margin-top: ${SPACING.spacing24};

              width: 100%;
              height: ${SPACING.spacing56};
              border: 1.5px solid ${COLORS2.main.MrtBlue1};
              border-radius: 5px;
              color: ${COLORS2.sub.MrtGray1};
          `,
            viewmodel
              .carListingSearchBox
              .i.pickupCitySearch
              .searchInput.value && css`
                  color: ${COLORS2.main.MrtBlack};
                `,
          )}
        >
          <img
            width="24px"
            height="24px"
            alt="search-city"
            src="./ic_search_blue.svg"
          />
          <span>
            {
              viewmodel
                .carListingSearchBox
                .i.pickupCitySearch
                .searchInput.value || '도시를 검색해주세요.'
            }
          </span>
        </div>

        {/* popular city */}
        <div>
          <PopularCity
            onSelectCity={() => {
              viewmodel.carListingSearchBox
                .stepManager.pushStep({ type: '0_none_selected' });
              viewmodel.carListingSearchBox
                .stepManager.pushStep({ type: '2_rental_office' });
            }}
            viewmodel={viewmodel.carListingSearchBox.i.pickupCitySearch}
            onBlur={() => null} // FIXME: is it right?
            cityCount={12}
            classOverrides={{
              cityButton: popularCityDefaultStyles.mobile.cityButton,
            }}
          />
        </div>
        {/* 예약 조회하기 */}
        <div
          className={css`
          display: flex;
          gap: ${SPACING.spacing24};

          width: 100%;
          padding: 9px ${SPACING.spacing16};
          margin-top:${SPACING.spacing16};

          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
          border-radius: 5px;
        `}
          // onClick={() => typeRouter.routes.checkReservation().push()}
          onClick={() => navigate(RESERVATION_CHECK)}

        >
          <img
            alt="booking-inquiry"
            src="./ic_ticket_2.svg"
          />
          <div className={css`
          display: flex;
          flex-direction: column;
          /* gap: 4px; */
        `}
          >
            <div className={cx(
              css`
            `,
              font.Body1_Bold,
            )}
            >
              예약 조회하기
            </div>
            <div className={cx(
              font.Caption2_Light,
              css`
              color: ${COLORS2.sub.MrtGray1};
            `,
            )}
            >
              내 예약 조회하기
            </div>
          </div>
        </div>

        {/* FAQ */}
        <div
          className={css`
          display: flex;
          gap: ${SPACING.spacing24};

          width: 100%;
          padding: 9px ${SPACING.spacing16};
          margin-top:${SPACING.spacing16};
          margin-bottom: 50px;

          box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
          border-radius: 5px;
        `}
          // onClick={() => typeRouter.routes.help().push()}
          onClick={() => navigate(HELP_PAGE)}
        >
          <img
            alt="booking-inquiry"
            src="./ic_chat.svg"
          />
          <div className={css`
          display: flex;
          flex-direction: column;
        `}
          >
            <div className={cx(
              css`
            `,
              font.Body1_Bold,
            )}
            >
              FAQ
            </div>
            <div className={cx(
              font.Caption2_Light,
              css`
              color: ${COLORS2.sub.MrtGray1};
            `,
            )}
            >
              자주 묻는 질문
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
