import React from 'react';

import { css, cx } from '@emotion/css';

import { font, SPACING } from '^/constant/CommonStyles2';

import { TimeSelectMinuteNullable } from './TimeSelect.constants';
import { classNames } from './TimeSelect.styles';

export const MinuteRenderer: React.FC<{
  value: TimeSelectMinuteNullable;
  isOpen?: boolean;
  isListItem?: boolean;
}> = ({ value, isOpen, isListItem }) => {
  return (
    <div
      className={isListItem
        ? cx(classNames.btnRendererBasic, font.Body1_Regular)
        : classNames.btnRendererBasic}
    >
      <span>{value}분</span>
      {!isListItem && (
        <img
          alt="minute-list-toggle"
          src={isOpen
            ? './ic_arrow_up.png'
            : './ic_arrow_down.png'}
          className={css`
          @media (max-width: 768px) {
            width: ${SPACING.spacing16};
            height: ${SPACING.spacing16};
          }
        `}
        />
      )}
    </div>
  );
};
