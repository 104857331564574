/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import Sticky from 'react-sticky-el';

import {
  TABLET_SIZE,
} from '../../constant/envConfig';
import {
  HELP_PAGE,
  RESERVATION_CHECK,
} from '../../constant/routes';
import './index.scss';

const NAVIGATION_MRT = [
  {
    name: 'reservationInquiry',
    url: RESERVATION_CHECK,
  },
  {
    name: 'serviceCenter',
    url: HELP_PAGE,
  },
];

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isTablet, setIsTablet] = useState(window.innerWidth <= TABLET_SIZE);
  const subdomain = 'myrealtrip';

  const handleResize = useCallback(() => {
    if (window.innerWidth <= TABLET_SIZE) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleNavigate = useCallback((url) => {
    localStorage.setItem('redirect_url', url);
    navigate(url);
  }, []);

  return (
    <>
      <Sticky
        stickyClassName="top-0 z-40 w-screen"
        topOffset={-95}
        bottomOffset={100}
        hideOnBoundaryHit={false}
      >
        <div
          className={[
            'bg-white flex h-[60px] w-full flex-row py-[28px]',
            'header m-auto items-center justify-center border-b-2 border-b py-6 mobile:px-[24px]',
          ].join(' ')}
        >
          <div
            className="flex flex-row justify-between mobile:w-full tablet:w-[1320px]"
          >
            <div className="flex flex-1 items-center">
              <button
                type="submit"
                onClick={() => {
                  // navigate('/');
                  window.location.href = window.location.origin;

                  window.scrollTo(0, 0);
                }}
              >
                <img
                  src={`/${subdomain}/trimo_logo_black.png`}
                  alt="logo"
                  className="h-[28px] w-[225px] hover:cursor-pointer"
                />
              </button>
            </div>
            {!isTablet && (
              <div className="flex flex-1 flex-row items-center justify-end">
                {NAVIGATION_MRT.map((nav) => (
                  <div
                    key={nav?.name}
                    className={[
                      'flex cursor-pointer flex-row items-center px-[28px] text-big font-bold text-primary-gray hover:cursor-pointer hover:rounded-full hover:bg-neutral-100 hover:py-2',
                      pathname === nav?.url ? 'font-bold' : '',
                    ].join(' ')}
                    onClick={() => {
                      handleNavigate(nav?.url);
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t(nav?.name)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Sticky>
    </>
  );
};

Header.propTypes = {};

export default Header;
