import { useRef, useLayoutEffect } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { font, SPACING } from '^/constant/CommonStyles2';
import {
  CitySearchInputViewModel,
} from '^/domainComponents/inputs/CitySearchInput/CitySearchInput.viewmodel';
// import { popularCityList } from '^/pages/Home2/popularCityList';
import { popularCityList2 } from '^/pages/Home2/popularCityList2';
import { CityCodeType } from '^/types/__BrandedTypes';

import { popularCityDefaultStyles } from './PopularCity.defaultStyles';

export const PopularCity: React.FC<{
  viewmodel: CitySearchInputViewModel,
  onBlur: () => void,
  cityCount: number,
  onSelectCity(cityCode: CityCodeType): void;
  classOverrides: {
    cityButton?: string
  }
}> = observer(function PopularCity({
  viewmodel,
  onBlur,
  cityCount,
  onSelectCity,
  classOverrides,
}) {
  const elemRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    elemRef.current?.focus();
    console.log('elemRef.current focus', elemRef.current);
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const isMobileStep1 = cityCount === 15;
  return (
    <div
      tabIndex={-1}
      onBlur={(e) => {
        // see: https://stackoverflow.com/a/60094794/19531789
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }
        if (onBlur) {
          onBlur();
        }
      }}
      className={cx(
        // eslint-disable-next-line no-nested-ternary
        isMobile
          ? isMobileStep1
            ? css`
                padding: 0;
                margin-top: ${SPACING.spacing24};
              `
            : popularCityDefaultStyles.mobile.rootWrapper
          : popularCityDefaultStyles.desktop.rootWrapper,
        isMobileStep1 && css`
          box-shadow: none;
        `,
      // borders.DEBUG_goldDashed,
      )}
    >
      {/* {JSON.stringify({ selectedCityCode: viewmodel.selectedPickupCityCode }, null, 2)} */}
      <h1
        className={
          // eslint-disable-next-line no-nested-ternary
          isMobile
            ? isMobileStep1
              ? font.Body1_Regular
              : popularCityDefaultStyles.mobile.title
            : popularCityDefaultStyles.desktop.title
        }
      >인기 도시
      </h1>
      <div
        className={cx(
          isMobile
            ? popularCityDefaultStyles.mobile.gridwrapper
            : popularCityDefaultStyles.desktop.gridWrapper,
        )}
      >
        {popularCityList2
          .filter((_, i) => (i < cityCount))
          .map((
            city,
          ) => (
            <div
              role="menuitem"
              tabIndex={-1}
              onClick={() => {
                viewmodel
                  .selectedCityCode
                  .set(city.code);
                viewmodel.searchInput.set(city.nameKo);
                if (onSelectCity) {
                  onSelectCity(city.code);
                }
              }}
              key={city.code}
              className={classOverrides.cityButton}
            >
              {city.nameKo}
            </div>
          ))}
      </div>
    </div>
  );
});
