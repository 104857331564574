/* eslint-disable jsx-a11y/no-static-element-interactions */
import { MouseEvent, RefObject } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { InputElemProps } from '^/commonComponents/inputs/TextInput';
import { borders } from '^/constant/CommonStyles';
import { SPACING } from '^/constant/CommonStyles2';
import { CarListingSearchBoxViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import { CitySearchInputButton } from '^/domainComponents/forms/CarListingSearchBox/headerArea/CitySearchInputButton';
import { RentalOfficeInputButton } from '^/domainComponents/forms/CarListingSearchBox/headerArea/RentalOfficeInputButton';
import { CitySearchInputViewModel } from '^/domainComponents/inputs/CitySearchInput/CitySearchInput.viewmodel';
import { ExtractReactProps } from '^/types/utils/extractReactProps';

import { CarListingBottomSheetHeader } from './CarListingBottomSheet.header';

type RentalOfficeInputVM = ExtractReactProps<typeof RentalOfficeInputButton>['viewmodel'];
export const CarListingBottomSheetSearchConditionHeader: React.FC<{
  citySearchInputRef?: RefObject<HTMLInputElement>,
  citySearchInputProps?: InputElemProps,
  viewmodel: CarListingSearchBoxViewModel;
  locationViewModel: RentalOfficeInputVM;
  children: JSX.Element;
  citySearch: CitySearchInputViewModel;
  onClickCityInputButton(e: MouseEvent): void;
  isCityInputSelected: boolean,
  onSelectCity: ExtractReactProps<typeof CitySearchInputButton>['onSelectCity'];
  isRentalOfficeInputSelected: boolean,
  onClickRentalOfficeInputButton(e: MouseEvent): void;
  title: string;
  rentalOfficeInputPlaceHolder?: string;
  citySearchInputPlaceholder: string;
}> = observer(function CarListingBottomSheetSearchConditionHeader({
  citySearchInputRef,
  citySearchInputProps,
  viewmodel,
  locationViewModel,
  children,
  citySearch,
  onClickCityInputButton,
  isCityInputSelected,
  onSelectCity,
  isRentalOfficeInputSelected,
  onClickRentalOfficeInputButton,
  title,
  rentalOfficeInputPlaceHolder,
  citySearchInputPlaceholder,
}) {
  return (
    <div
      className={cx(
        css`
          height: 100%;
          width: 100%;
          /* background-color: pink; */
        `,
        // borders.DEBUG_blueBorderDashed,
      )}
    >
      <CarListingBottomSheetHeader
        title={title}
        // title="대여 / 반납 위치"
        leftCornorBtnType="prevStack"
        stepManager={viewmodel
          .stepManager}
      />
      <div
        className={
          css`
            display: flex;
            flex-direction: column;

            padding: ${SPACING.spacing24};
            padding-top: 12px;
            height: calc(100% - 56px);
            overflow: scroll;
          `
        }
      >
        <div onClick={onClickCityInputButton}>
          {/* {viewmodel.i.hasDifferentPickupReturnLocations} */}
          <CitySearchInputButton
            placeholder={citySearchInputPlaceholder}
            inputRef={citySearchInputRef}
            viewmodel={citySearch}
            title=""
            showSuggestions={isCityInputSelected}
            onSelectCity={onSelectCity}
            inputElemProps={citySearchInputProps}
          />
        </div>

        <div
          className={css`
            margin-top: 12px;
          `}
          onClick={onClickRentalOfficeInputButton}
        >
          <RentalOfficeInputButton
            viewmodel={locationViewModel}
            isSelected={isRentalOfficeInputSelected}
            placeholder={rentalOfficeInputPlaceHolder}
          />
        </div>

        {children}
      </div>
    </div>
  );
});
