const foo = 'F MMM DD';

foo.split(' '); // ?

export function changeToUpperCaseOfWordOnlyFistAlpahbet(word: string) {
  if (!word.length) return '';

  return word.trim().split(' ')
    .map((splitedWord) => splitedWord.toLowerCase())
    .map((lowerCaseWord) => {
      const firstAlphabet = lowerCaseWord[0]?.toUpperCase();
      const res = firstAlphabet + lowerCaseWord.slice(1);

      return res;
    })
    .join(' ');
}

const bar = 'A CHEVROLET SPARK OR SIMILAR ';

const zoo = changeToUpperCaseOfWordOnlyFistAlpahbet(bar); // ?

export function deleteGrade(modelName: string) {
  return modelName.split(' ').slice(1).join(' ');
}

changeToUpperCaseOfWordOnlyFistAlpahbet(foo); // ?

changeToUpperCaseOfWordOnlyFistAlpahbet(''); // ?
deleteGrade(zoo); // ?
