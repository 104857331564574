import { css } from '@emotion/css';

import { COLORS } from '^/constant/CommonStyles';

export const DefaultTypedSelectStyles = {
  root: css`
    position: absolute;
    top: 0; left: 0;
    bottom: 0;
    z-index: 2;
  `,
  button: css`
    display: flex;
    width: 80px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
  `,
  optionsList: css`
    transform: translateY(-10px);
    z-index: 100;
    height: 450px;
    width: 520px;
    background-color: white;
    border-left: solid 1px ${COLORS.LIGHT_GREY};
    border-right: solid 1px ${COLORS.LIGHT_GREY};
    border-bottom: solid 1px ${COLORS.LIGHT_GREY};
    /* border: solid 1px green; */
    overflow-y: scroll;
    padding-bottom: 30px;
    border-end-start-radius: 5px;
    border-end-end-radius: 5px;

  `,
  optionItem: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 44px;
  `,
};

export type TypedSelectClassNameOverrides = typeof DefaultTypedSelectStyles;
