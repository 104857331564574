import { css, cx } from '@emotion/css';

import { font, COLORS2, SPACING } from '^/constant/CommonStyles2';

export const defaultCalendarWithTimeMobileStyles = {
  scheduleWrapper: css`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  title: cx(
    font.Caption1_Light,
    css`color: ${COLORS2.sub.MrtGray1};`,
  ),
  date: cx(
    font.Headline1_Bold,
    css`margin-top: 12px;`,
  ),
  timeSelectWrapper: css`
  margin-top: ${SPACING.spacing16};
  width: 100%;
`,
} as const;
