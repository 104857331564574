import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

const BANNER_TYPES = {
  clock: './badge_alarm.png',
  speaker: './badge_speaker.png',
  bell: './badge_notice.png',
  calendar: './badge_calendar.webp',
};
const SpanNotice = ({ content, type, hasIcon = true }) => {
  return (
    <div className="flex align-center mt-6">
      {hasIcon && <img className="mr-2 w-[36px] h-[36px]" src={BANNER_TYPES[type]} alt="banner-type" />}
      <span className="text-default text-primary-gray px-3 py-[9px] h-[36px] bg-secondary-gray-light rounded-[18px]">{content}</span>
    </div>
  );
};
SpanNotice.propTypes = {
  content: PropTypes.string,
  type: PropTypes.string,
  hasIcon: PropTypes.bool,
};

SpanNotice.propTypes = {
  size: PropTypes.string,
};
const Banner = (props) => {
  const { item, onClick, hasIcon } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const containerClassNames = `whitespace-pre-line hover:cursor-pointer ${item.size === 'small' || item.size === 'horizontal' ? 'row-span-1' : 'row-span-2'} ${
    item.size === 'large' || item.size === 'horizontal' && 'col-span-2 laptop-max-min:col-span-4'
  } tablet-max:col-span-2`;
  const subContainer = `tablet:hover:shadow-banner-hover delay-300  duration-300 rounded-trimo  ease-in-out shadow-trimo
  ${item.size === 'horizontal' ? 'tablet:flex tablet:justify-between' : ''} ${item.size === 'small' ? 'h-[189px] tablet-max:h-[196px]' : ''} w-full`;
  const imageClassNames = 'w-full h-full tablet-max:mt-6';
  const divImagesClassNames = `overflow-hidden w-full ${item.size === 'large' || item.size === 'vertical' ? 'rounded-b-trimo tablet:mt-6' : 'rounded-r-trimo'} ${
    item.size === 'large' ? 'h-[200px]' : item.size === 'horizontal' ? 'tablet:w-[343px] h-[197px] tablet-max:h-[216px]' : 'h-[216px]'
  }`;
  const sortTitleClassNames = `line-clamp-2 ${
    item.size === 'large' ? 'whitespace-pre-line text-enomous leading-[42px]' : item.size === 'small' ? 'text-semilarge leading-[24px]' : 'text-semihuge leading-[29px]'
  } font-bold  mt-[14px]
  tablet-max:text-semihuge tablet-max:tracking-medium tablet-max:leading-[32px]
  `;
  return (
    <div className={containerClassNames} onClick={onClick}>
      <div className="flex">
        <div className="flex tablet:hover:-translate-y-2 duration-300 w-full">
          <div className={subContainer}>
            <div className="px-6 tablet-max:px-4">
              <SpanNotice type={item.type} content={item.notice} hasIcon={hasIcon} />
              <p dangerouslySetInnerHTML={{ __html: item.sortTitle }} className={sortTitleClassNames}>
              </p>
              <p dangerouslySetInnerHTML={{ __html: item.sortContent }} className="mt-[14px] text-default line-clamp-1 font-light tablet-max:text-default tablet-max:tracking-small">
              </p>
            </div>
            {item.size !== 'small' && (
              <div className={divImagesClassNames}>
                {' '}
                <img className={imageClassNames} src={!isMobile ? item.image : (item.imageMobile ?? item.image)} alt="banner-image" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
Banner.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
};
export default Banner;
