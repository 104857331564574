import {
  createRouter,
  defineRoute,
  param,
} from 'type-route';

import {
  BirthdayTypeParam,
  CarSearchBoxStepParams,
  CityCodeParam,
  CityNameParam,
  DatetimeNoTimezoneParam,
  FreshnessParam,
  LocationCategoryNameParam,
  LocationIdParam,
  LocationNameParam, LocationParamRelOAG,
} from './RouteParamTypes';

const ListingSearchBoxParams = {
  // pickup
  pDatetime: param.query.ofType(DatetimeNoTimezoneParam),
  pCityCode: param.query.ofType(CityCodeParam),
  pCityName: param.query.ofType(CityNameParam),
  pLocationId: param.query.ofType(LocationIdParam),
  pLocationCode: param.query.ofType(LocationParamRelOAG),
  pLocationName: param.query.optional.ofType(LocationNameParam),
  pIsDiff: param.query.boolean,
  pParentCategoryCode: param.query.ofType(LocationCategoryNameParam),
  // return
  rDatetime: param.query.ofType(DatetimeNoTimezoneParam),
  rCityCode: param.query.optional.ofType(CityCodeParam),
  rCityName: param.query.optional.ofType(CityNameParam),
  rLocationId: param.query.optional.ofType(LocationIdParam),
  rLocationCode: param.query.optional.ofType(LocationParamRelOAG),
  rLocationName: param.query.optional.ofType(LocationNameParam),
  rParentCategoryCode: param.query.optional.ofType(LocationCategoryNameParam),

  birthday: param.query.ofType(BirthdayTypeParam),

  pickupCitySearchText: param.state.optional.string,
  returnCitySearchText: param.state.optional.string,
} as const;

export const typeRouter = createRouter({
  home2: defineRoute({
    freshness: param.state.optional.ofType(FreshnessParam),

    steps: param
      .state
      // .query // for debugging
      .optional
      .ofType(CarSearchBoxStepParams),
  }, () => ['/', '/main2', '/home2']),
  __listingSearchBoxParams: defineRoute(
    ListingSearchBoxParams,
    () => '/_____PATH_ONLY_FOR_TYPE_EXTRACTION',
  ),
  listing2: defineRoute({
    freshness: param
      // .query
      .state
      .optional
      .ofType(FreshnessParam),
    ...ListingSearchBoxParams,
    steps: param
      .state
      // .query // for debugging
      .optional
      .ofType(CarSearchBoxStepParams),

  }, () => ['/listing', '/listing2']),

  // checkReservation: defineRoute(
  //   '/reservation_check',
  // ),
  // help: defineRoute('/help'),
  // noticeDetail: defineRoute({
  //   id: param.query.number,
  // },
  // () => '/notice_detail',
  // ),
});

// typeRouter.session.listen(route => {
// })

(window as any).routerSession = typeRouter.session;
