import React from 'react';

import { css, cx } from '@emotion/css';

import { font, SPACING } from '^/constant/CommonStyles2';

import { TimeSelectHourNullable } from './TimeSelect.constants';
import { classNames } from './TimeSelect.styles';

export const HourRenderer: React.FC<{
  value: TimeSelectHourNullable;
  isOpen?: boolean;
  isListItem?: boolean;
  disabled?: boolean;
}> = ({
  value, isOpen, isListItem, disabled,
}) => {
  return (
    <div
      className={[
        isListItem
          ? cx(classNames.btnRendererBasic, font.Body1_Regular)
          : classNames.btnRendererBasic,
        disabled ? css`
          opacity: 0.3;
        ` : '',
      ].join(' ')}
    >
      <span>
        {value}시
      </span>
      {!isListItem && (
        <img
          alt="hour-list-toggle"
          src={isOpen
            ? './ic_arrow_up.png'
            : './ic_arrow_down.png'}
          className={css`
          @media (max-width: 768px) {
            width: ${SPACING.spacing16};
            height: ${SPACING.spacing16};
          }
        `}
        />
      )}
    </div>
  );
};
