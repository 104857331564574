import { z } from 'zod';

import { CityCodeType } from '^/types/__BrandedTypes';

import { defineResponseWithData } from '../DefineResponse';

export const SearchCityAPIResponseItem = z.object({
  code: CityCodeType,
  name: z.string(),
  country_name: z.string().optional(),
  korean_name: z.string(),
});
// eslint-disable-next-line max-len, @typescript-eslint/no-redeclare
export type SearchCityAPIResponseItem = z.infer<typeof SearchCityAPIResponseItem>;

export const SearchCityAPIResponse = defineResponseWithData(
  SearchCityAPIResponseItem
    .array(),
  200,
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SearchCityAPIResponse = z.infer<typeof SearchCityAPIResponse>;
