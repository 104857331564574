import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import PaymentResult from '../../components/Popup/PaymentResult/index';
import { VENDOR_LOGOS } from '../../constant/envConfig';
import { MY_RESERVATION_PATH } from '../../constant/routes';
import { birthdayState } from '^/recoil/atoms';
import {
  pickupLocationState,
  returnLocationState,
} from '^/recoil/atoms/locationState';
import { pickupDatetimeState, returnDatetimeState } from '^/recoil/atoms/datetimeState';
import { authAtom } from '../../recoil/atoms/auth';
import ReservationConfirm from '../Booking/components/DriverDetails/components/ReservationConfirm';
import { calculateHours } from '../../util';
import { GroupCar, NameCar } from '../../components/ModelCar';
import { borders } from '^/constant/CommonStyles';

export const ResultPage = () => {
  const { t } = useTranslation();
  const isAuth = useRecoilValue(authAtom);
  const setPickupDatetime = useSetRecoilState(pickupDatetimeState);
  const setReturnDatetime = useSetRecoilState(returnDatetimeState);
  const setBirthday = useSetRecoilState(birthdayState);
  const setPickupLocation = useSetRecoilState(pickupLocationState);
  const setReturnLocation = useSetRecoilState(returnLocationState);
  const navigate = useNavigate();
  const location = useLocation();

  const goToHomeByRefresh = () => {
    window.location = window.location.origin;
  };
  // const bookingInfo = location && location.state && location.state.info && location.state.info.isSuccess && location.state.info && location.state.info.bookingInfo ? location.state.info.bookingInfo : {};
  const [bookingInfo, setBookingInfo] = useState({});
  // const pkgSelected = location && location.state && location.state.info && location.state.info.isSuccess && location.state.info && location.state.info.pkgSelected ? location.state.info.pkgSelected : '';
  const [pkgSelected, setPkgSelected] = useState('');
  // const packageInfo = (pkgSelected && bookingInfo?.carInfo.insurances.find((item) => item.package === pkgSelected)?.packageInfo) || null;
  const [payType, setPayType] = useState('');
  /*
    NOTE
    모바일 결제로 인해 useLocation으로 넘어오는 데이터 상실
    useEffect를 페이지 로딩 시 가동하여 세션 데이터 가져오기
  */
  const setupData = () => {
    const parsedBooking = JSON.parse(sessionStorage.getItem('_BOOKING_'));
    const isPPD = parsedBooking?.payment?.type === 'PPD';
    const info = isPPD
      ? parsedBooking
      : location?.state?.info;

    setPayType(info.bookingInfo.carInfo.payment.type);

    if (info?.isSuccess) {
      setBookingInfo(info?.bookingInfo);
      setPkgSelected(info?.pkgSelected);
    } else {
      // Mobile
      const session = JSON.parse(sessionStorage.getItem('_BOOKING_'));
      if (session?.isSuccess) {
        setBookingInfo(session?.bookingInfo);
        setPkgSelected(session?.pkgSelected);
      }
    }
  };
  useEffect(() => {
    setupData();
  }, []);

  useEffect(() => {
    setPickupDatetime('');
    setReturnDatetime('');
    setBirthday('');
    setPickupLocation({
      cityCode: '',
      cityName: '',
      parentCategoryCode: '',
      categories: {},
      locationCode: '',
      locationName: '',
    });
    setReturnLocation({
      cityCode: '',
      cityName: '',
      parentCategoryCode: '',
      categories: {},
      locationCode: '',
      locationName: '',
    });
  }, [bookingInfo]);

  const isSuc = location?.state?.info?.isSuccess ?? JSON.parse(sessionStorage.getItem('_BOOKING_')).isSuccess;
  if (!isSuc) {
    return (
      <PaymentResult title={location?.state?.info.title} isSuccess={false} />
    );
  }
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [groupCar, setGroupCar] = useState(null);
  const [nameCar, setNameCar] = useState(null);
  useEffect(() => {
    if (bookingInfo?.carInfo?.vehInfo?.name) {
      if (bookingInfo?.carInfo?.vehInfo?.name.substring(0, 2).trim().length === 2) {
        setGroupCar(bookingInfo?.carInfo?.vehInfo?.name.substring(0, 2));
        setNameCar(bookingInfo?.carInfo?.vehInfo?.name.slice(3, -10).trim());
      } else if (bookingInfo?.carInfo?.vehInfo?.name.substring(0, 2).trim().length === 1) {
        setGroupCar(bookingInfo?.carInfo?.vehInfo?.name.substring(0, 1));
        setNameCar(bookingInfo?.carInfo?.vehInfo?.name.slice(2, -10).trim());
      }
    }
  }, [bookingInfo?.carInfo?.vehInfo?.name]);

  if (!bookingInfo || !pkgSelected) {
    return null;
  }
  return (
    <div
      className={[
        'relative flex flex-col mobile:mx-6 trimo-screen:mx-auto',
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <div className='flex items-center gap-3 mt-[50px] tablet-max:my-5'>
        <h1 className='text-middlegiant font-bold tablet-max:text-semihuge'>예약이 완료되었습니다!</h1>
      </div>
      {
        isMobile ?
          <>
            <ReservationConfirm
              bookingInfo={bookingInfo}
              pkgSelected={pkgSelected}
              isTitle={false}
            />
            <div className='flex gap-[15px] justify-between items-center w-full mt-5'>
              <button onClick={() => goToHomeByRefresh()} className='border border-neutral-100 basis-1/2 tablet:py-4 tablet:px-6 mobile:p-[15px] bg-white rounded-trimo-5 text-primary-gray font-bold hover:text-primary-white hover:bg-primary-skyblue'>{t('backHome')}</button>
              <button onClick={() => navigate(MY_RESERVATION_PATH)} disabled={isAuth ? false : true} className='basis-1/2 tablet:py-4 tablet:px-6 mobile:p-[15px] bg-primary-skyblue tablet:bg-trimo-shadow rounded-trimo-5 text-primary-white font-bold hover:text-primary-skyblue hover:bg-primary-skyblue whitespace-nowrap'>{t('toReservationHistory')}</button>
            </div>
          </> :
          <div className='tablet:mt-[60px] mobile:mt-5 mx-auto flex flex-col items-center gap-6 max-w-content tablet:w-[872px] tablet:text-center'>
            <div className='tablet:bg-primary-white mobile:bg-secondary-gray rounded-trimo shadow-trimodropdown tablet:pl-[47px] tablet:pr-[13px] tablet:py-[40px] mobile:p-4 text-primary-gray w-full'>
              <div className='flex justify-between items-center'>
                <GroupCar
                  modelName={bookingInfo?.carInfo?.vehInfo?.name}
                  className='tablet:text-enomous mobile:text-semilarge font-bold capitalize'
                  size={bookingInfo?.carInfo?.vehInfo?.size}
                  type={bookingInfo?.carInfo?.vehInfo?.type}
                />
                {/* <h2 className='tablet:text-enomous mobile:text-semilarge font-bold capitalize'>[{groupCar} 그룹] - {bookingInfo.carInfo.vehInfo.size} {bookingInfo.carInfo.vehInfo.type !== 'car' ? bookingInfo.carInfo.vehInfo.type : ''}</h2>*/}
                <img alt="car-image" src={VENDOR_LOGOS[bookingInfo?.carInfo?.vehInfo?.company]} className="object-scale-down h-[26px] w-[72px]" />
              </div>
              <div className='flex font-light text-default mt-1.5 mb-3'>
                <NameCar modelName={bookingInfo?.carInfo?.vehInfo?.name}/>
                {/* <div className='lowercase first-line:capitalize'><span>{nameCar} 또는 동급차량</span></div>*/}
              </div>
              <p className="mt-2 tablet:text-semilarge mobile:text-small font-normal text-neutral-300 text-left">
                {
                  bookingInfo?.carInfo?.services?.filter((service) => service?.info && service?.info !== 'undefined')?.map((service) => `${Number(service?.info) ? `${t(service?.shortName).includes('${num}') ? '' : service?.info}` : t(service.info)} ${t(service?.shortName).replace('${num}', service?.info)}`).join(' / ')
                }
              </p>
              <div className='mt-[30px] flex flex-col gap-6 text-left'>
                <div className='flex justify-between items-center tablet:gap-[50px] mobile:gap-12'>
                  <div className='basis-1/5 tablet:text-semihuge mobile:text-big font-bold'>예약 번호</div>
                  <div className='grow tablet:text-big mobile:text-default'>
                    {bookingInfo?.res_id}
                  </div>
                </div>
                <div className='flex justify-between items-center tablet:gap-[50px] mobile:gap-12'>
                  <div className='basis-1/5 tablet:text-semihuge mobile:text-big font-bold'>{t('rentaled')} / {t('return')} {t('location1')}</div>
                  <div className='grow tablet:text-big mobile:text-default'>
                    {t(`location_name.${bookingInfo?.carInfo?.coreInfo?.pickup?.code}`) + ' / ' + t(`location_name.${bookingInfo?.carInfo?.coreInfo?.return?.code}`)}
                  </div>
                </div>
                <div className='flex justify-between items-center tablet:gap-[50px] mobile:gap-12'>
                  <div className='basis-1/5 tablet:text-semihuge mobile:text-big font-bold'>{t('rentaled')} / {t('return')} {t('schedule')}</div>
                  <div className='grow tablet:text-big mobile:text-default'>
                    <span>
                      {format(new Date(bookingInfo?.carInfo?.coreInfo?.pickup?.time), 'y.MM.dd HH:mm')} ~ {format(new Date(bookingInfo?.carInfo?.coreInfo?.return?.time), 'MM.dd HH:mm')}
                    </span>
                    <span> ({calculateHours(
                      new Date(bookingInfo?.carInfo?.coreInfo?.return?.time),
                      new Date(bookingInfo?.carInfo?.coreInfo?.pickup?.time),
                    )} {t('hourUnit')})</span>
                  </div>
                </div>
                <div className='flex justify-between items-center tablet:gap-[50px] mobile:gap-12'>
                  <div className='basis-1/5 tablet:text-semihuge mobile:text-big font-bold'>{t('insuranceDetails')}</div>
                  <div className='grow tablet:text-big mobile:text-default flex flex-row items-center'>
                    <p>
                      {pkgSelected ? t(`insurance_name.${pkgSelected}`) : '기본 할인요금'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between items-center tablet:gap-[50px] mobile:gap-12'>
                  <div className='basis-1/5 tablet:text-semihuge mobile:text-big font-bold'>{t('moreOptions')}</div>
                  <div className='grow text-big'>
                    {
                      bookingInfo?.carInfo.equipsCharge?.length > 0 ?
                        <span>
                          {
                            bookingInfo?.carInfo?.equipsCharge.map((equip) => {
                              return `${t(`equipment_${equip.code}`)} (${equip.quantity})`;
                            }).join(' / ')
                          }
                        </span> :
                        <span>{t('none')}</span>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='flex tablet:gap-6 mobile:gap-[15px] justify-between items-center w-full'>
              <button
                onClick={() => goToHomeByRefresh()} className='basis-1/2 tablet:py-4.5 tablet:px-6 mobile:p-[15px] bg-trimo-shadow rounded-trimo-5 text-primary-gray font-bold hover:text-primary-white hover:bg-primary-skyblue'>{t('backHome')}</button>
              <button onClick={() => navigate(MY_RESERVATION_PATH)} disabled={isAuth ? false : true} className='basis-1/2 tablet:py-4.5 tablet:px-6 mobile:p-[15px] bg-primary-skyblue tablet:bg-primary-skyblue rounded-trimo-5 text-primary-white font-bold hover:text-primary-white hover:bg-primary-skyblue whitespace-nowrap'>{t('toReservationHistory')}</button>
            </div>
          </div>
      }
    </div>
  );
};

export default ResultPage;
