import React, { PropsWithChildren } from 'react';

import { css } from '@emotion/css';
import XIcon from '@heroicons/react/solid/XIcon';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import Button from '^/components/Button';
import { borders, COLORS } from '^/constant/CommonStyles';

import {
  ReservationCheckPageFormViewModel,
} from './ReservationCheck.form.viewmodel';

const ButtonImported = (Button as any);

export const ReservationCheckPageMobileLayout: React.FC<PropsWithChildren<{
  formViewModel: ReservationCheckPageFormViewModel,
}>> = observer(function ReservationCheckPageMobile({
  children,
  formViewModel,
}) {
  const navigate = useNavigate();
  return (
    <form
      id="ReservationCheckPageMobileLayout_root"
      className={css`
        position: fixed;
        top: 0; bottom: 0;
        left: 0; right: 0;
      `}
      onSubmit={(e) => {
        e.preventDefault();
        formViewModel.submit();
      }}
    >
      <div
        id="ReservationCheckPageMobileLayout_header"
        className={css`
          position: fixed;
          top: 0; height: 60px;
          left: 0; right: 0;
          display: flex;
          border-bottom: 1px solid ${COLORS.LIGHT_GREY_0};
          justify-content: space-between;
          align-items: center;
          padding-left: 24px;
          padding-right: 24px;
        `}
      >
        <XIcon
          className={css`
            width: 32px;
            height: 32px;
          `}
          onClick={() => navigate(-1)}
        />
        <h1
          className={css`
            font-size: 18px;
            font-weight: 700;
          `}
        >예약 조회
        </h1>
        <div
          className={css`
            width: 32px;
            height: 32px;
          `}
        />
      </div>
      <div
        id="ReservationCheckPageMobileLayout_body"
        className={[
          css`
            position: fixed;
            top: 60px; bottom: 0;
            left: 0; right: 0;
            padding: 36px 24px 24px 24px;
            overflow-y: scroll;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
          `,
          // borders.DEBUG_purpleBorderDashed,

        ].join(' ')}
      >
        <div>

          <h1
            id="ReservationCheckPageMobileLayout_title"
            className={css`
            font-family: Pretendard;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: -0.03px;
            text-align: left;
            color: #1e1e1e;
            position: absolute;
          `}
          > 예약을 조회하려면 정보가 필요해요
          </h1>
          <div
            className={css`
            margin-top: 36px;
          `}
          >
            {children}
          </div>
        </div>
        <ButtonImported
          id="button"
          type="submit"
          classNames="
            hover:text-white
            rounded-trimo-5
            py-4.5
            tablet:text-semilarge mobile:text-big
            text-gray
            diabled:text-white disabled:secondary-gray-light
            bg-primary-skyblue
            "
          color="skyblue" content="continue"
          disabled={!formViewModel.isValid}
          fullWidth
          onClick={formViewModel.submit}
        >
        </ButtonImported>
      </div>
    </form>
  );
});
