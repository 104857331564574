import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

import { LOCALE_STORAGE_NAME } from './constant/envConfig';

const loadPath = process.env.REACT_APP_TRANSLATION_PATH;
i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['ko', 'en'],
    supportedLngs: ['en', 'ko'],
    lng: localStorage.getItem(LOCALE_STORAGE_NAME) || 'ko',
    backend: {
      backends: [
        LocalStorageBackend,
        HttpBackend,
      ],
      backendOptions: [{
        expirationTime: 10 * 60 * 1000, // 10 minutes
      }, {
        loadPath,
      }],
    },
  });

export default i18n;
