import { makeObservable } from 'mobx';

import { CarListingSearchBoxInputViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.InputViewmodel';
import {
  BaseSearchBoxStepManager,
  SearchBoxSteps,
} from '^/domainComponents/forms/CarListingSearchBox/SearchBoxStepManager';

import { ListingPage2RouteParams } from './ListingPage2RouteParams';

export class ListingSearchBoxStepManagerDesktop
  extends BaseSearchBoxStepManager<ListingPage2RouteParams> {
  constructor(
    public carListingSearchInputVM: CarListingSearchBoxInputViewModel,
    public steps: SearchBoxSteps[] = [],
  ) {
    super();
    makeObservable(this, {
      updatePushCountBy: true,
      steps: true,
      getInputViewModel: true,
      getSteps: true,
      pushStepsToRoute: true,
      replaceStepsInRoute: true,
      updateRouteParams: true,
      guardStep: true,
      pushStep: true,
      clearStep: true,
      replaceStep: true,
      currentStep: true,
      carListingSearchInputVM: true,
    });
  }

  getInputViewModel() {
    return this.carListingSearchInputVM;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  updateRouteParams(routeParams: ListingPage2RouteParams) {
    // do nothing
  }

  getSteps() {
    return this.steps || [];
  }

  pushStepsToRoute(newSteps: SearchBoxSteps[]) {
    this.steps = [...newSteps];
  }

  replaceStepsInRoute(newSteps: SearchBoxSteps[]) {
    this.steps = [...newSteps];
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  updatePushCountBy(n: number): void {
    // do nothing
  }
}
