import {
  formatIncompletePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { makeAutoObservable } from 'mobx';

import {
  countriesPhoneCode,
} from '^/constant/countriesPhoneCode';
import { MobxInputValue } from '^/util/MobxInputValue';

const krNth = countriesPhoneCode.findIndex((a) => a.code === 'KR');
//    ^?

export class PhoneInputViewModel {
  constructor(
    public phoneCountryCode = new MobxInputValue<
      typeof countriesPhoneCode[number]
    >(countriesPhoneCode[krNth]),
    public phoneNumber = new MobxInputValue(''),
  ) {
    makeAutoObservable(this);
  }

  get formatter() {
    const phoneCode = this.phoneCountryCode.value;

    return (a: string) => {
      if (phoneCode.code === 'AQ'
      || phoneCode.code === 'BV'
      || phoneCode.code === 'GS'
      || phoneCode.code === 'HM'
      || phoneCode.code === 'PN'
      || phoneCode.code === 'TF'
      ) {
        return formatIncompletePhoneNumber(a);
      }
      return formatIncompletePhoneNumber(a, phoneCode.code);
    };
  }

  get isValidPhoneRaw() {
    const phoneCode = this.phoneCountryCode.value;
    if (phoneCode.code === 'AQ'
      || phoneCode.code === 'BV'
      || phoneCode.code === 'GS'
      || phoneCode.code === 'HM'
      || phoneCode.code === 'PN'
      || phoneCode.code === 'TF'
    ) {
      return isValidPhoneNumber(this.phoneNumber.value);
    }
    return isValidPhoneNumber(this.phoneNumber.value, phoneCode.code);
  }

  get isValidPhone() {
    if (this.isValidPhoneRaw) return this.isValidPhoneRaw;
    if (!this.phoneNumber.hasBlurred) return null;
    return this.isValidPhoneRaw;
  }
}
