import { makeAutoObservable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { RentalCarOfficeLocationType } from '^/AppContext/RentalOfficeLocationRepo';
import { MobxInputValue } from '^/util/MobxInputValue';
import { MobxValue } from '^/util/MobxValue';
import { hoverDisabledDevice } from '^/util/parsedUserAgent';

import { CalendarDayViewModel } from './Day/Calendar.Day.viewModel';
import {
  addDaysToDay,
  dayToDate,
  DayType,
  dayTypeFromDate,
  DayTypeStr,
} from './Day/DayType';

export type SelectionRange = {
  start: DayType,
  end: DayType | null,
} | {
  start: null,
  end: null,
};
export class CalendarStateViewModel {
  constructor(
    public calendarPaginationOffset: MobxInputValue<number>,
    public today = dayTypeFromDate(new Date()),
    public enabledLastDay = addDaysToDay(today, 365),
    public selection: SelectionRange = {
      start: null,
      end: null,
    },
    public hoverDay = new MobxValue<DayType | null>(null),
    public selectedReturnDayOffsetTop = new MobxValue<number | null>(null),
  ) {
    makeAutoObservable(this, {
      getOfficeViewType: false,
      isInSelectionRange: false,
      isInHoverRange: false,
      getViewType: false,
      getSelectionEndBackgroundDirection: false,
      getSelectionStartBackgroundDirection: false,
    });
  }

  clone() {
    return new CalendarStateViewModel(
      this.calendarPaginationOffset
        .clone(),
      this.today,
      this.enabledLastDay,
      {
        ...this.selection,
      },
      this.hoverDay
        .clone(),
    );
  }

  copyFrom(from: CalendarStateViewModel) {
    this.calendarPaginationOffset.set(
      from.calendarPaginationOffset.value,
    );
    this.today = from.today;
    this.enabledLastDay = from.enabledLastDay;
    this.selection = from.selection;
    this.hoverDay.setValue(from.hoverDay.value);
    this.selectedReturnDayOffsetTop = from.selectedReturnDayOffsetTop;
  }

  get startDate() {
    if (!this.selection.start) return null;
    return dayToDate(this.selection.start);
  }

  get endDate() {
    if (!this.selection.end) return null;
    return dayToDate(this.selection.end);
  }

  pushSelectionDay(day: DayType) {
    if (this.enabledLastDay.str < day.str) return;
    if (this.today.str > day.str) return;

    // TODO...
    if (!this.selection.start && !this.selection.end) {
      this.selection = {
        start: day, end: null,
      };
      this.selection.start = day;
      return;
    }
    if (this.selection.start && this.selection.end) {
      this.selection = {
        start: day, end: null,
      };
      return;
    }

    if (!this.selection.end) {
      // case1: selection.start < day
      if (this.selection.start.str < day.str) {
        this.selection = {
          start: this.selection.start,
          end: day,
        };
      } else {
        this.selection = {
          start: day,
          end: null,
        };
      }
    }
  }

  reset() {
    this.selection = {
      start: null,
      end: null,
    };
    this.selectedReturnDayOffsetTop
      .setValue(null);
  }

  isInSelectionRange = computedFn((
    dayStr: DayTypeStr,
  ) => {
    if (!this.selection.start?.str
      || !this.selection.end?.str) {
      return false;
    }
    return (
      this.selection.start.str <= dayStr
      && dayStr <= this.selection.end.str
    );
  });

  getOfficeViewType = computedFn((
    dayVM: CalendarDayViewModel,
    pickupLocation: RentalCarOfficeLocationType,
    returnLocation: RentalCarOfficeLocationType,
    hasDifferentPickupReturnLocations: MobxInputValue<boolean>,
  ) => {
    const dayAcronym = dayVM.dayNameInWeek;
    const isOffPickupOffice = !pickupLocation?.openCloseTimes[dayAcronym].length;
    const isOffReturnOffice = !returnLocation?.openCloseTimes[dayAcronym].length;

    if (!hasDifferentPickupReturnLocations.value
      && isOffPickupOffice) {
      return 'off';
    }
    if (hasDifferentPickupReturnLocations.value) {
      // off by pickup
      if (!this.selection.start
          && !this.selection.end
          && isOffPickupOffice) {
        return 'off';
      }
      // off by return
      if (this.selection.start
        && !this.selection.end
        && isOffReturnOffice) {
        return 'off';
      }
      // off by pickup
      if (this.selection.start
          && this.selection.end
          && isOffPickupOffice) {
        return 'off';
      }
    }
    return null;
  });

  getViewType = computedFn((
    dayStr: DayTypeStr,
  ) => {
    if (dayStr < this.today.str
      || this.enabledLastDay.str < dayStr) {
      return 'disabled' as const;
    }
    if (dayStr === this.selection.start?.str) {
      return 'start' as const;
    }
    if (dayStr === this.selection.end?.str) {
      return 'end' as const;
    }
    if (this.isInSelectionRange(dayStr)) {
      return 'inSelectionBackground' as const;
    }
    if ((this.hoverDay.value?.str || '') === dayStr
      && !hoverDisabledDevice) {
      return 'hover' as const;
    }
    if (this.isInHoverRange(dayStr)
      && !hoverDisabledDevice) {
      return 'inHoverSelection' as const;
    }

    return 'normal' as const;
  });

  isInHoverRange = computedFn((
    dayStr: DayTypeStr,
  ) => {
    if (!this.selection.start) return false;
    if (this.selection.end) return false;
    if ((this.hoverDay.value?.str || '')
      < this.selection.start.str
    ) {
      return false;
    }

    if ((this.selection.start?.str || '') < dayStr
      && dayStr < (this.hoverDay.value?.str || '')) {
      return true;
    }
    return (this.hoverDay.value?.str || '') < dayStr
      && dayStr < this.selection.start.str;
  });

  getSelectionStartBackgroundDirection = computedFn((
    dayStr: DayTypeStr,
  ) => {
    if (dayStr !== this.selection.start?.str) return null;
    if (this.selection.start?.str && this.selection.end) return null;

    if (
      this.selection.start.str < (this.hoverDay.value?.str || '')
    ) {
      return 'right';
    }
    if (
      this.selection.start.str > (this.hoverDay.value?.str || '')
    ) {
      return 'left';
    }
    return null;
  });

  getSelectionEndBackgroundDirection = computedFn((
    dayStr: DayTypeStr,
  ) => {
    if (dayStr !== this.hoverDay.value?.str) return null;
    if (!this.selection.start?.str) return null;
    if (this.selection.start?.str && this.selection.end) return null;

    if (this.hoverDay.value.str < (this.selection.start?.str || '')) {
      return 'right' as const;
    }
    if (this.hoverDay.value.str > (this.selection.start?.str || '')) {
      return 'left' as const;
    }
    return null;
  });

  onHover(day: DayType) {
    this.hoverDay.setValue(day);
  }
}
