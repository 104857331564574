import { z } from 'zod';

import { DayType, dayTypeFromString } from '^/components/calendar2/Day/DayType';
import {
  TimeSelectHour,
  TimeSelectMinute,
} from '^/domainComponents/inputs/TimeSelect/TimeSelect.constants';

export const CityCodeType = z.string()
  .brand<'CityCode'>();

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CityCodeType = z.infer<typeof CityCodeType>;

export const CityNameType = z.string()
  .brand<'CityName'>();

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CityNameType = z.infer<typeof CityNameType>;

export const LocaleType = z.enum(['ko', 'en']);

export const LocationNameType = z.string()
  .brand<'LocationNameType'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocationNameType = z.infer<typeof LocationNameType>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const LocationCodeTypeRel_OAG = z.string()
  .brand<'LocationCode(rel. OAG)'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/naming-convention
export type LocationCodeTypeRel_OAG = z.infer<typeof LocationCodeTypeRel_OAG>;

export const CountryCodeType = z.string()
  .brand<'CountryCode'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CountryCodeType = z.infer<typeof CountryCodeType>;

export const LocationCodeType = z.string()
  .brand<'LocationCode(rel. OAG)'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocationCodeType = z.infer<typeof LocationCodeType>;

// L AP: AirPort
// L DT: DownTown
// L HT: HoTel
// L AM: Train Station ...?
export const LocationCategoryNameType = z.enum([
  'LAP',
  'LDT',
  'LHT',
  'LAM',
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LocationCategoryNameType = z.infer<typeof LocationCategoryNameType>;

export const RentalCarOfficeId = z.string()
  .brand<'RentalCarOfficeId'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RentalCarOfficeId = z.infer<typeof RentalCarOfficeId>;

export const VendorCompanyIDType = z
  .enum(['ZE']);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VendorCompanyIDType = z.infer<typeof VendorCompanyIDType>;

export const VendorCompanyNameType = z
  .string()
  .brand<'VendorCompanyNameType'>();

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VendorCompanyNameType = z.infer<typeof VendorCompanyNameType>;

export const PriceCodeType = z
  .string()
  .brand<'PriceCodeType'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PriceCodeType = z.infer<typeof PriceCodeType>;

export const FreePermissionType = z.enum([
  'Unlimited',
  'Limited',
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FreePermissionType = z.infer<typeof FreePermissionType>;

// TODO: rename? (may conflict with 'creditcard'/'cash'/etc)
export const PaymentTypes = z.enum([
  'POD',
  'PPD',
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentTypes = z.infer<typeof PaymentTypes>;

export const DatetimeWithoutTimezone = z.string()
  .brand<'DatetimeWithoutTimezone'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DatetimeWithoutTimezone = z.infer<typeof DatetimeWithoutTimezone>;

export function encodeDatetimeWithoutTimezone(
  day: DayType,
  hour: TimeSelectHour,
  min: TimeSelectMinute,
) {
  return `${day.str}T${hour}:${min}:00` as DatetimeWithoutTimezone;
}

export function decodeDatetimeWithoutTimezone(
  d: DatetimeWithoutTimezone,
) {
  const splitByT = d.split('T');
  const dayStr = splitByT[0];
  const timeStr = splitByT[1];
  const timeStrSplit = timeStr.split(':');
  return {
    day: dayTypeFromString(d),
    dayStr,
    hour: timeStrSplit[0] as TimeSelectHour,
    minute: timeStrSplit[1] as TimeSelectMinute,
  };
}
export const BirthdayType = z
  .string()
  .brand<'BirthdayType'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BirthdayType = z.infer<typeof BirthdayType>;

export const ShortBirthdayType = z
  .string()
  .brand<'ShortBirthday'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ShortBirthdayType = z.infer<typeof ShortBirthdayType>;

export function fromShortBirthday(
  s: ShortBirthdayType,
  now = new Date(),
): BirthdayType {
  const nowFullYr = String(now.getFullYear());
  const nowCentury = Number(nowFullYr.slice(0, -2));
  const nowPartialYear = nowFullYr.slice(2, 4);
  const partialYear = s.slice(0, 2);
  const century = partialYear < nowPartialYear
    ? nowCentury : nowCentury - 1;
  const birthYear = `${century}${s.substring(0, 2)}`;
  const birthMonth = s.substring(2, 4);
  const birthDay = s.substring(4, 6);
  return `${birthYear}-${birthMonth}-${birthDay}` as BirthdayType;
}

export function toShortBirthday(
  b: BirthdayType,
) {
  const split = b.split('-');
  return `${split[0].slice(-2)}${split[1]}${split[2]}` as ShortBirthdayType;
}

export const NthDayOfWeek = z.number().brand<'NthDayOfWeek'>();
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NthDayOfWeek = z.infer<typeof NthDayOfWeek>;
