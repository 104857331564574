/* eslint-disable jsx-a11y/alt-text */
// import Lottie from 'react-lottie';

import { ReactComponent as LoadingCar } from '^/assets/loading-animation.svg';
import { borders } from '^/constant/CommonStyles';

// import animationData from '../../lotties/loading-animation.json';

export const Loading = () => {
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData,
  // };
  return (
    <div className={[
      'relative',
      // borders.DEBUG_greenBorderDashed,
    ].join(' ')}
    >
      <div className="fixed inset-0 z-40 flex h-full w-full flex-col items-center justify-center gap-6 bg-black opacity-30"></div>
      <div className="fixed inset-0 z-40 flex h-full w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <div className="mx-auto flex items-center justify-center rounded-full bg-white px-4 mobile:h-[150px] mobile:w-[150px] tablet:h-[198px] tablet:w-[198px]">
            <LoadingCar />
          </div>
          <img src="/trimo_logo_white.png" className="mt-6 h-[32px] w-[96px]" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
