import { makeAutoObservable, runInAction } from 'mobx';

import {
  asSuccessPromise,
  FreshnessType,
  NotInitiatedPromiseStale,
  PromiseResultType,
} from '^/types/__ResultType';
import { isDefined } from '^/types/utils/isDefined';
import { computedFn2 } from '^/util/mobx-utils/mobxComputedFn2';

import { BaseItemsRepository } from './BaseItemRepository';
import { defaultArgsSerialize } from './defaultArgsSerialize';

export type SerializedArgCacheKey = string & {
  __SerializedArgCacheKey: null,
};

export type ListAPIFnType<
  TArgs,
  TItem,
  TFail,
  TLoading = unknown,
  TValue extends { items: TItem[] } = { items: TItem[] },
> = (
  args: TArgs,
) => Promise<PromiseResultType<TValue, TFail, TLoading>>;

export type ExtractAPIFn<TAPIFn> =
  TAPIFn extends ListAPIFnType<
  infer TArgs,
  infer TItem,
  infer TFail,
  infer TLoading
  > ?
    {
      args: TArgs,
      item: TItem,
      fail: TFail,
      loading: TLoading,
    } : never;

export class BaseListCore<
  TArgs extends {},
  TItem,
  TFail,
  TLoading = unknown,
  TAPIFn extends ListAPIFnType<TArgs, TItem, TFail, TLoading>
  = ListAPIFnType<TArgs, TItem, TFail, TLoading>,
  TIDType extends string | number = string,
  TRepo extends BaseItemsRepository<TItem, TIDType>
  = BaseItemsRepository<TItem, TIDType>,
> {
  constructor(
    public apiFn: TAPIFn,
    public itemToId: (item: TItem) => TIDType,
    public repo: TRepo,
    public serializeArgs:(args: TArgs) => SerializedArgCacheKey
    = defaultArgsSerialize,
    public argsToIDs = new Map<
    SerializedArgCacheKey,
    PromiseResultType<TIDType[], TFail, TLoading>
    >(),
  ) {
    makeAutoObservable(this, {
      getList: false,
      fetchAndSetData: false,
    });
  }

  getList = computedFn2((
    args: TArgs,
    freshness: FreshnessType,
    options?: {
      hideStaleValue?: boolean,
    },
  ) => {
    // console.log('getList freshness:', freshness);
    const cacheKey = this.serializeArgs(args);
    this.fetchAndSetData(args, freshness);
    const argsToIDs = this.argsToIDs
      .get(cacheKey) || NotInitiatedPromiseStale;
    if (argsToIDs.status !== 'success') {
      return argsToIDs;
    }
    if (argsToIDs.freshness < freshness && options?.hideStaleValue) {
      // console.log(
      //   'STALE!',
      //   'argsToIDs.freshness',
      //   argsToIDs.freshness,
      //   'freshness',
      //   freshness,
      // );
      return NotInitiatedPromiseStale;
    }
    // console.log(
    //   'SUCCESS!',
    //   'argsToIDs.freshness',
    //   argsToIDs.freshness,
    //   'freshness',
    //   freshness,
    // );
    return asSuccessPromise(
      argsToIDs.value
        .map(this.repo.getById)
        .filter(isDefined),
      freshness,
    );
  });

  fetchAndSetData = computedFn2(async (
    args: TArgs,
    freshness: FreshnessType,
  ) => {
    const cacheKey = this.serializeArgs(args);
    const result = await this.apiFn({
      ...args,
      freshness,
    });
    if (result.status !== 'success') {
      this.argsToIDs.set(cacheKey, result);
      return;
    }
    const oldValue = this.argsToIDs.get(cacheKey);
    if (oldValue?.status === 'success'
      && oldValue.freshness > result.freshness
    ) {
      // discard old-requested-but-new-responses
      return;
    }
    runInAction(() => {
      result.value.items.forEach((item) => {
        const id = this.itemToId(item);
        this.repo.setById(id, item);
      });
      this.argsToIDs.set(
        cacheKey,
        asSuccessPromise(
          result.value.items.map(this.itemToId),
          freshness,
        ),
      );
    });
  });
}
