import axios from '../../axios/index';
import { BASE_PATH } from './SEARCH_BASE_PATH';

export const searchCity = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await axios.fetch({
    url: `${BASE_PATH}?${queryString}`,
    method: 'GET',
  });
  return response;
};

export const searchVehAvail = async (params) => {
  const response = await axios.fetch({
    url: `${BASE_PATH}/cars`,
    method: 'POST',
    data: params,
  });
  return response;
};

export const getNewVehAvail = async (key) => {
  const response = await axios.fetch({
    url: `${BASE_PATH}/cars/loading?key=${key}`,
    method: 'GET',
  });
  return response;
};

export const isExistKey = async (key) => {
  const response = await axios.fetch({
    url: `${BASE_PATH}/cars/exists?key=${key}`,
    method: 'GET',
  });
  return response.data;
};

export const searchCarsByLocationGroup = async (params) => {
  axios.fetch({
    url: `${BASE_PATH}/cars/location`,
    method: 'POST',
    data: params,
  });
};

export const searchDefinedCar = async (params) => {
  const response = await axios.fetch({
    url: `${BASE_PATH}/cars/defined`,
    method: 'POST',
    data: params,
  });
  return response;
};

export const checkEquips = async (params) => {
  const response = await axios.fetch({
    url: `${BASE_PATH}/cars/equips`,
    method: 'POST',
    data: params,
  });
  return response;
};

export const testConnecttion = async () => {
  const response = await axios.fetch({
    url: '/redis',
    method: 'GET',
  });
  return response;
};
