import itiriri from 'itiriri';
import { makeAutoObservable, runInAction } from 'mobx';

import { GetRentalCarOfficeLocationsByCityAPI } from '^/api2/getRentalCarOfficeLocationsByCity/byCity';
import {
  CityCodeType, LocationCategoryNameType, RentalCarOfficeId,
} from '^/types/__BrandedTypes';
import {
  asSuccessPromise,
  ExtractFailures, FreshnessType, NotInitiatedPromiseStale, PromiseResultType,
} from '^/types/__ResultType';
import { ExtractPromise } from '^/types/utils/ExtractPromise';
import { isDefined } from '^/types/utils/isDefined';
import { computedFn2 } from '^/util/mobx-utils/mobxComputedFn2';

import { RentalCarOfficeLocationRepo } from './RentalOfficeLocationRepo';

type APIResponse = ExtractPromise<ReturnType<GetRentalCarOfficeLocationsByCityAPI>>;
type FailResponses = ExtractFailures<APIResponse>;

/* eslint-disable @typescript-eslint/indent */
type IDsResult = PromiseResultType<
  Map<LocationCategoryNameType, RentalCarOfficeId[]>,
  FailResponses
>;
/* eslint-enable @typescript-eslint/indent */

export class RentalOfficeLocationsByCityCodeCore {
  constructor(
    public rentalOfficeLocationsByCityAPI: GetRentalCarOfficeLocationsByCityAPI,
    public rentalOfficeLocationsRepo: RentalCarOfficeLocationRepo,
    public locationIDLists = new Map<CityCodeType, IDsResult>(),
  ) {
    makeAutoObservable(this, {
      getRentalOfficeLocations: false,
      fetchRentalOfficeLoations: false,
    });
  }

  getRentalOfficeLocations = computedFn2((
    cityCode: CityCodeType,
    freshness: FreshnessType,
  ) => {
    // TODO...
    this.fetchRentalOfficeLoations(cityCode, freshness);
    const result = this.locationIDLists.get(cityCode);
    if (!result) {
      return NotInitiatedPromiseStale;
    }
    if (result.status !== 'success') {
      return result;
    }
    const rentalCarOfficesByCategory = itiriri(result.value)
      .map(([
        locationCategory,
        ids,
      ]) => {
        const rentalCarOffices = ids
          .map((a) => this.rentalOfficeLocationsRepo.getById(a))
          .filter(isDefined);
        return [locationCategory, rentalCarOffices] as const;
      })
      .toMap(
        (a) => a[0],
        (a) => a[1],
      );
    return asSuccessPromise(
      rentalCarOfficesByCategory,
      freshness,
    );
  });

  fetchRentalOfficeLoations = computedFn2(async (
    cityCode: CityCodeType,
    freshness: FreshnessType,
  ) => {
    const apiResult = await this.rentalOfficeLocationsByCityAPI(
      cityCode,
    );
    if (apiResult.status !== 'success') {
      this.locationIDLists.set(cityCode, apiResult);
      return;
    }
    runInAction(() => {
      apiResult.value.rentalOffices
        .forEach((l) => {
          l.location.forEach((a) => {
            this.rentalOfficeLocationsRepo
              .setById(a.location_id, a);
          });
        });
      const locationCategoriesToRentalOfficeLocations = itiriri(
        apiResult
          .value
          .rentalOffices,
      ).map((q) => {
        return {
          id: q.id,
          locationIDs: q.location.map((a) => a.location_id),
        };
      }).toMap(
        (val) => val.id,
        (a) => a.locationIDs);
      this.locationIDLists.set(
        cityCode,
        asSuccessPromise(
          locationCategoriesToRentalOfficeLocations,
          freshness,
        ),
      );
    });
  });
}
