export const fakeBanner = [
  {
    id: 1,
    type: 'clock',
    notice: '이용꿀팁',
    title: '미국 렌트카 보험, \n어렵지 않아요!',
    name: '메인텍스트 입니다 메인텍스트 입니다 메인텍스트 입니다 길어질 경우 …',
    content: `<style>
    body {font-size: 16px}
    b    {font-size: 18px;}
    </style><span>국내든 해외든 렌트카를 예약할 때 가장 중요하게 알아봐야 할 내용은 바로 보험이에요. 혹시 모를 사고 발생 시 보험의 유무, 보험 커버리지 정도에 따라 빠른 대처가 가능하기 때문이죠. 게다가 익숙한 한국도 아니고 낯선 해외에서 사고라도 난다면 어떻게 처리해야 할지 막막하게 느껴지실 거예요. 그렇기에 우리는 보험을 잘 살펴보고 예약해야 한답니다.
  
    해외 렌트카의 경우 보험 용어가 다소 생소하고 종류도 많아 더 어렵게 느껴지실 수 있지만 트리모가 쉽게 알려드릴게요. 나라마다, 렌트카 브랜드마다 보험 용어나 조건이 다르지만 우선 미국 기준, 허츠(Hertz) 렌트카를 기준으로 설명해 드릴게요!
  
    미국에서 렌트카를 예약할 때 필수로 볼 수 있는 보험은 아래 세 가지예요.<br>
    <b>(1)LDW(Loss Damage Waiver) : 차량 손실 면책 프로그램</b>
    쉽게 자차보험이라고 생각해 주시면 되어요. 차량의 파손 및 도난에 대한 책임이 면제되는 보험인데요. 주차하다가 차를 긁는 등 차량이 파손된 경우 비용을 지불하지 않아도 되는 보험이에요. 또, 가끔 미국에서 렌트카를 사용한 후기를 보면 차량을 그대로 도난당하는 경우도 볼 수 있는데요. 이런 때에도 부담해야 할 비용은 전혀 없는 보험이랍니다.<br>
  <b>(2)LI/LIS(Liability / PLiability Insurance Supplement) : 대인, 대물 추가 책임보험</b>
    혼자 주차하다가 벽에 박아서 사고가 난 경우가 아닌, 차량 대 차량으로 다른 차량과 부딪히는 사고가 났을 때를 위해 필요한 보험이에요. 상대방의 차량 혹은 신체, 기물 등을 보장해 주는 보험인데요. 국가에 따라 보상한도가 다르지만 미국의 경우 100만 달러 안에서 보상이 가능하답니다. 만약 차량 대 차량 사고가 났을 경우 현지 경찰을 불러 Police Report를 받아야만 보험 적용과 보상을 받을 수 있다는 점, 꼭 기억해 주세요.<br>
    <b>(3)PAI/PEC(Personal Accident Insurance / Personal Effects Coverage) : 임차인 상해 및 휴대품 분실보험</b>
    PAI는 차량 관련 사고로 인해 임차인(탑승자) 및 동승자가 상해를 입었을 때 필요한 보험이고 PEC는 차량 안에서 개인 물품을 도난당했을 때 보장해 주는 보험이에요. 차량 안에 있는 물품을 도난 당했을 경우를 대비해 해당 보험이 있으면 좋겠지요? 이 보험 역시 도난당한 물품이 명시되어 있는 현지 경찰의 Police Report가 필요해요. 또, 도난당한 물품의 구매 영수증이 있어야 보상을 받을 수 있답니다.<br>
    위 세 가지 보험 외에도 현장에서 추가 옵션을 구매할 수 있어요.<br>
    <b>(1)PERS(Premium Emergency Roadside Service) : 긴급출동 서비스</b>
    운전자 부주의로 인한 차 키 분실 혹은 차량 열쇠를 차 안에 두고 잠근 경우, 연료 소진, 타이어 펑크 등의 사고가 일어났을 때 보험사가 긴급 출동해 주는 서비스에요.<br>
    <b>(2)ESP(Emergency Sickness Protection) : 응급질환 보장</b>
    차량 임차 기간 중 미국 내에서 질병이 발생했을 경우 보장해 주는 서비스에요. 사고로 인한 상해나 성형수술, 치과 치료, 선천적 질병 등 보상에서 제외되는 항목도 많아요. 또 미국 시민인 경우에는 구매할 수 없는 옵션이랍니다.<br>
  모든 상황에 대해 보험이 적용되면 좋겠지만, 보험이 적용되지 않는 경우 또한 존재해요. 어떤 상황에서 보험 보장이 어려울까요?
  
  Police Report가 없는 차량 사고/파손 및 휴대품 분실건에 대해선 보험이 적용되지 않는답니다. 그렇기에 아무리 작은 사고일지라도 사고가 난 직후 경찰과 Hertz Emergency Service에 신고해 주셔야 해요.<br>
  차량 열쇠를 분실하거나 차량 내 인테리어 및 내비게이션 등이 파손되었을 때에도 보험이 적용되지 않아요. 또한 차량의 유종을 구분하지 못하고 주유하여 엔진이 오염되는 등 개인 부주의로 인한 사고에 대해서도 보험이 적용되지 않아요.<br><br><br>
  미국 내 허츠(Hertz) 브랜드 렌트카 기준으로 보험을 설명해 드렸는데요. 각 국가별, 브랜드별로 보험의 종류, 이름, 내용이 조금씩 다르기에 렌트카 예약 전에 꼭 확인해 주세요. 앞으로 트리모도 더 다양한 국가와 브랜드의 보험을 쉽게 소개해 드리도록 할게요!
  </span>`,
    image: './banner_insurance_small.webp',
    size: 'vertical',
    order: 1,
    bannerStyle: '',
    sortTitle: '미국 렌트카 보험,\n어렵지 않아요!',
    sortContent:
      '#해외렌트 #보험정보는 필수',
    bannerFull: './banner_insurance_full.webp',
    hasToNoticesPage: true,
  },
  {
    id: 2,
    type: 'clock',
    notice: '이용꿀팁',
    title: '  해외에서 렌트카\n운행할 때 주의할 점',
    name: '메인텍스트 입니다 메인텍스트 입니다 메인텍스트 입니다 길어질 경우 …',
    content: `<style>
    body {font-size: 16px}
    b    {font-size: 18px;}
    </style><span>해외에서 운전을 할 땐, 한국에서 할 때보다 훨씬 더 긴장하게 되시죠? 혹시나 사고가 나면 어쩌나, 교통 법규를 위반하는 건 아닐까 등 신경이 곤두선 채로 운전을 하게 되실 텐데요. 사실 운전대를 잡고 나면 한국과 크게 다를 것 없다고 느껴진답니다. 해외에서 렌트카 운전할 때 주의할 점을 미리 살펴보고 가시면 더 편안한 마음으로 운전하실 수 있을거예요.<br>
    <b >[교통법규 위반 및 범칙금]</b>
    교통법규를 위반하면 한국에서와 마찬가지로 범칙금은 임차인이 지불해야 해요. 그러니 해외에서 운전하기 전에는 각 나라별, 지역별로 교통법규를 잘 알아보아야 해요.<br>
  대표적인 교통법규 위반에는 어떤 것들이 있을까요?<br>
    <b >(1)유료도로 비용 미납</b>
    미국에서 가장 많이 위반되는 사례에요. 미국의 대부분 도로는 무인카메라 차선이고, 카메라가 통행료를 미납하는 차량의 번호판을 인식하여 위반으로 간주하게 되어요.<br>
    <b >(2)주정차 금지구역 위반</b>
    해외의 경우 주정차 금지구역은 무인 카메라를 통해 24시간 단속되어요. 짐을 내리거나 사람이 내리기 위해 아주 잠깐의 정차만 하더라도 엄격하게 단속이 이루어지니 절대로 정차하지 않는 것이 좋아요. 주차 역시 허용된 구역에서만 해야 한답니다.<br>
    <b >(3)속도 위반</b>
    한국에서와 마찬가지로 해외 역시 도로들마다 제한된 속도가 다른데요. 이 제한속도를 잘 살펴보아야 하고 이를 지켜 운전해야 해요. 한국과 마찬가지로 10km 이내의 과속은 허용되지 않고 무인 카메라로 상시 단속되고 있답니다.<br>
    <b >(4)버스 전용 차로 및 갓길 운전</b>
    현재 운행 중인 차로가 버스 전용 차로는 아닌지 잘 살펴보아야 해요. 한국에서도 버스 전용 차로로 달리면 위반인 것처럼 해외 역시 탑승 인원에 따라 주행할 수 있는 차선이 다르답니다.<br>
  만약 교통법규를 위반했다면 범칙금은 어떻게 납부해야 할까요?<br>
  해외 역시 대부분 무인 카메라로 교통법규를 단속하고 위반 시에는 렌트카 회사로 임차인 신원 조회와 차적 조회 요청이 들어오게 되어요. 차량 반납 후 1년이 지난 시점에도 요청이 들어올 수 있답니다. 차적 조회 시에는 행정수수료가 청구되는데 이 역시 임차인이 부담해야 해요. (유럽의 경우 평균 30유로)<br>
  교통법규 위반 고지서는 임차인의 주소지로 우편을 통해 받으실 수 있고, 고지서에 기재된 대로 해당하는 기관에 직접 납부해 주시면 되어요. 미국에서 Hertz 렌트카를 통해 차량을 운행하셨다면 교통법규 위반 관할 기관 American Traffic Solution에서 임차 시 등록한 카드로 자동 청구된답니다. 또, 미국은 Rentalcarticket(www.rentalcarticket.com)에서 범칙금 상세 내용과 영수증을 확인할 수 있으니 참고해 주세요.<br>
    <b >[차량 사고 및 고장/파손]</b>
    극히 드문 일이지만, 렌트카를 운행하다가 사고가 나거나 고장 및 파손의 긴급 상황이 발생하는 경우가 있을 수 있어요. 큰 사고가 아니라면 우선 차량을 렌트한 회사로 전화해 주세요. 트리모로 연락을 하셔도 ‘차량을 렌트한 회사로 연락하셔야 한다’고 안내를 드릴 수밖에 없기에 바로 렌트사로 연락하셔야 빠르게 처리할 수 있답니다. 그다음, 경찰에 신고해 주셔야 해요. 경찰 신고 후 Police Report를 받아 렌트사에 제출해 주시면 보험에 따라 보상이 진행될 거예요.<br>
  만약 경찰에 신고되지 않은 사고 및 고장/파손은 보험처리가 진행되지 않으니 아무리 작은 사고라고 할지라도 꼭 신고해 주셔야 해요!<br>
    <b >[해외 도로주행 TIP]</b><br>
    <b >(1)주유</b>
    한국에도 무인 주유소가 점점 많아지고 있는데요. 해외 역시 대부분이 무인 주유소에요. 주유기에서 직접 신용카드로 결제하거나 사무실 내에 있는 직원에게 주유기 번호를 말한 후 현금 또는 신용카드로 비용을 지불할 수 있어요. 이때, 혼유하지 않도록 꼭 주의하셔야 해요. 특히 프랑스에서는 디젤이 Gazole이라고 표기되어 있어 헷갈릴 수 있으니 잘 확인해 보고 주유해 주세요.<br>
    <b >(2)STOP 표지판</b>
    신호등이 없는 교차로를 좌우로 잘 살펴보시면 대부분 STOP 표지판이 있어요. 이 표지판을 보게 되면 무조건 정지해 주세요. 우회전을 하건 좌회전을 하건 나의 주행 방향과는 상관없이 STOP 표지판이 있는 곳에서는 무조건 ‘3초간' 정지한 후 출발해야 해요.<br>
  교통 표지판 외에도 스쿨버스에서 아이들이 내릴 경우 스쿨버스에 있는 STOP 팻말이 펼쳐지는데요. 이때는 같은 방향 차선뿐 아니라 반대편 차선의 차량도 모두 정지해야 해요. 이후 STOP 팻말이 사라진 후에 다시 주행할 수 있답니다. 이를 위반 시 굉장히 높은 범칙금이 부과되니 꼭 주의해 주세요.<br><br>
  여행지에서 설레는 마음으로 렌트카를 예약했는데 교통법규 위반으로 범칙금이 발생하면 굉장히 속상하실 거예요. 예상치 못한 사고가 발생하면 머리가 하얘질 수밖에 없고요. 그러니 여행지로 떠나기 전, 렌트카 운행 시 주의할 점을 잘 살펴봐주세요.<br>
  </span>`,
    image: './banner_wrongWay_small.webp',
    size: 'vertical',
    order: 2,
    bannerStyle: '',
    sortTitle: '해외에서 렌트카<br>운행할 때 주의할 점',
    sortContent:
      '#해외 교통법규 #해외 도로주행 팁',
    bannerFull: './banner_wrongWay_full.webp',
    hasToNoticesPage: true,
  },
  {
    id: 3,
    type: 'clock',
    notice: '이용꿀팁',
    title: '렌트카 차종은 어떻게 \n선택하는 게 좋을까요?',
    name: '메인텍스트 입니다 메인텍스트 입니다 메인텍스트 입니다 길어질 경우 …',
    content: `<span>해외여행을 위해 렌트카를 빌리려고 마음먹고 나면, 어떤 차량을 예약할지 고민이 되실 거예요. 대부분 렌트카 예약은 구체적인 차종을 선택하는 것이 아니라 ‘차량 등급’을 예약하게 되는데요. 등급별로 대표 차종이 제시되긴 하지만 대표 차종 이외의 차종을 인수하게 될 가능성도 높고 차량에 따라 세부 옵션 및 연류의 종류도 달라지게 되어요. 
  
  등급에 따라 어떤 차들이 있는지, 어떤 기준으로 등급을 선택하는 게 좋을지 알려드릴게요.😉<br>
    <b>[차량 등급 및 대표 차종]
  (1)Economy</b>
    국내 차량으로 비교하자면 프라이드와 유사한 차종이 있는 등급이에요. 1,400cc 배기량이 대부분이에요. 경차까지는 아니지만 아반떼보다 작은 차량의 등급이라고 생각하시면 되어요.<br>
    <b>(2)Compact</b>
    국내 차량으로 비교하자면 아반떼와 유사한 차종이 있는 등급이에요. 1,600cc 배기량이 대부분이에요.<br>
    <b >(3)Midsize, Intermedia</b>
    국내 소나타와 유사한 차종의 등급이에요. 2,000cc 내외의 배기량을 가진 차량이 이 등급에 속해요.<br>
    <b>(4)Full Size</b>
    국내 그랜저와 유사한 차종이 속한 등급이에요. 배기량은 2,400cc 내외의 차량들이에요.<br>
    <b>(5)7인승 미니밴</b>
    국내 카니발과 유사하거나 조금 더 작은 차종이라고 생각하시면 되어요. 맨 뒷좌석을 접어 트렁크 용량을 더 많이 확보할 수 있는 차량들이에요.<br>
    <b>(6)Premium</b>
    벤츠, BMW 등의 고급 차량의 등급이에요. 아반떼보다 살짝 크고, 소나타보다 살짝 작은 차량들이 속해있어요. BMW 3시리즈, 벤츠 C클래스 등의 차량들이 있어요.<br>
    <b>(7)Luxury</b>
    벤츠, BMW 등의 고급 차량이며 소나타보다 살짝 크고 그랜저보다 살짝 작은 차량의 등급이에요. BMW 5시리즈, 벤츠 E클래스를 생각하시면 되어요.<br>
    <b>[인원에 따른 추천 차량 등급]</b>
    렌트카로 해외여행을 떠나는 경우 짐을 넣을 수 있는 공간까지 생각하여 차량을 예약해야 해요. 인원만 생각하고 차량을 예약했다가 짐 넣을 공간이 부족한 경우가 생길 수 있기 때문이에요.<br>
    <b>(1)1~2명이 여행할 때</b>
    짐이 많은 편이 아니라면 Economy 등급으로도 충분해요. 만약 캐리어가 많고 짐이 많다면 Compact 등급으로 차량을 예약하는 것이 편하게 다닐 수 있는 방법이에요.
    <b>(2)3명이 여행할 때</b>
    최소 Compact 등급 이상의 차량을 예약하는 것을 추천드려요. 2열에도 사람이 앉게 되고 짐까지 생각해야 하기 때문에 Economy 등급은 불편할 수 있어요.<br>
    <b>(3)4명이 여행할 때</b>
    5인승의 대형 또는 7인승의 미니밴을 추천드려요. 미국의 경우 장시간 운전하게 될 일이 많은데, 2열에 두 명이 앉고 짐까지 생각하면 Compact 등급은 좁게 느껴지실 거예요.<br>
    <b>(4)5명이 여행할 때</b>
    5명이라고 5인승의 차량을 예약하게 되면, 2열에 앉은 분들이 굉장히 불편하실 거예요. 7인승의 미니밴을 추천드려요.<br>
   이동 과정에서 에너지를 많이 소비하면 여행 일정을 즐기기 어렵겠죠? 인원과 짐의 양을 생각하여 조금 여유로운 크기의 렌트카를 예약하시고 여행을 100% 즐겨보는 건 어떨까요?</span><style>
  body {font-size: 16px}
  b    {font-size: 18px;}
  </style>`,
    image: './banner_cars_small.webp',
    size: 'vertical',
    order: 3,
    bannerStyle: '',
    sortTitle: '렌트카 차종은 어떻게<br>선택하는 게 좋을까요?',
    sortContent:
      '#차종을 #고민하는 #당신에게',
    bannerFull: './banner_cars_full.webp',
    hasToNoticesPage: true,
  },
  {
    id: 4,
    type: 'clock',
    notice: '이용꿀팁',
    title: '해외에서 렌트카 인수하기 전, \n꼭 읽어주세요!',
    name: '메인텍스트 입니다 메인텍스트 입니다 메인텍스트 입니다 길어질 경우 …',
    content: `<span>트리모를 통해 해외 렌트카를 예약하신 다음, 어떤 절차를 통해 차량을 인수받고 인수받을 때는 어떤 준비물들이 필요한 지 궁금하시죠? 국내가 아닌 해외에서 빌리는 렌트카다 보니 더 꼼꼼히 챙기고 싶으실 텐데요. 해외에서 렌트카를 픽업하고 반납하는 과정에 필요한 모든 것들을 알려드릴게요!<br>
    <b>[렌트카 픽업 전 꼭! 챙겨야 하는 준비물]</b>
    잊지 말고 챙겨야 할 준비물은 총 네 가지인데요. 아래 중 하나라도 빠지면 렌트카 픽업이 어려울 수 있으니 꼭 챙겨주셔야 해요.<br>
    <b>(1)여권</b>
    국내에서 주민등록증을 통해 나의 신원을 확인할 수 있는 것처럼, 해외에서는 여권을 통해 확인해요. 그렇기에 해외에서는 항상 여권이 필수랍니다. 렌트카 대여 시에도 여권은 필수에요!<br>
    <b>(2)국내 면허증</b>
    면허 취득 후 1년이 경과한 국내 면허증을 준비해 주세요. 취득일자는 면허 번호를 통해 확인할 수 있는데요. 면허증을 갱신한 경우에도 면허 번호가 바뀌지 않았다면 무방해요. 만약 면허 취소 후 새로 취득한 경우라면 새로 취득한 날로부터 1년이 지나야 해요. (미국은 면허 취득 후 1년이 경과해야 한다는 규정이 없어 1년 미만의 신규 취득자도 렌트가 가능하답니다.)<br>
    <b>(3)국제 면허증</b>
    국제 면허증은 따로 취득하는 것이 아니라 국내 면허증을 각국어로 번역한 사본을 말해요. 국내 면허가 있는 경우 전국의 모든 경찰서 혹은 면허 시험장에서 국제 면허증을 발급받을 수 있으며 대리 발급도 가능하답니다.<br>
    <b>(4)신용카드</b>
    차량 대여 요금을 지불하기 위해 신용카드가 필요한 것이 아니라 차량에 대한 보증을 위해 신용카드가 필요해요. 차량 픽업 시 신용카드를 통해 Deposit(보증금)을 예치하게 되고 이 보증금은 차량 반납 전까지 실제 청구되는 금액이 아니에요. 해당 보증금은 차량 반납 후 2~3주 뒤에 자동 환원 처리되어요. 신용카드의 한도도 잘 살펴보아야 해요. 최소 200달러 이상의 보증금이 필요하며, 한도가 부족해 보증금 지불이 이루어지지 않으면 차량 픽업이 어렵답니다.<br>
  이렇게 네 가지 준비물을 필수로 챙겨주셔야 문제없이 차량을 픽업할 수 있는데요. 여권, 국내 면허증, 국제 면허증, 신용카드 모두 주 운전자 이름과 동일한 영문명의 이름으로 되어 있어야 해요. 특히 신용카드는 주 운전자 본인의 카드만 인정하므로 잘 챙겨주세요!<br>
    <b>[렌트카 픽업하기]</b>
    보통 여행을 가시면 공항에서 렌트카 픽업을 하게 되는 경우가 많으실 거예요. 공항에서 렌트카를 픽업하실 경우에는 ‘Car rental’이라고 적힌 표지판을 찾아주세요. 해당 표지판을 따라가시면 렌트카 픽업 영업소들을 만날 수 있어요. 미국 공항에서 픽업하실 경우 제주도처럼 공항에서 셔틀을 타고 렌트카 영업소들이 모여있는 곳으로 이동하셔야 해요. 미국은 렌트카 영업소들이 공항과 조금 떨어진 곳에 있어 셔틀을 타고 가야 한답니다. 미국 외 대부분의 국가는 공항 안에 렌트카 영업소가 있어요.<br>
    <b>[렌트카 반납하기]</b>
    픽업과 마찬가지로 공항에서 차량을 반납할 경우 공항 내에 있는 ‘Rental Car Return’ 표지판을 찾아주세요. 해당 표지판을 따라가시면 차량 반납 주차장에 도착하시고 직원의 안내에 따라 반납을 진행해 주시면 된답니다.<br> 
  렌트카 반납 전에는 주행거리, 연료의 잔량, 차량 상태 등을 점검해 주세요. 연료는 차량 픽업 시와 같은 상태로 반납이 되어야 하는데요. 만약 연료가 모자란 상태로 반납하게 되면 그만큼의 주유비와 서비스 비용까지 함께 청구되어 생각보다 높은 요금을 지불해야 할 수도 있어요.<br>
  무인 반납은 무인 반납이 가능한 영업소에서 미리 협의가 된 경우에 가능하며 차량 픽업 시 받았던 영수증 봉투에 ‘반납 날짜, 반납 시간, 주행 거리, 연료량’을 기재하여 차키와 키박스에 넣어주시면 되어요. 키박스는 렌트카 영업소 출입문 주변에서 찾으실 수 있어요.<br>
    <b>[계약서 임차 용어]</b>
    한글로 적혀있는 계약서를 이해하는 것도 쉽지 않은데, 영어로 적혀있는 차량 임차 계약서를 받아보시면 더 어렵게 느껴지실 수 있어요. 계약서를 살펴보기 전, 알고 가면 좋을 임차 용어들을 알려드릴게요.🔍<br>
    <b>(1)Rental Agreement :임차 계약서</b><br>
    <b>(2)RA(Rental Agreement) Number, RR(Rental Record) Number : 임차 계약번호</b>
    현장에서 대여 기간을 연장해야 하거나 차량 반납 후 한국에 귀국하여 임차했던 차량에 문의가 있는 경우 해당 계약번호가 필요해요.<br>
    <b>(3)Pre-authorization Amount, Deposit Amount : 보증금</b>
    주 운전자 본인 명의의 신용카드로 예치되는 보증금이에요. 실제 지불되는 금액이 아니라 반납 후 3~4주 이후의 자동 환원 처리되어요.<br>
  해외에서 빌리는 렌트카도 국내에서 빌리는 것과 크게 다르지 않답니다. 하지만 낯선 곳에서 낯선 환경을 접하다 보니 놓칠 수 있는 부분이 생길 수 있기에 여행 전에 자세히 살펴봐주세요!<br>
  </span>`,
    // image: './banner_img_example.png',
    size: 'small',
    order: 4,
    bannerStyle: '',
    sortTitle: '해외에서 렌트카 인수하기 전,\n꼭 읽어주세요!',
    sortContent:
      '#해외렌트 #필수준비물',
    hasToNoticesPage: true,
  },
];
