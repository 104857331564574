import { css, cx } from '@emotion/css';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { CheckboxesWithAllToggleForFilter } from '^/commonComponents/inputs/CheckboxesWithAllToggleForFilter';
import { RadioGroupInput } from '^/commonComponents/inputs/RadioGroup';
import { MultiRangeSlider } from '^/components/MultiRangeSlider';
import { borders } from '^/constant/CommonStyles';
import { COLORS2, font, SPACING } from '^/constant/CommonStyles2';
import { BaseSearchBoxStepManager } from '^/domainComponents/forms/CarListingSearchBox/SearchBoxStepManager';
import { typeRouter } from '^/typeRoute/typeRoutes';
import {
  FreePermissionType,
  VendorCompanyNameType,
} from '^/types/__BrandedTypes';

import {
  ListingPage2FilterViewModel,
  PriceSortFilter,
} from './ListingPage2.FilterViewModel';
import { ListingPage2LoadedViewModel } from './ListingPage2.LoadedViewModel';

export const defaultMultiRangeSliderStyles = {

} as const;

export const ListingPage2MobileFilter: React.FC<{
  loadedViewModel: ListingPage2LoadedViewModel,
  filterViewModel: ListingPage2FilterViewModel,
  stepManager: BaseSearchBoxStepManager,
}> = observer(function ListingPage2MobileFilter({
  loadedViewModel,
  filterViewModel,
  stepManager,
}) {
  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `,
        // borders.DEBUG_redBorderDashed,
      )}
    >
      <section className={css`
        box-sizing: border-box;
        padding-bottom: ${SPACING.spacing16};
        border-bottom: 1px solid ${COLORS2.sub.MrtGray2};
      `}
      >
        <h1 className={font.Headline1_Bold}>정렬</h1>
        <RadioGroupInput
          carsScrollAreaRef={null}
            // eslint-disable-next-line no-underscore-dangle
          choices={PriceSortFilter._def.values}
          inputValue={filterViewModel.priceSortFilter}
        />
      </section>

      <section className={css`
        margin-top: ${SPACING.spacing16};
        padding-bottom: ${SPACING.spacing16};
        border-bottom: 1px solid ${COLORS2.sub.MrtGray2};
      `}
      >
        <h1 className={font.Headline1_Bold}>금액</h1>
        <div
          className={cx(
            css`
              display: flex;
              width: 100%;
              justify-content: space-between;
              margin-top: ${SPACING.spacing16};
            `,
            // borders.DEBUG_greenBorderDashed,
          )}
        >
          <p>최소 금액</p>
          <p>최대 금액</p>
        </div>
        <div className={css`
          margin-top: 20px;
        `}
        >
          <MultiRangeSlider
            classOverrides={{
              root: css`
                width: 100%;
                height: 29px;
                margin-top: 12px;
                position: relative;
              `,
            }}
            possibleMin={loadedViewModel.possiblePriceRange.min || 0}
            possibleMax={loadedViewModel.possiblePriceRange.max || 0}
            minValue={
              filterViewModel.minPrice.value
              || loadedViewModel.possiblePriceRange.min
              || 0
            }
            maxValue={
              filterViewModel.maxPrice.value
              || loadedViewModel.possiblePriceRange.max
              || 0
            }
            onChange={({ min, max }) => {
              if (min > max) return;
              runInAction(() => {
                filterViewModel.minPrice.set(min);
                filterViewModel.maxPrice.set(max);
              });
            }}
          />

        </div>
        <div
          className={cx(
            css`
              width: 100%;
              display: flex;
            `,
          // borders.DEBUG_greenBorderDashed,
          )}
        >
          <p className={cx(
            css`
              height: 38px;
              background-color: ${COLORS2.sub.MrtGray3};
              padding: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              /* flex: 1 1 33%; */
              width: fit-content;

            `,
            font.Body1_Regular,
          )}
          >
            {(filterViewModel.minPrice.value
              || loadedViewModel.possiblePriceRange.min
              || '')
              .toLocaleString('ko-kr')}원
          </p>
          <span className={css`
            margin: auto;
          `}
          >~
          </span>
          <p className={cx(
            css`
              height: 38px;
              background-color: ${COLORS2.sub.MrtGray3};
              padding: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              flex-basis: fit-content;
              /* flex: 1 1 33%; */
            `,
            font.Body1_Regular,
          )}
          >
            {(filterViewModel.maxPrice.value
              || loadedViewModel.possiblePriceRange.max
              || '')
              .toLocaleString('ko-kr')}원
          </p>
        </div>
      </section>

      <section className={css`
        margin-top: ${SPACING.spacing16};
        padding-bottom: ${SPACING.spacing16};
        border-bottom: 1px solid ${COLORS2.sub.MrtGray2};
      `}
      >
        <h1 className={font.Headline1_Bold}>브랜드</h1>
        <CheckboxesWithAllToggleForFilter
          carsScrollAreaRef={null}
          checked={filterViewModel.vendorsFilter}
          possibleValues={[
            'Hertz' as VendorCompanyNameType,
          ]}
        />
      </section>

      <section className={css`
        margin-top: ${SPACING.spacing16};
      `}
      >
        <h1 className={font.Headline1_Bold}>주행거리</h1>
        <CheckboxesWithAllToggleForFilter
          carsScrollAreaRef={null}
          checked={filterViewModel.freePermission}
          // eslint-disable-next-line no-underscore-dangle
          possibleValues={FreePermissionType._def.values}
        />
      </section>

      <div className={css`
        padding-top:${SPACING.spacing16};
        position: sticky;
        bottom: 0;
        background-color: ${COLORS2.main.MrtWhite};
        margin-top: auto;
      `}
      >
        <button
          type="button"
          onClick={() => {
            // requirements
            //   1. 'ListingMobile --> Filter --> BackBtn' should return to ListingMobile
            //     --> filter SHOULD be handled using history api
            //   2. 'ListingMobile --> Filter --> Apply --> BackBtn' should NOT show Filter, but previous page
            //     --> with requirement 1, only possible solution: going back
            typeRouter.session.back();
          }}
          className={css`
            width: 100%;
            min-height: ${SPACING.spacing56};

            border-radius: 5px;
            margin-top: auto;
            color: ${COLORS2.main.MrtWhite};
            background-color: ${COLORS2.main.MrtBlue1} !important;
          `}
        >
          적용
        </button>
      </div>
    </div>
  );
});
