import PropTypes from 'prop-types';
import Footer from '../Footer/index';
import Header from '../Header/index';
import { useMediaQuery } from 'react-responsive';

const Layout = ({ children }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <>
      <Header/>
      {
        isMobile ?
          <div className="mainContainer layout tablet:max-w-[380px] relative flex flex-col mobile:mx-6 trimo-screen:mx-auto bg-white">
            {children}
          </div>
          :
          <div className="mainContainer layout tablet:max-w-[1320px] relative flex flex-col min-h-[calc(100vh-72px-166px-130px)] tablet-max:min-h-[calc(100vh-72px-166px-50px)] mobile:mx-6 trimo-screen:mx-auto">
            {children}
          </div>
      }
      {
        window.location.pathname !== '/listing' && <Footer/>
      }
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
