/* eslint-disable max-len */
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { CalendarListViewModel } from '^/components/calendar2/List/Calendar.List.viewmodel';
import { borders } from '^/constant/CommonStyles';
import { font, SPACING } from '^/constant/CommonStyles2';
import { NOTICE_DETAIL } from '^/constant/routes';
import { CarListingSearchBoxDesktop } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.desktop';
import { typeRouter } from '^/typeRoute/typeRoutes';

import Banner from '../Notice/components/Banner';
import { fakeBanner } from '../Notice/data';

import { HomePage2ViewModel } from './HomePage2.viewmodel';

export const HomePage2Desktop: React.FC<{
  viewmodel: HomePage2ViewModel,
}> = observer(function HomePage2Desktop({
  viewmodel,
}) {
  const navigate = useNavigate();
  return (

    <div className={cx(
      css`
        position: relative;
        /* overflow-x: auto; */
        height: 788px;
        @media (max-width: 1280px) {
          padding: 0 24px;
        }
      `,
      // borders.DEBUG_greenBorderDashed,
    )}
    >
      {/* <pre
        className={css`
          position: fixed;
          top: 0;
          left: 10px;
          background: yellow;
          z-index: 11111111;
          opacity: 0.3;
          pointer-events: none;
        `}
      >
        {JSON.stringify(
          viewmodel.carListingSearchBox
            .stepManager
            .getSteps(),
          null,
          2,
        )}
      </pre> */}
      <h1 className={cx(
        font.LargeTitle,
        css`
          margin-bottom: 56px;
        `,
      )}
      >
        <div>이번 휴가는 </div>
        <div>해외여행 어떠세요?</div>
      </h1>
      {/* <pre> {JSON.stringify(viewmodel.routeParams)} </pre> */}
      <CarListingSearchBoxDesktop
        viewmodel={viewmodel.carListingSearchBox}
        onBlur={() => viewmodel.carListingSearchBox
          .stepManager
          .clearStep()}
      />

      {/* className="grid-row-[312px,312px,312px,312px] grid grid-cols-2 mobile:mt-0 mobile:gap-[30px] tablet:mt-[30px] tablet:gap-6 laptop:grid-cols-4" */}
      <div className={css`
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-top: 58px;
        gap: ${SPACING.spacing24};

        /* width: 1320px; */
      `}
      >
        {fakeBanner.map((banner) => {
          return (
            <Banner
              key={banner.id}
              item={banner}
              onClick={() => {
                // typeRouter.routes.noticeDetail({ id: banner.id }).push();

                navigate({
                  pathname: NOTICE_DETAIL,
                  search: `${new URLSearchParams({
                    id: String(banner.id),
                  })}`,
                });
                // navigate(`${NOTICE_DETAIL}?id=${banner.id}`);
              }}
              hasIcon={false}
            />
          );
        })}
      </div>
    </div>
  );
});
