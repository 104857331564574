import { RefObject } from 'react';

import { observer } from 'mobx-react-lite';

import { CarListingSearchBoxViewModel } from '../forms/CarListingSearchBox/CarListingSearchBox.viewmodel';

import { Step0DifferentRentalLocation } from './Step0.differentRentalLocation';
import { Step0SameRentalLocation } from './Step0.sameRentalLocation';

export const Step0Root: React.FC<{
  viewmodel: CarListingSearchBoxViewModel,
  pickupCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
  returnCityInputDummyRefForIOSKeyboard: RefObject<HTMLInputElement>,
  carsScrollAreaRef: RefObject<HTMLDivElement> | null,
  carClassChipsScrollRef: RefObject<HTMLDivElement> | null,
}> = observer(function Step0Root({
  viewmodel,
  pickupCityInputDummyRefForIOSKeyboard,
  returnCityInputDummyRefForIOSKeyboard,
  carsScrollAreaRef,
  carClassChipsScrollRef,
}) {
  if (viewmodel.i.hasDifferentPickupReturnLocations.value) {
    return (
      <Step0DifferentRentalLocation
        viewmodel={viewmodel}
        pickupCityInputDummyRefForIOSKeyboard={
          pickupCityInputDummyRefForIOSKeyboard
        }
        returnCityInputDummyRefForIOSKeyboard={
          returnCityInputDummyRefForIOSKeyboard
        }
        carsScrollAreaRef={carsScrollAreaRef}
        carClassChipsScrollRef={carClassChipsScrollRef}
      />
    );
  }
  return (
    <Step0SameRentalLocation
      pickupCityInputDummyRefForIOSKeyboard={
        pickupCityInputDummyRefForIOSKeyboard
      }
      viewmodel={viewmodel}
      carsScrollAreaRef={carsScrollAreaRef}
      carClassChipsScrollRef={carClassChipsScrollRef}
    />
  );
});
