import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import DynamicIcons from '../../Icon/index';
import Location from '../../../components/Location/index';
import Button from '../../Button/index';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PaymentResult = ({ title, id, email, isSuccess = true, locationInfo }) => {
  const { t } = useTranslation();
  return (
    <Transition appear show={true} as={Fragment}>
      <div className='relative z-20'>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 h-full bg-black bg-opacity-30" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex tablet:flex-row flex-col min-h-full items-center justify-center p-4 text-center gap-16">
            { isSuccess ?
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className='tablet:w-[536px] max-w-[95vw] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='text-secondary-green relative text-large font-bold pl-8 mb-4'>
                    <DynamicIcons name='AiOutlineCheckCircle' style='absolute top-1.5 left-0' />
                    <span>{t(title)} {t('successful')}!</span>
                  </div>
                  <p className='text-default text-neutral-300'>
                    {t('thanksForBooking')} <span className='font-bold text-primary-gray'>{email}</span>
                  </p>
                  <div className='relative text-primary-gray w-fit pr-5 my-4'>
                    <span>{t('bookingReferenceID')}: {id}</span>
                    <DynamicIcons name='MdOutlineFileCopy' style='absolute top-1 right-0'/>
                  </div>
                  <div className='bg-primary-white border border-neutral-200 p-4 rounded-lg'>
                    <Location locationsInfo={locationInfo} />
                  </div>
                </div>
              </Transition.Child> :
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className='tablet:w-[536px] max-w-[95vw] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='text-secondary-red relative text-large font-bold pl-8 mb-4'>
                    <DynamicIcons name='AiOutlineCloseCircle' style='absolute top-1.5 left-0' />
                    <span>{t(title)} {t('unsuccesful')}{' .'}{t('tryAgain')}</span>
                  </div>
                  <Button content='tryADifferentPaymentMethod' rounded />
                </div>
              </Transition.Child>
            }
          </div>
        </div>
      </div>
    </Transition>
  );
};

PaymentResult.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  email: PropTypes.string,
  isSuccess: PropTypes.bool,
  locationInfo: PropTypes.array,
};

export default PaymentResult;
