/* eslint-disable max-len */
import { css } from '@emotion/css';

import { TermsAndCondition1UsageContents } from '^/pages/Booking/components/DriverDetails/components/TermAndCondition/TermsAndCondition1Usage.contents';

import TermWrapper from './TermWrapper';

export const TermsServicePopup: React.FC<{
  open: boolean,
  setOpen: (o: boolean) => void;
}> = ({ open, setOpen }) => {
  const content = (
    <div className="table-max:font-light flex flex-col text-default text-primary-gray tablet-max:max-h-[calc(100vh)] tablet-max:leading-[19px]">
      <div
        id="TermsService_Content"
        className={[
          'table-max:font-light flex flex-col text-default text-primary-gray antialiased tablet-max:leading-[19px]',
          css`
            > h3:first-child {
              margin-top: 0rem;
            }
            > h3 {
              margin-top: 1.5rem;
              margin-bottom: 1rem;
            }
          `,
        ].join(' ')}
      >
        <TermsAndCondition1UsageContents
          termsItemHeaderStyle={[
            'text-big font-bold tablet-max:mb-3',
          ].join(' ')}
          termsItemContentsStyle={[
            'font-light',
            css`
              br {
                display: block; /* makes it have a width */
                content: ""; /* clears default height */
                margin-top: 20px; /* change this to whatever height you want it */
              }
            `,
          ].join(' ')}
        />
      </div>

      <div className="pb-6" />
    </div>
  );
  return (
    <TermWrapper
      classNames="
        tablet:rounded-[10px] tablet:w-[568px] tablet:h-[90vh]
        mobile:h-[100vh] mobile:w-[100vw] mobile:rounded-none
      "
      open={open}
      onCloseModal={() => setOpen(false)}
      title="이용약관"
      content={content}
    />
  );
};
export default TermsServicePopup;
