import { makeAutoObservable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { TimeSelectHourNullable, TimeSelectMinuteNullable } from '^/domainComponents/inputs/TimeSelect/TimeSelect.constants';
import { DatetimeWithoutTimezone, decodeDatetimeWithoutTimezone } from '^/types/__BrandedTypes';
import { asFailedPromise, asSuccessPromise, NotInitiatedPromiseStale } from '^/types/__ResultType';
import { isMinuteEnabled, OpenCloseTime } from '^/types/RawOpenHours';
import { MobxInputValue } from '^/util/MobxInputValue';

export class ScheduleTimeViewModel {
  constructor(
    public pickupHour: MobxInputValue<TimeSelectHourNullable>,
    public pickupMinute: MobxInputValue<TimeSelectMinuteNullable>,
    public returnHour: MobxInputValue<TimeSelectHourNullable>,
    public returnMinute: MobxInputValue<TimeSelectMinuteNullable>,
  ) {
    makeAutoObservable(this, {
      validatePickupTime: false,
      validateReturnTime: false,
    });
  }

  copyFrom(from: ScheduleTimeViewModel) {
    this.pickupHour.set(from.pickupHour.value);
    this.pickupMinute.set(from.pickupMinute.value);
    this.returnHour.set(from.returnHour.value);
    this.returnMinute.set(from.returnMinute.value);
  }

  clone() {
    return new ScheduleTimeViewModel(
      this.pickupHour.clone(),
      this.pickupMinute.clone(),
      this.returnHour.clone(),
      this.returnMinute.clone(),
    );
  }

  validatePickupTime = computedFn((
    pickupOpeningHours: OpenCloseTime[],
  ) => {
    if (!this.pickupHour.value || !this.pickupMinute.value) {
      return NotInitiatedPromiseStale;
    }
    if (!isMinuteEnabled(
      pickupOpeningHours,
      Number(this.pickupHour.value),
      Number(this.pickupMinute.value),
    )) {
      return asFailedPromise({
        messageKo: '대여 시간이 영업시간 밖입니다',
        reason: 'PICKUP_TIME_OUTSIDE_OPENING_HOURS',
        target: 'pickup',
      } as const);
    }
    return asSuccessPromise({
      pickupHour: this.pickupHour.value,
      pickupMinute: this.pickupMinute.value,
    });
  });

  validateReturnTime = computedFn((
    returnOpeningHours: OpenCloseTime[],
  ) => {
    if (!this.returnHour.value || !this.returnMinute.value) {
      return NotInitiatedPromiseStale;
    }
    if (!isMinuteEnabled(
      returnOpeningHours,
      Number(this.returnHour.value),
      Number(this.returnMinute.value),
    )) {
      return asFailedPromise({
        messageKo: '대여 시간이 영업시간 밖입니다',
        reason: 'RETURN_TIME_OUTSIDE_OPENING_HOURS',
        target: 'return',
      } as const);
    }
    return asSuccessPromise({
      returnHour: this.returnHour.value,
      returnMinute: this.returnMinute.value,
    });
  });

  reset() {
    this.pickupHour.set(null);
    this.pickupMinute.set(null);
    this.returnHour.set(null);
    this.returnMinute.set(null);
  }
}

export function createScheduleTimeViewModelFromDefaults(
  pickupDateTime: DatetimeWithoutTimezone | undefined,
  returnDateTime: DatetimeWithoutTimezone | undefined,
) {
  const p = pickupDateTime
    && decodeDatetimeWithoutTimezone(pickupDateTime);
  const r = returnDateTime
    && decodeDatetimeWithoutTimezone(returnDateTime);
  return new ScheduleTimeViewModel(
    new MobxInputValue(
      p?.hour || null,
    ),
    new MobxInputValue(
      p?.minute || null,
    ),
    new MobxInputValue(
      r?.hour || null,
    ),
    new MobxInputValue(
      r?.minute || null,
    ),
  );
}
