import { action, makeObservable } from 'mobx';

import { CarListingSearchBoxInputViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.InputViewmodel';
import {
  BaseSearchBoxStepManager,
  SearchBoxSteps,
} from '^/domainComponents/forms/CarListingSearchBox/SearchBoxStepManager';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { ListingPage2RouteParams } from './ListingPage2RouteParams';

export class ListingSearchBoxStepManager
  extends BaseSearchBoxStepManager<ListingPage2RouteParams> {
  constructor(
    public routeParams: ListingPage2RouteParams,
    public carListingSearchInputVM: CarListingSearchBoxInputViewModel,
    public goBackFn: (n: number) => void,
    public pushCount = 0,
  ) {
    super();
    makeObservable(this, {
      updatePushCountBy: true,
      getInputViewModel: true,
      getSteps: true,
      pushStepsToRoute: action.bound,
      replaceStepsInRoute: action.bound,
      updateRouteParams: action.bound,
      guardStep: true,
      pushStep: action.bound,
      clearStep: action.bound,
      replaceStep: action.bound,
      currentStep: true,
      routeParams: true,
      carListingSearchInputVM: true,
      pushCount: true,
    });
  }

  getInputViewModel() {
    return this.carListingSearchInputVM;
  }

  updateRouteParams(routeParams: ListingPage2RouteParams) {
    this.routeParams = routeParams;
  }

  getSteps() {
    return this.routeParams.steps || [];
  }

  pushStepsToRoute(newSteps: SearchBoxSteps[]) {
    this.pushCount += 1;
    console.log(
      'ListingSearchBoxStepManager::pushStepsToRoute: pushCount: ',
      this.pushCount,
    );
    typeRouter
      .routes
      .listing2({
        ...(this.routeParams || {}),
        steps: newSteps || [],
      }).push();
  }

  replaceStepsInRoute(newSteps: SearchBoxSteps[]) {
    try {
      typeRouter
        .routes
        .listing2({
          ...(this.routeParams || {}),
          steps: newSteps || [],
        }).replace();
    } catch (e) {
      debugger;
    }
    // }
  }

  clearStep() {
    console.log(
      'ListingSearchBoxStepManager::clearStep()',
      'pushCount: ',
      this.pushCount);
    for (let i = 0; i < this.pushCount; i += 1) {
      this.goBackFn(1);
    }
  }

  updatePushCountBy(n: number): void {
    this.pushCount += n;
  }
}
