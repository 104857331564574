import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { CalendarStateViewModel } from '^/components/calendar2/Calendar.state.viewmodel';
import { dayTypeFromString } from '^/components/calendar2/Day/DayType';
import { CalendarListViewModel } from '^/components/calendar2/List/Calendar.List.viewmodel';
import { CarListingSearchBoxInputViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.InputViewmodel';
import { CarListingSearchBoxViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { asSuccessPromise, getCurrentFreshness } from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';
import { MobxValue } from '^/util/MobxValue';

import { ListingPage2CardViewModel } from './ListingPage2.card.viewmodel';
import { ListingPage2FilterViewModel } from './ListingPage2.FilterViewModel';
import { ListingPage2LoadedViewModel } from './ListingPage2.LoadedViewModel';
import { ListingPage2RouteParams } from './ListingPage2RouteParams';
import { ListingSearchBoxStepManager } from './ListingSearchBoxStepManager';
import { ListingSearchBoxStepManagerDesktop } from './ListingSearchBoxStepManager.desktop';

export class ListingPage2ViewModel {
  constructor(
    public appContext: AppContext,
    public routeParamsValues: ListingPage2RouteParams,
    public calendarsCount: number,
    public isMobile: boolean,
    public calendarPaginationOffset = new MobxInputValue(0),
    public freshness = getCurrentFreshness(),
    public filterViewModel = new ListingPage2FilterViewModel(),
    public calendarStateViewModel = new CalendarStateViewModel(
      calendarPaginationOffset,
      undefined,
      undefined,
      {
        start: dayTypeFromString(routeParamsValues.pDatetime),
        end: dayTypeFromString(routeParamsValues.rDatetime),
      },
    ),
    public calendarListViewModel = new CalendarListViewModel(
      calendarsCount,
    ),
    public carListingSearchBoxInputVm = appContext
      .carListingSearchBoxInput
      .value || new CarListingSearchBoxInputViewModel(
      appContext,
      freshness,
      calendarStateViewModel,
      routeParamsValues,
    ),
    public stepManager = isMobile
      ? new ListingSearchBoxStepManager(
        routeParamsValues,
        carListingSearchBoxInputVm,
        (n) => {
          typeRouter.session.back(n);
        },
      ) : new ListingSearchBoxStepManagerDesktop(
        carListingSearchBoxInputVm,
      ),
    public carListingSearchBox = new CarListingSearchBoxViewModel(
      appContext,
      freshness,
      calendarListViewModel,
      carListingSearchBoxInputVm,
      stepManager,
    ),

  ) {
    console.log('ListingPage2ViewModel constructor');
    makeAutoObservable(this);
    if (appContext.carListingSearchBoxInput.value === null) {
      appContext.carListingSearchBoxInput.setValue(
        this.carListingSearchBox.i,
      );
    }
  }

  setRouteParams(routeParamsValues: ListingPage2RouteParams) {
    if (this.routeParamsValues.pDatetime !== routeParamsValues.pDatetime
      || this.routeParamsValues.rDatetime !== routeParamsValues.rDatetime
    ) {
      this.calendarStateViewModel.selection = {
        start: dayTypeFromString(routeParamsValues.pDatetime),
        end: dayTypeFromString(routeParamsValues.rDatetime),
      };
    }
    this.routeParamsValues = {
      ...this.routeParamsValues,
      ...routeParamsValues,
    };
  }

  get allCarList() {
    const r = this.routeParamsValues;
    const carListResult = this.appContext
      .getCarListCore
      .getList({
        birth_day: r.birthday,
        // pickup
        pickup_city: r.pCityCode,
        pickup_datetime: r.pDatetime,
        pickup_location: r.pLocationCode,
        // return
        return_city: r.rCityCode || r.pCityCode,
        return_datetime: r.rDatetime,
        return_location: r.rLocationCode || r.pLocationCode,
      }, this.freshness, {
        hideStaleValue: true,
      });
    if (carListResult.status !== 'success') {
      return carListResult;
    }
    return asSuccessPromise(
      new ListingPage2LoadedViewModel(
        carListResult.value
          .map((data) => new ListingPage2CardViewModel(
            this.appContext,
            data,
            this.routeParamsValues,
          )),
        this.filterViewModel,
      ),
      carListResult.freshness,
    );
  }
}
