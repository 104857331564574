/* eslint-disable max-len */
import { css } from 'styled-components';

import TermWrapper from './TermWrapper';

// const titleClassName = 'mb-4 text-big font-bold tablet-max:my-0 tablet-max:text-big';
// const textClassName = 'mt-[18px] text-default font-light leading-[19px] tablet-max:mt-[18px] tablet-max:mb-0 tablet-max:font-light';
export const tableStyle = [
  'table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small',
  css`
    th, td {
      border-width: 1px;
      border-color: rgb(30, 30, 30);
    }
  `,
].join(' ');
export const TermsPrivacyPopup: React.FC<{
  open: boolean,
  setOpen: (o: boolean) => void;
}> = ({ open, setOpen }) => {
  const content = (
    <div className="table-max:font-light flex flex-col text-default text-primary-gray tablet-max:max-h-[calc(100vh)] tablet-max:leading-[19px]">
      <h3 className="mb-4 text-big font-bold tablet-max:mb-3 tablet-max:text-default">
        1. 개인정보 보호와 취급방침
      </h3>
      <p className="text-default font-light tablet-max:text-small">
        ① 아이엠에스모빌리티 개인정보취급방침이란 서비스를 이용하는 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 하기 위한 아이엠에스모빌리티(주)(이하 “회사”라 함)의 지침을 의미합니다. 회사는 본 방침에 관하여 대한민국의 개인정보보호 규정 및 가이드라인을 준수하고 있습니다.
        <br />② 회사는 이용자의 동의를 기반으로 개인정보를 수집·이용 및 제공하고 있으며, 이용자의 권리(개인정보 자기결정권)를 적극적으로 보장합니다.
        <br />③ 본 개인정보취급방침은 회사가 운영하는 서비스에서 적용되는 것을 원칙으로 하며 본 개인정보취급방침은 대한민국 법령에 의하여 규정되고 해석됩니다.
      </p>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
        2. 개인정보 수집 항목 및 방법
      </h3>
      <p className="text-default font-light tablet-max:text-small">
        모든 이용자는 회사가 제공하는 서비스를 이용할 수 있고, 회사의 다양한 서비스를 제공받을 수 있습니다. 그리고 이때 회사는 다음의 원칙하에 이용자의 개인정보를 수집하고 있습니다.
        <br /><br />① 회사는 서비스 제공에 필요한 최소한의 개인 정보를 수집하고 있습니다.
        <br />{'<개인정보 수집 항목>'}
      </p>
      <table className={tableStyle}>
        <tr>
          <th>항목</th>
          <th>수집 내용</th>
        </tr>
        <tr>
          <td className="border border-trimo-dark text-center">서비스 이용</td>
          <td className="border border-trimo-dark">예약자 <br /> 성명,  성명(국문/영문), 생년월일, 휴대폰번호, 이메일주소, 이용 지역 및 일정, 이용 요금정보 및 추가서비스, 항공편명, 상용고객정보, 업체 회원번호(Hertz Gold회원 번호 등), 카카오톡 ID (업체 카카오톡 채널과 대화 진행 시 수집)</td>
        </tr>
        <tr>
          <td className="border border-trimo-dark text-center">결제</td>
          <td className="border border-trimo-dark">결제를 위한 고객의 신용카드 정보 또는 가상계좌 정보</td>
        </tr>
        <tr>
          <td className="min-w-[120px] border border-trimo-dark text-center">서비스 중 자동 수집</td>
          <td className="border border-trimo-dark">IP Address, 쿠키, 방문 일시, 서비스 이용기록, 이용자의 사용 OS, 기기 고유번호, 서비스 이용기록, 불만 및 분쟁처리 기록</td>
        </tr>
      </table>
      <p className="mt-3 text-default font-light tablet-max:text-small">{'<개인정보 수집방법>'}</p>
      <table className="w-full table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small">
        <tr>
          <th className="border border-trimo-dark">수집 방법</th>
        </tr>
        <tr>
          <td className="border border-trimo-dark text-center">서비스 이용, 전화, 고객센터 등</td>
        </tr>
      </table>
      <p className="mt-3 text-default font-light tablet-max:text-small">② 해당 정보는 이용자가 확인한 시점을 기준으로 한 정보이며, 이용자의 개인정보를 추가 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 거치겠습니다.
        <br />③ 회사는 민감 정보를 수집하지 않습니다.
        회사는 이용자의 소중한 인권을 침해할 우려가 있는 민감한 정보(인종, 사상 및 신조, 정치적 성향 이나 범죄기록, 의료정보 등)는 어떠한 경우에도 수집하지 않으며, 만약 법령에서 정한 의무에 따라 불가피하게 수집하는 경우에는 반드시 이용자에게 사전 동의를 거치겠습니다.
      </p>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">3. 개인정보 이용 목적</h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">회사는 이용자의 소중한 개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는 사전에 이용자의 동의를 구하는 등 필요한 조치를 취하도록 합니다.
        <br />i.                기기의 고유한 번호로 이용자를 식별하고, 각종 고지나 통지, 고충처리의 목적 및 불량 이용자의 부정한 이용을 방지하기 위하여 사용합니다.
        <br />ii.               이용자에게 회사의 다양한 서비스를 제공하고 서비스 이용 과정에서 이용자의 문의 사항이나 불만을 처리하고 공지사항 등을 전달하기 위해 사용합니다.
        <br />iii.             이용자와 약속한 서비스를 제공하고 유료 서비스 구매 및 이용이 이루어지는 경우 이에 따른 요금 정산을 위해 사용됩니다.
        <br />iv.             신규 서비스가 개발되거나 이벤트 행사 시 참여 기회를 알리기 위한 정보 전달 및 마케팅, 광고 등에도 사용됩니다.
        <br />v.               이용자의 이용 패턴과 접속 빈도 분석 및 서비스 이용에 대한 통계 및 이를 통한 맞춤형 서비스 제공 및 서비스 개선에도 사용됩니다.
      </p>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">4. 개인정보 보유 및 기간</h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">회사는 이용자의 개인정보를 서비스 이용 시작일부터 서비스를 제공하는 기간 동안에만 제한적으로 이용하고 있습니다. 이용자가 개인정보 삭제를 요청하거나 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 이용자의 개인정보는 지체 없이 파기됩니다.
        <br />그리고 관계 법령의 규정에 따라 일정한 기간 동안 이용자 정보를 보관하며 자세한 내용은 아래와 같습니다.
      </p>
      <table className="w-full table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small">
        <tr>
          <th className="border border-trimo-dark">보관내용</th>
          <th className="border border-trimo-dark">보관근거</th>
          <th className="border border-trimo-dark">수집 내용</th>
        </tr>
        <tr>
          <td className="border border-trimo-dark">소비자 불만 및 분쟁처리에 관한 기록</td>
          <td className="border border-trimo-dark">전자상거래 등에서의 소비자보호에 관한 법률</td>
          <td className="border border-trimo-dark text-center">3년</td>
        </tr>
        <tr>
          <td className="border border-trimo-dark">대금결제 및 재화 등의 공급에 관한 기록</td>
          <td className="border border-trimo-dark">전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 시행령 제6조</td>
          <td className="border border-trimo-dark text-center">5년</td>
        </tr>
        <tr>
          <td className="border border-trimo-dark">접속에 관한 기록</td>
          <td className="border border-trimo-dark">통신비밀보호법 제15조의2 및 시행령 제41조</td>
          <td className="border border-trimo-dark text-center">3개월</td>
        </tr>
        <tr>
          <td className="border border-trimo-dark">부정거래기록</td>
          <td className="border border-trimo-dark">부정거래의 배제 등 회사 방침에 의한 보존</td>
          <td className="border border-trimo-dark text-center">5년</td>
        </tr>
        <tr>
          <td className="border border-trimo-dark">이용자 생년월일, 휴대폰 번호, 이메일주소, 카카오톡 ID</td>
          <td className="border border-trimo-dark">부정거래의 배제 등 회사 방침에 의한 보존, 정보주체 동의</td>
          <td className="border border-trimo-dark text-center">5년</td>
        </tr>
      </table>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">5. 개인정보 취급 위탁</h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
        <br />회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      </p>
      <table className="w-full table-auto border-collapse border border-trimo-dark text-default font-light tablet-max:text-small">
        <tr>
          <th className="border border-trimo-dark">수탁업체</th>
          <th className="border border-trimo-dark">위탁내용</th>
          <th className="border border-trimo-dark">개인정보의 보유 및 이용기간</th>
        </tr>
        <tr>
          <td className="border border-trimo-dark">기업은행</td>
          <td className="border border-trimo-dark">결제처리 (가상계좌 입금) 및 환불처리</td>
          <td className="border border-trimo-dark">위탁 계약 만료 시까지 보유</td>
        </tr>
        <tr>
          <td className="border border-trimo-dark">KG이니시스, 아임포트</td>
          <td className="border border-trimo-dark">신용카드 결제수단 제공 및 환불처리</td>
          <td className="border border-trimo-dark">위탁 계약 만료 시까지 보유</td>
        </tr>
      </table>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
        6. 개인정보 파기
      </h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">
        회사는 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우를 제외하고는 이용자의 개인정보에 대해 “개인정보의 수집·이용 목적이 달성”된 후 및 개인정보 보유기간이 경과된 후에는 해당 정보를 재생할 수 없는 기술적인 방법을 이용하여 완전하게 삭제하며, 종이로 출력된 개인정보는 분쇄하거나 소각하여 지체 없이 파기함을 약속합니다.
      </p>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
        7. 이용자의 권리
      </h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">
        회사는 서비스 이용에 대해, 이용자의 권리를 다음과 같이 보호하고 있습니다.
        <br />i. 언제든지 예약 조회 등의 방법을 통하여 등록되어 있는 자신의 개인정보를 조회할 수 있으며, 변경 또는 삭제를 요청할 수 있습니다. 다만, 그러한 경우 해당 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
        <br />ii. 언제든지 개인정보 제공에 관한 동의 철회를 요청할 수 있습니다.
        <br />iii. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
        <br />iv. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 &quot;개인정보의 보유 및 이용기간&quot; 에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
      </p>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
        8. 개인정보를 자동으로 수집하는 장치의 설치・운영 및 그 거부에 관한 사항
      </h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">
        회사는 이용자에게 특화된 맞춤서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 &apos;쿠키(cookie)&apos;를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 작은 텍스트 파일로 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
        <br />① 쿠키의 설치/운영 및 거부
        <br />이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
        <br />i.                인터넷 익스플로러의 경우 : 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
        <br />ii.               구글 크롬의 경우 : 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보
        <br /><br />다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 서비스는 이용할 수 없습니다.
        <br />회사는 Google Analytics, 채널톡, Smartlook 등을 활용하여 이용자의 서비스 이용에 대해 분석 합니다. Google Analytics, 채널톡, Smartlook 등은 이용자의 사이트 활용 방식을 이해하는데 도움을 주는 웹로그 분석 도구를 포함합니다. 개별 이용자의 개인 정보를 식별하지 않고 익명의 사용자 정보를 활용하여 웹사이트 사용 통계를 보고하기 위해 쿠키를 사용할 수 있습니다.  Web분석을 해제하려면 Opt-out Browser Add-on (https://tools.google.com/dlpage/gaoptout) 등을 설치할 수 있습니다.
      </p>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
        9. 링크 사이트에 대한 책임
      </h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">
        회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 다만, 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 &quot;개인정보취급방침&quot;이 적용되지 않습니다.
      </p>
      <h3
        className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default"
      >
        10. 개인정보의 기술적/관리적 보호 대책
      </h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">
        회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
        <br /><br />① 해킹 등에 대비한 대책
        <br /><br />회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
        <br /><br />② 취급 직원의 최소화 및 교육
        <br /><br />회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보취급방침의 준수를 항상 강조하고 있습니다.
        <br /><br />③ 개인정보보호 전담기구의 운영
        <br /><br />사내 개인정보보호 전담기구 등을 통하여 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
      </p>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
        11. 개인정보 관리 책임자 및 담당부서
      </h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">책임자: 김수환 이사
        <br />전화: 02-548-8348
        <br />문의: kimsw@imsmobility.co.kr
        <br /><br />또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우 또는 개인정보보호법에 따른 개인정보의 열람청구가 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
        <br />개인정보분쟁조정위원회: (국번없이) 1833-6972 (https://www.kopico.go.kr)
        <br />개인정보침해 신고센터: (국번없이) 118 (https://privacy.kisa.or.kr)
        <br />대검찰청 사이버수사과: (국번없이) 1301 (https://www.spo.go.kr)
        <br />경찰청 사이버안전국: (국번없이) 182 (https://cyberbureau.police.go.kr)
      </p>
      <h3 className="mt-6 text-big font-bold tablet-max:mt-4 tablet-max:text-default">
        12. 시행일
      </h3>
      <p className="mt-3 text-default font-light tablet-max:text-small">이 {'<개인정보 처리방침>'}은 2022년 8월 29일부터 적용됩니다.</p>
      <p className="mt-3 text-left text-default font-light tablet-max:text-small">개인정보취급방침 버전번호: 1.1
        <br />개인정보취급방침 시행일자: 2022년 10월 20일
      </p>

      <div className="pb-6" />
    </div>
  );
  return (
    <TermWrapper
      classNames="

        tablet:rounded-[10px] tablet:w-[568px] tablet:h-[90vh]
        mobile:h-[100vh] mobile:w-[100vw] mobile:rounded-none
      "
      open={open}
      onCloseModal={() => setOpen(false)}
      title="개인정보 취급방침"
      content={content}
    />
  );
};
export default TermsPrivacyPopup;
