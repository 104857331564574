import { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import setDefaultDatefnsOptions from 'date-fns/setDefaultOptions';
import koDatefnsLocale from 'date-fns/locale/ko';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getAllLocales } from './api/translation';
import './App.css';
import Layout1 from './components/Layout/index';
import { Layout2 } from './components/Layout/Layout2';
import {
  // NEW_RESERVATION,
  BOOKING_PATH, HELP_PAGE, HOME_PATH, LISTING_PATH,
  MAPLOCATION_PATH, MY_RESERVATION_PATH, NOTICE,
  NOTICE_DETAIL, NOT_FOUND_PATH, RESERVATION_DETAIL, RESULT_PATH, RESERVATION_CHECK,
} from './constant/routes';
// import Listing from './pages/Listing';
// import Login from './pages/Login/index';
import { ReservationCheckPageWrapper } from './pages/ReservationCheck/ReservationCheck.page.wrapper';
// import NewReservation from './pages/NewReservation/index';
import { BOOKING, ROOT } from './constant/envConfig';
import useScript from './hooks/useScript';
import Result from './pages//Result/index';
import { Help } from './pages/FAQ/index';
// import Booking from './pages/Booking/index';
// import MyReservation from './pages/MyReservation';
// import Notice from './pages/Notice';
// import NoticeDetail from './pages/Notice/NoticeDetail';
// import Register from './pages/Register';
import {
  continentsState,
  errorMsgState,
  errorState,
  footerColorState,
  subdomainState,
} from './recoil/atoms/index';
import { localesState } from './state';
import { useMediaQuery } from 'react-responsive';
import { getSubdomain } from './util';
// import { HomePage as Home } from './pages/Home/HomePage';
import qs from 'qs';
import { typeRouter } from './typeRoute/typeRoutes';
import { TypeRouteRenderer } from './typeRoute/TypeRouteRenderer';
import { defaultAppContext } from './AppContext/AppContext';

setDefaultDatefnsOptions({
  locale: koDatefnsLocale,
});

// import Home from './pages/Home';
const Error = lazy(() => import('./pages/Error'));
const NotFound = lazy(() => import('./pages/NotFound'));
const MapLocation = lazy(() => import('./pages/MapLocation'));
const Notice = lazy(() => import('./pages/Notice'));
const NoticeDetail = lazy(() => import('./pages/Notice/NoticeDetail'));
const MyReservation = lazy(() => import('./pages/MyReservation'));
const Booking = lazy(() => import('./pages/Booking'));
const ReservationDetail = lazy(() => import('./pages/MyReservation/components/ResDetail'));


export const globalState = createContext(null);

const queryString = qs.parse(window.location.search.slice(1));
if (queryString.pid) {
  sessionStorage.setItem('_AGENT_DATA_', JSON.stringify(queryString));
}
const { RouteProvider: TypeRouteProvider } = typeRouter;

function App() {
  const { data: locales } = useQuery('locales', getAllLocales, {
    initialData: {
      data: [],
    },
  });
  // const { data: continents } = useQuery(
  //   'getContinentsList',
  //   getCountriesGroubByContinent,
  //   {
  //     retry: 1,
  //   },
  // );
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== BOOKING_PATH) {
      localStorage.removeItem(BOOKING);
    }
  }, [location]);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [, setFooterColor] = useRecoilState(footerColorState);
  useEffect(() => {
    if (isMobile) {
      switch (location.pathname) {
      case HOME_PATH:
      case RESULT_PATH:
      case MY_RESERVATION_PATH:
      case RESERVATION_DETAIL:
      case NOTICE:
      case HELP_PAGE:
        setFooterColor('trimo-bg');
        break;
      default:
        setFooterColor('white');
      }
    } else {
      setFooterColor('white');
    }
  }, [location, isMobile]);

  const continentsList = useSetRecoilState(continentsState);
  const [languages, setLanguages] = useState();
  const setAllLocales = useSetRecoilState(localesState);
  const [loaded, setLoaded] = useState(false);
  const setSubdomain = useSetRecoilState(subdomainState);

  useEffect(() => {
    if (locales && locales.data) {
      setAllLocales(locales.data);
    }
  }, [locales]);

  continentsList([
    { name: 'Guam', code: 'GUM' },
    { name: 'Honolulu', code: 'HNL' },
    { name: 'Saipan', code: 'SPN' },
    { name: 'Los Angeles', code: 'LAX' },
    { name: 'San Francisco', code: 'SFO' },
    { name: 'Las Vegas', code: 'LAS' },
    { name: 'Okinawa', code: 'OKA' },
    { name: 'Fukuoka', code: 'FUK' },
    { name: 'Sapporo', code: 'SPK' },
    { name: 'Paris', code: 'PAR' },
    { name: 'Rome', code: 'ROM' },
    { name: 'Frankfurt', code: 'FRA' },
    { name: 'Sydney', code: 'SYD' },
    { name: 'Lisbon', code: 'LIS' },
    { name: 'Milan', code: 'MIL' },
  ]);

  useEffect(() => {
    // check subdomain
    const subdomain = getSubdomain();
    if (subdomain !== ROOT) {
      setSubdomain(subdomain);
    } else {
      setSubdomain('trimo');
    }
    setLoaded(true);
  }, []);

  const [isOpen, setIsOpen] = useRecoilState(errorState);
  const errorMsg = useRecoilValue(errorMsgState);

  return (
    <TypeRouteProvider>
      <globalState.Provider value={{ languages, setLanguages }}>
        {/* <Translation /> */}
        <Suspense fallback={<div></div>}>
          <Error isOpen={isOpen} handleClose={() => setIsOpen(false)} content={errorMsg} />
          <Routes>
            <Route path={RESERVATION_CHECK} element={<ReservationCheckPageWrapper />} />
            {/* <Route
              path={HOME_PATH}
              element={
                <Layout2
                  scrollAreaKey={HOME_PATH}
                >
                  <Home />
                </Layout2>
              }
            /> */}
            <Route path={NOT_FOUND_PATH} element={<Layout1><NotFound /></Layout1>} />
            <Route path={MAPLOCATION_PATH} element={<Layout1><MapLocation /></Layout1>} />
            {/* <Route
              path={LISTING_PATH}
              element={
                <Layout2
                  withoutFooter
                  withoutOverflowScroll
                  withoutMaxWidth
                  scrollAreaKey={LISTING_PATH}
                >
                  <Listing />
                </Layout2>
              }
            /> */}
            <Route
              path={BOOKING_PATH}
              element={(
                <Layout2
                  withoutOverflowScroll
                  withoutFooter
                  withoutMaxWidth
                >
                  <Booking />
                </Layout2>
              )}
            />
            <Route
              path={RESULT_PATH}
              element={
                <Layout2
                  withoutFooter
                >
                  <Result />
                </Layout2>
              }
            />
            {/* FIXME: please Delete */}
            <Route path={HELP_PAGE} element={<Layout2><Help /></Layout2>} />
            <Route path={NOTICE} element={<Layout1><Notice /></Layout1>} />
            <Route path={NOTICE_DETAIL} element={<Layout1><NoticeDetail /></Layout1>} />
            <Route path={MY_RESERVATION_PATH} element={<Layout1><MyReservation /></Layout1>} />
            <Route path={RESERVATION_DETAIL} element={<Layout1><ReservationDetail /></Layout1>} />
            {/* <Route path="*" element={<Navigate to={'/not-found'} />} /> */}
            <Route path="*" element={

              <TypeRouteRenderer
                appContext={defaultAppContext}
              />
            } />
          </Routes>
        </Suspense>
      </globalState.Provider>
    </TypeRouteProvider>
  );
}

export default App;
