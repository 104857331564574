import { HELP_PAGE, RESERVATION_CHECK } from '^/constant/routes';

export const NAVIGATION_MRT = [
  {
    name: '예약조회',
    url: RESERVATION_CHECK,
  },
  {
    name: 'FAQ',
    url: HELP_PAGE,
  },
];

export const MRT_LOGO = '/myrealtrip/trimo_logo_black.png';
