import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { borders } from '^/constant/CommonStyles';
import {
  COLORS2, FLEX, font, SPACING,
} from '^/constant/CommonStyles2';
import { VENDOR_LOGOS } from '^/constant/envConfig';

import { ListingPage2CardViewModel } from './ListingPage2.card.viewmodel';
import { translateServices } from './translateServices';
import {
  changeToUpperCaseOfWordOnlyFistAlpahbet,
  deleteGrade,
} from './utils';

export const ListingPage2DesktopCard: React.FC<{
  viewmodel: ListingPage2CardViewModel,
}> = observer(function ListingPage2DesktopCard({
  viewmodel,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = viewmodel;
  const {
    POD,
    PPD,
    core,
    services,
  } = data;

  const convertedModelName = data.modelName
    && deleteGrade(
      changeToUpperCaseOfWordOnlyFistAlpahbet(data.modelName),
    );

  const carSize = core.vehType.size;
  const carType = core.vehType.type;

  return (
    <div
      className={cx(
        css`
          display: flex;
          justify-content: space-between;

          width: 100%;
          height: 218px;
          max-width: 1320px;

          padding: ${SPACING.spacing24};
          background-color: ${COLORS2.main.MrtWhite};
          border-radius: 10px;
        `,
        // borders.DEBUG_greenBorderDashed,

      )}
    >
      {/* car img */}
      <div
        id="CarListing2_Images"
        className={cx(
          css`
            position: relative;

          `,
          FLEX.allDirectionCenter,

          // borders.DEBUG_redBorderDashed,
        )}
      >
        <img
          alt="vendor_logo"
          src={VENDOR_LOGOS[core.company.id]}
          className={[
            css`
              position: absolute;
              top: 0; left: 0;
            `,
            'h-[20.6px] w-[58.2px] object-scale-down',
            // borders.DEBUG_blueBorderDashed,
          ].join(' ')}
        />
        <img
          alt="qweqwe"
          // alt="car"
          src={core.image}
          className={[
            css`
              width: 220px;
              height: 128px;

              margin-left: 10px;
            `,
            // css`
            //   width: 20vw !important;
            // `,
            // borders.DEBUG_pinkDashed,
          ].join(' ')}
          onError={(e) => {
            e.currentTarget.src = '/NOT_FOUND_CAR.png';
          }}
        />
      </div>

      {/* CarListing2_Description */}
      <div
        id="CarListing2_Description"
        className={cx(
          css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;

            padding-left: ${SPACING.spacing56};
          `,
          // borders.DEBUG_greenBorderDashed,
        )}
      >
        <h2
          className={font.Title2}
        >
          {`[${t(carSize)}] ${changeToUpperCaseOfWordOnlyFistAlpahbet(carSize)} ${carType !== 'car' ? carType : ''}`}
        </h2>
        <h3
          className={font.Headline1_Regular}
        >
          {convertedModelName} {data.orSimilarCar ? '또는 동급차량' : ''}
        </h3>
        <h4
          className={cx(
            font.Body1_Regular,
            css`
              margin-top: ${SPACING.spacing24};
              color: ${COLORS2.sub.MrtGray1};
            `,
          )}
        >
          {translateServices(services, t).join(' / ')}
        </h4>
      </div>

      <div
        id="gotoBookingButtons"
        className={cx(
          css`
            display: flex;
            flex-direction: row;
            gap: ${SPACING.spacing24};
          `,
          // borders.DEBUG_purpleBorderDashed,
        )}
      >
        { POD && (
          <button
            type="button"
            className={[
              css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                padding: ${SPACING.spacing24} 0;
                color: ${COLORS2.sub.MrtGray1};
                background-color: ${COLORS2.sub.MrtGray3} !important;
                border-radius: 5px;
                min-width: 170px;
              `,
              font.Caption1_Light,
              // borders.DEBUG_blueBorderDashed,
            ].join(' ')}
            onClick={() => viewmodel.gotoBooking('POD', navigate)}
          >
            <span className={font.Body1_Bold}>
              후불 결제
            </span>
            <span className={font.Title1}>
              {POD.payment.foreignCurrency.toLocaleString()} {POD.payment.foreignCurrencyCode}
            </span>
            <span>
              약 {POD.payment.domesticPrice?.toLocaleString('ko-KR')} 원
            </span>
          </button>
        )}
        { PPD && (
          <button
            type="button"
            className={[
              css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                padding: ${SPACING.spacing24} 0;
                color: ${COLORS2.sub.MrtGray1};
                background-color: ${COLORS2.sub.MrtGray3} !important;
                border-radius: 5px;
                min-width: 170px;
              `,
              font.Caption1_Light,
              // borders.DEBUG_blueBorderDashed,
            ].join(' ')}
            onClick={() => viewmodel.gotoBooking('PPD', navigate)}
          >
            <span className={font.Body1_Bold}>
              선불 결제
            </span>
            <span className={font.Title1}>
              {PPD.payment.foreignCurrency.toLocaleString()} {PPD.payment.foreignCurrencyCode}
            </span>
            <span>
              약 {PPD.payment.domesticPrice?.toLocaleString('ko-KR')} 원
            </span>
          </button>
        )}
      </div>
    </div>
  );
});
