import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { differenceInHours, format, differenceInMinutes } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { TABLET_SIZE, VENDOR_LOGOS } from '../../../../../constant/envConfig';
import { useMediaQuery } from 'react-responsive';
import { calculateHours } from '../../../../../util';
import { GroupCar, NameCar } from '../../../../../components/ModelCar';

const ReservationConfirm = ({ bookingInfo, pkgSelected, isTitle=true }) => {
  const { t } = useTranslation();
  const [packageInfo, setPackageInfo] = useState(null);
  const [payType, setPayType] = useState('');

  useEffect(() => {
    setPackageInfo(bookingInfo?.carInfo?.insurances.find((item) => item.package === pkgSelected)?.packageInfo);
  }, [pkgSelected]);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= TABLET_SIZE,
  );

  useEffect(() => {
    setPayType(bookingInfo.carInfo.payment.type);
  }, []);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  const handleResize = useCallback(() => {
    if (window.innerWidth <= TABLET_SIZE) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  }, []);

  return (
    <div className="flex flex-col tablet:gap-6 mobile:gap-5">
      {isTitle && <h2 className="font-bold tablet:text-enomous mobile:text-semihuge">예약 확인</h2>}
      {
        isMobile ?
          <div className='-mx-6 px-6 py-5 bg-trimo-bg flex flex-col gap-4'>
            <div className='bg-white rounded-trimo p-4 flex flex-col gap-1.5'>
              <div className='flex justify-between items-start'>
                <GroupCar
                  modelName={bookingInfo?.carInfo?.vehInfo?.name}
                  className='font-bold capitalize text-big'
                  size={bookingInfo?.carInfo?.vehInfo?.size}
                  type={bookingInfo?.carInfo?.vehInfo?.type}
                />
                {/* <h2 className='font-bold capitalize text-big'>[{groupCar} 그룹] - {bookingInfo.carInfo.vehInfo.size} {bookingInfo.carInfo.vehInfo.type !== 'car' ? bookingInfo.carInfo.vehInfo.type : ''}</h2>*/}
                <img alt="car-image" src={VENDOR_LOGOS[bookingInfo?.carInfo?.vehInfo?.company]} className="object-scale-down w-11 h-5" />
              </div>
              <NameCar
                modelName={bookingInfo?.carInfo?.vehInfo?.name}
                className='text-small font-light'/>
              {/* <p className='lowercase first-line:capitalize text-small font-light'><span>{nameCar} 또는 동급차량</span></p>*/}
              <p className="text-small font-light text-neutral-300">
                {
                  bookingInfo?.carInfo?.services?.filter((service) => service?.info && service?.info !== 'undefined')?.map((service) => `${Number(service?.info) ? `${t(service?.shortName).includes('${num}') ? '' : service?.info}` : t(service.info)} ${t(service?.shortName).replace('${num}', service?.info)}`).join(' / ')
                }
              </p>
            </div>
            <div className='bg-white rounded-trimo p-4 flex flex-col gap-4'>
              {
                bookingInfo?.res_id &&
                <div className='flex flex-col gap-3'>
                  <div className='text-big font-bold'>예약 번호</div>
                  <div className='text-default font-light'>
                    {bookingInfo?.res_id}
                  </div>
                </div>
              }
              <div className='flex flex-col gap-3'>
                <div className='text-big font-bold'>{t('rentaled')} / {t('return')} {t('location1')}</div>
                <div className='text-default font-light'>
                  {t(`location_name.${bookingInfo?.carInfo?.coreInfo?.pickup?.code}`)} / {t(`location_name.${bookingInfo?.carInfo?.coreInfo?.return?.code}`)}
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='text-big font-bold'>{t('rentaled')} / {t('return')} {t('schedule')}</div>
                <div className='text-default font-light'>
                  <span>
                    {`${format(new Date(bookingInfo?.carInfo?.coreInfo?.pickup?.time), 'y.MM.dd HH:mm')} ~ ${format(new Date(bookingInfo?.carInfo?.coreInfo?.return?.time), 'MM.dd HH:mm')}`}
                  </span>
                  <span> ({calculateHours(
                    new Date(bookingInfo?.carInfo?.coreInfo?.return?.time),
                    new Date(bookingInfo?.carInfo?.coreInfo?.pickup?.time),
                  )} {t('hourUnit')})</span>
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='text-big font-bold'>{t('insuranceDetails')}</div>
                <div className='text-default font-light'>
                  {t('insurance_name.'+ bookingInfo?.carInfo?.insurances.find((item) => item.package === pkgSelected)?.package)}
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='text-big font-bold'>{t('moreOptions')}</div>
                <div className='text-default font-light'>
                  {
                    bookingInfo?.carInfo?.equipsCharge.length > 0 ?
                      <span className='flex flex-wrap'>
                        {
                          bookingInfo?.carInfo?.equipsCharge.map((equip) => {
                            return `${t(`equipment_${equip.code}`)} (${equip.quantity})`;
                          }).join(' / ')
                        }
                      </span> :
                      <span>{t('none')}</span>
                  }
                </div>
              </div>
            </div>
          </div> :
          <div className='bg-primary-white rounded-trimo shadow-trimodropdown tablet:p-[30px] mobile:p-4 text-primary-gray'>
            <div className='flex justify-between items-center'>
              <GroupCar
                modelName={bookingInfo?.carInfo?.vehInfo?.name}
                className='font-bold capitalize table:text-enomous mobile:text-semilarge'
                size={bookingInfo?.carInfo?.vehInfo?.size}
                type={bookingInfo?.carInfo?.vehInfo?.type}
              />
              {/* <h2 className='font-bold capitalize table:text-enomous mobile:text-semilarge'>[{groupCar} 그룹] - {bookingInfo.carInfo.vehInfo.size} {bookingInfo.carInfo.vehInfo.type !== 'car' ? bookingInfo.carInfo.vehInfo.type : ''}</h2>*/}
              <img alt="car-image" src={VENDOR_LOGOS[bookingInfo?.carInfo?.vehInfo?.company]} className="object-scale-down tablet:h-[26px] tablet:w-[72px] mobile:w-[50px]" />
            </div>
            <div className='flex font-light text-default mt-1.5 mb-3'>
              {/* <span className='text-default'>{bookingInfo.carInfo.vehInfo.model.slice(0, 1)} Group - </span> */}
              <NameCar
                modelName={bookingInfo?.carInfo?.vehInfo?.name}
                className='text-default'/>
              {/* <span className='lowercase first-line:capitalize text-default'><span>{nameCar} 또는 동급차량</span></span>*/}
            </div>
            <p className="mt-2 tablet:text-semilarge mobile:text-small font-normal text-neutral-300">
              {
                bookingInfo?.carInfo?.services?.filter((service) => service?.info && service?.info !== 'undefined')?.map((service) => `${Number(service?.info) ? `${t(service?.shortName).includes('${num}') ? '' : service?.info}` : t(service.info)} ${t(service.shortName).replace('${num}', service?.info)}`).join(' / ')
              }
            </p>
            <div className='tablet:mt-[30px] mobile:mt-6 flex flex-col tablet:gap-6 mobile:gap-4'>
              <div className='flex tablet:justify-between tablet:items-center tablet:gap-[50px]'>
                <div className='tablet:basis-1/5 mobile:basis-1/2 tablet:text-semihuge mobile:text-big font-bold'>{t('rentaled')} / {t('return')} {t('location1')}</div>
                <div className='grow text-left tablet:text-big mobile:text-default'>
                  {t(`location_name.${bookingInfo?.carInfo?.coreInfo?.pickup?.code}`)} /{isTablet && <br/>} {t(`location_name.${bookingInfo?.carInfo?.coreInfo?.return?.code}`)}
                </div>
              </div>
              <div className='flex tablet:justify-between tablet:items-center tablet:gap-[50px]'>
                <div className='tablet:basis-1/5 mobile:basis-1/2 tablet:text-semihuge mobile:text-big font-bold'>{t('rentaled')} / {t('return')} {t('schedule')}</div>
                <div className='grow text-left tablet:text-big mobile:text-default'>
                  <span>
                    {`${format(new Date(bookingInfo?.carInfo?.coreInfo?.pickup.time), 'y.MM.dd HH:mm')} ~ ${isTablet ? <br/> : ''} ${format(new Date(bookingInfo?.carInfo?.coreInfo?.return?.time), 'MM.dd HH:mm')}`}
                  </span>
                  <span> ({calculateHours(
                    new Date(bookingInfo?.carInfo?.coreInfo?.return?.time),
                    new Date(bookingInfo?.carInfo?.coreInfo?.pickup?.time),
                  )} {t('hourUnit')})</span>
                </div>
              </div>
              <div className='flex tablet:justify-between tablet:items-center tablet:gap-[50px]'>
                <div className='tablet:basis-1/5 mobile:basis-1/2 tablet:text-semihuge mobile:text-big font-bold'>{t('insuranceDetails')}</div>
                <div className='grow text-left tablet:text-big mobile:text-default flex flex-row items-center'>
                  <p>
                    {pkgSelected ? t(`insurance_name.${pkgSelected}`) : '기본 할인요금' }
                  </p>
                </div>
              </div>
              <div className='flex tablet:justify-between tablet:items-center tablet:gap-[50px]'>
                <div className='tablet:basis-1/5 mobile:basis-1/2 tablet:text-semihuge mobile:text-big font-bold'>{t('moreOptions')}</div>
                <div className='tablet:grow mobile:basis-1/2 text-left tablet:text-big mobile:text-default'>
                  {
                    bookingInfo?.carInfo?.equipsCharge.length > 0 ?
                      <span className='flex flex-wrap'>
                        {
                          bookingInfo?.carInfo?.equipsCharge.map((equip) => {
                            return `${t(`equipment_${equip.code}`)} (${equip.quantity})`;
                          }).join(' / ')
                        }
                      </span> :
                      <span>{t('none')}</span>
                  }
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
};

ReservationConfirm.propTypes = {
  bookingInfo: PropTypes.object.isRequired,
  pkgSelected: PropTypes.string,
  isTitle: PropTypes.bool,
};

export default ReservationConfirm;
