import { action, makeObservable } from 'mobx';

import { CarListingSearchBoxInputViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.InputViewmodel';
import { BaseSearchBoxStepManager, SearchBoxSteps } from '^/domainComponents/forms/CarListingSearchBox/SearchBoxStepManager';

import { HomePage2RouteParams } from './HomePage2RouteParams';

export class HomeSearchBoxStepManagerDesktop
  extends BaseSearchBoxStepManager<HomePage2RouteParams> {
  constructor(
    public routeParams: HomePage2RouteParams,
    public carListingSearchInputVM: CarListingSearchBoxInputViewModel,
    public steps: SearchBoxSteps[] = [],
  ) {
    super();
    makeObservable(this, {
      updatePushCountBy: true,
      steps: true,
      getInputViewModel: true,
      getSteps: true,
      pushStepsToRoute: action.bound,
      replaceStepsInRoute: action.bound,
      updateRouteParams: action.bound,
      guardStep: action.bound,
      pushStep: action.bound,
      clearStep: action.bound,
      replaceStep: action.bound,
      currentStep: true,
      routeParams: true,
      carListingSearchInputVM: true,
    });
  }

  getInputViewModel(): CarListingSearchBoxInputViewModel {
    return this.carListingSearchInputVM;
  }

  getSteps() {
    return this.steps || [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  updateRouteParams(routeParams: HomePage2RouteParams) {

    // this.routeParams = routeParams;
  }

  pushStepsToRoute(newSteps: SearchBoxSteps[]) {
    this.steps = [...newSteps];
  }

  replaceStepsInRoute(newSteps: SearchBoxSteps[]) {
    this.steps = [...newSteps];
  }

  // eslint-disable-next-line class-methods-use-this
  updatePushCountBy(): void {
    // do nothing
  }
}
