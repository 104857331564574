import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { RentalCarOfficeLocationType } from '^/AppContext/RentalOfficeLocationRepo';
import { CalendarRoot } from '^/components/calendar2/Calendar.root';
import { CalendarStateViewModel } from '^/components/calendar2/Calendar.state.viewmodel';
import {
  CalendarListViewModel,
} from '^/components/calendar2/List/Calendar.List.viewmodel';
import { borders } from '^/constant/CommonStyles';
import { SPACING } from '^/constant/CommonStyles2';
import { CarListingSearchBoxInputViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.InputViewmodel';
import type { CarListingSearchBoxViewModel } from '^/domainComponents/forms/CarListingSearchBox/CarListingSearchBox.viewmodel';
import { ScheduleTimeViewModel } from '^/domainComponents/forms/CarListingSearchBox/ScheduleTimeViewModel';
import {
  BaseSearchBoxStepManager,
} from '^/domainComponents/forms/CarListingSearchBox/SearchBoxStepManager';
import { OpenCloseTimes } from '^/types/RawOpenHours';
import { MobxInputValue } from '^/util/MobxInputValue';

import { CalendarWithTimeChoice } from './CalendarWithTimeChoice';

const classNames = {
  root: cx(
    css`
      display: grid;
      gap: ${SPACING.spacing24};
      grid-template-columns: 2fr 1fr;
    `,
    // borders.DEBUG_blueBorderDashed,
  ),
  flexWrap: css`
    display: flex;
    flex-wrap: wrap;
  `,
};

export const CalendarWithTimeDesktop: React.FC<{
  hasDifferentPickupReturnLocations: CarListingSearchBoxInputViewModel['hasDifferentPickupReturnLocations'],
  pickupLocation: RentalCarOfficeLocationType,
  returnLocation: RentalCarOfficeLocationType,
  stepManager: BaseSearchBoxStepManager,
  calendarStateViewModel: CalendarStateViewModel,
  calendarListViewModel: CalendarListViewModel,
  scheduleTimeViewModel: ScheduleTimeViewModel,
  onBlur?: () => void,
  onAllDateSelected(args: {
    startDate: Date,
    endDate: Date,
  }): void,
  onSubmit: () => void,
  isMobile: boolean,
}> = observer(function CalendarWithTime(p) {
  const isCalendarWidthTimeMaxSize = useMediaQuery({ query: '(max-width: 1240px)' });
  return (
    <div
      className={cx(
        classNames.root,
        isCalendarWidthTimeMaxSize && classNames.flexWrap,
      )}
    >
      <CalendarRoot
        hasDifferentPickupReturnLocations={p.hasDifferentPickupReturnLocations}
        calendarListViewModel={p.calendarListViewModel}
        calendarStateViewModel={p.calendarStateViewModel}
        pickupLocation={p.pickupLocation}
        returnLocation={p.returnLocation}
        isMobile={p.isMobile}
        isCalendarWidthTimeMaxSize={isCalendarWidthTimeMaxSize}
      />
      <CalendarWithTimeChoice
        onAllDateSelected={p.onAllDateSelected}
        calendarListViewModel={p.calendarListViewModel}
        calendarStateViewModel={p.calendarStateViewModel}
        stepManager={p.stepManager}
        scheduleTimeViewModel={p.scheduleTimeViewModel}
        pickupOpenCloseTimes={p.pickupLocation.openCloseTimes}
        returnOpenCloseTimes={p.returnLocation.openCloseTimes}
        onSubmit={p.onSubmit}
      />
    </div>
  );
});
