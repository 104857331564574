import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { borders } from '^/constant/CommonStyles';
import {
  COLORS2, SPACING, font, INPUT,
} from '^/constant/CommonStyles2';

import { ArrowDwon } from '../../../../commonComponents/ui/ArrowDown';
import { CarListingSearchBoxViewModel } from '../CarListingSearchBox.viewmodel';
import { classNames } from '../components/CarListingSearchBox.contentsArea.style';

export const RentalOfficeInputButton: React.FC<{
  viewmodel: CarListingSearchBoxViewModel['pickupLocation']
  isSelected: boolean
  placeholder?: string;
}> = observer(function RentalOfficeInputButton({
  viewmodel,
  isSelected,
  placeholder,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div
      // ref={elemRef}
      className={cx(
        classNames.common.searchBoxInputWrapper,
        classNames.common.searchBoxInputs,
        css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          background-color: ${COLORS2.sub.MrtGray3};

          padding-left: ${SPACING.spacing16};
          padding-right: ${SPACING.spacing16};
        `,
        isSelected && css`
          border: 1px solid ${COLORS2.main.MrtBlue1};
          background-color: ${COLORS2.main.MrtWhite};
        `,
        isMobile && css`
          padding-left: 12px;
          padding-right: 12px;
        `,
        // borders.DEBUG_greenBorderDashed,
      )}
    >
      {/* <h1>{JSON.stringify(viewmodel.pickupLocationViewModel, null, 2)}</h1> */}
      <div
        className={cx(
        // classNames.common.searchBoxInputWrapper,
          font.Body1_Bold,
          css`
          `,
          viewmodel
            ?.locationDescriptions
            .get('ko')
            ?.description
            ? css`
              color: ${COLORS2.main.MrtBlack};
            `
            : css`
              color: ${COLORS2.sub.MrtGray1};
            `,
          INPUT.overflowEllipsis,
        )}
      >
        { viewmodel
          ?.locationDescriptions
          .get('ko')
          ?.description
            || placeholder
            || '대여/반납 장소 선택'}
      </div>

      {!isMobile && (
        <ArrowDwon
          color={isSelected ? 'black' : 'gray'}
        />
      )}
    </div>
  );
});
