import { IterableQuery } from 'itiriri';

export function isDefined<T>(t: T | undefined | null): t is T {
  return t !== undefined && t !== null;
}

export function filterNotDefineds<T>(
  iterableQuery: IterableQuery<T | null | undefined>,
) {
  return iterableQuery.filter(isDefined) as IterableQuery<T>;
}
