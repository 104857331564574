import { css, cx } from '@emotion/css';

import { COLORS2, font, SPACING } from '^/constant/CommonStyles2';

export const classNames = {
  common: {
    searchBoxInputWrapper: css`
      display: flex;
      flex-direction: column;
      align-items: space-between;

      flex: 1 1 0px;

      /* background-color: ${COLORS2.sub.MrtGray3}; */
      color: ${COLORS2.sub.MrtGray1};
      /* padding: ${SPACING.spacing16} 0; */
      border-radius: 5px;
      /* margin-top: 12px; */
      &:hover  {
        cursor: default;
      }
    `,
    searchBoxInputTitle: cx(
      css`
      `,
      font.Caption1_Light,
    ),
    searchBoxInputs: css`
      height: 56px;
      display: flex;
      align-items: center;

      box-sizing: border-box;
      border-radius: 5px;
      padding: ${SPACING.spacing16};
    `,
  },
};
