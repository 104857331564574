/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import {
  ReactComponent as CalendarSVG,
} from '^/assets/ic_calendar_24px.svg';
import { borders } from '^/constant/CommonStyles';
import { COLORS2, font, SPACING } from '^/constant/CommonStyles2';

import { CarListingSearchBoxViewModel } from './CarListingSearchBox.viewmodel';
import { CarListingSearchBoxDesktopContentsArea } from './components/CarListingSearchBox.desktop.contentsArea';
import { BirthDayInput } from './headerArea/BirthDayInput';
import { CitySearchInputButton } from './headerArea/CitySearchInputButton';
import { RentalOfficeInputButton } from './headerArea/RentalOfficeInputButton';
import { ScheduleInputButton } from './headerArea/ScheduleInputButton';
import { SearchBoxSteps } from './SearchBoxStepManager';

export const PickupReturnSameInputsDesktop: React.FC<{
  viewmodel: CarListingSearchBoxViewModel;
  smaller?: boolean;
}> = observer(function PickupReturnSameInputsDesktop({
  viewmodel,
  smaller,
}) {
  const {
    stepManager,
  } = viewmodel;

  const pickupCityInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (stepManager.currentStep?.type === '1_rental_city'
      && stepManager.currentStep.autofocus === 'CitySearchInput'
    ) {
      pickupCityInputRef.current?.focus();
    }
  }, [
    stepManager.currentStep?.type,
  ]);

  const stepManagerStepChangeOptions = {
    afterStepChange(newStep: SearchBoxSteps) {
      if (newStep.type === '1_rental_city') {
        viewmodel.i.pickupCitySearch.searchInput
          .setIsFocused(true);
        pickupCityInputRef.current?.focus();
      }
    },
  } as const;

  return (
    <form
      className={cx(
        css`
          &:focus-visible {
              outline: none;
          }
        `,
        smaller ? css`
          padding: ${SPACING.spacing16} ${SPACING.spacing24};
        ` : css`
          padding: ${SPACING.spacing24};
        `,
        // borders.DEBUG_blueBorderDashed,
      )}
      onSubmit={(e) => {
        if (viewmodel.submitActionVM.status === 'success') {
          viewmodel.submitActionVM.value.submit();
          if (document.activeElement
            && document.activeElement instanceof HTMLElement
          ) {
            document.activeElement.blur();
          }
        }
        e.preventDefault();
        return false;
      }}
    >
      {/* <pre>
        {JSON.stringify(stepManager.getSteps(), null, 2)}
      </pre> */}
      <div
        className={css`
          /* display: flex; */
          display: grid;
          grid-template-columns: minmax(0, 272px) minmax(0, 272px) minmax(0, 326px) minmax(0, 232px) 56px;
          justify-content: space-between;
          align-items: end;
          gap: 24px;
        `}
      >
        {/* 도시검색 */}
        <div>
          <CitySearchInputButton
            placeholder="대여/반납 도시 선택"
            inputRef={pickupCityInputRef}
            viewmodel={viewmodel.i.pickupCitySearch}
            title={smaller ? null : '대여 / 반납 위치'}
            showSuggestions={stepManager.currentStep?.type === '1_rental_city'}
            onSelectCity={() => {
              stepManager
                .replaceStepWithOptions({
                  type: '2_rental_office',
                }, stepManagerStepChangeOptions);
              // alert('SameRentalReturnInputs.desktop CitySearchInputButton!');
            }}
            onClickCitySearchInputBtn={() => {
              pickupCityInputRef.current?.focus();

              if (stepManager.currentStep?.type !== '1_rental_city') {
                stepManager
                  .replaceStepWithOptions({
                    type: '1_rental_city',
                    autofocus: 'CitySearchInput',
                  }, stepManagerStepChangeOptions);
              }
            }}
          />
        </div>

        {/* 대여/반납 장소 선택 */}
        <div
          onClick={() => {
            stepManager.replaceStepWithOptions({
              type: '2_rental_office',
            }, stepManagerStepChangeOptions);
          }}
        >
          <RentalOfficeInputButton
            viewmodel={viewmodel.pickupLocation}
            isSelected={stepManager.currentStep?.type === '2_rental_office'}
          />
        </div>
        <div>
          <ScheduleInputButton
            viewmodel={viewmodel}
            title={smaller ? null : undefined}
            onClick={() => {
              stepManager.replaceStepWithOptions({
                type: '3_schedule',
              }, stepManagerStepChangeOptions);
            }}
            isSelected={stepManager.currentStep?.type === '3_schedule'}
          />
          {
              viewmodel.i.calendarStateViewModel.selection.end
              && !smaller
              && viewmodel.rentalPeriodText
              && (
              <div className={cx(
                font.Body1_Regular,
                css`
                  position: absolute;
                  display: flex;
                  gap: 4px;
                  top: ${SPACING.spacing120};
              `)}
              >
                <CalendarSVG />
                {/* FIXME: apply time */}
                <span>
                  {viewmodel.rentalPeriodText}
                </span>
              </div>
              )
            }
        </div>

        <div>
          <BirthDayInput
            viewmodel={viewmodel}
            title={smaller ? null : undefined}
            onClickBirthDayInput={() => {
              stepManager.replaceStepWithOptions({
                type: '4_birthday',
              }, stepManagerStepChangeOptions);
            }}
          />
        </div>

        {/* 검색 버튼 */}
        <input
          type="image"
          alt="차량 조회"
          src="./ic_search_white.svg"
          disabled={viewmodel.submitActionVM.status !== 'success'}
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;

            border-radius: 5px;
            background-color: ${COLORS2.main.MrtBlue1};
            /* margin-bottom: ${SPACING.spacing16}; */
            width: ${SPACING.spacing56};
            height: ${SPACING.spacing56};
            padding: ${SPACING.spacing16};

            &:disabled {
              background-color: ${COLORS2.main.MrtBlue2};
              cursor: not-allowed;
            }
          `}
        />
      </div>
      {/* 대여/반납 장소가 다릅니다. */}
      {(!smaller || null) && (
        <div
          className={css`
            display: flex;
            gap: ${SPACING.spacing8};

            margin-top: 12px;
            width: fit-content;

            &:hover {
              cursor: pointer;
            }
          `}
          onClick={() => {
            viewmodel
              .i
              .hasDifferentPickupReturnLocations
              .set(!viewmodel.i.hasDifferentPickupReturnLocations.value);
            stepManager
              .replaceStepWithOptions({
                type: '5_return_city',
              }, stepManagerStepChangeOptions);
            // FIXME: calendar reset as requested...(?????)
            //          better: do validation...
            viewmodel
              .i
              .scheduleTimeViewModel
              .reset();
            viewmodel
              .i
              .calendarStateViewModel
              .reset();
          }}
        >
          <img
            alt="rental-location-different"
            src={viewmodel.i.hasDifferentPickupReturnLocations.value
              ? 'ic_checkbox/ic_checkbox_a2.svg'
              : 'ic_checkbox/ic_checkbox_n2.svg'}
          />
          대여/반납 장소가 다릅니다.
        </div>
      )}
      <div
        className={css`
          z-index: -1;
          position: absolute;
          top: calc(100% - 10px);
          left: 0;
          padding-left: 24px;
          padding-right: 24px;
          padding-bottom: 24px;
          width: 100%;
          background-color: ${COLORS2.main.MrtWhite};
          /* background-color: yellow; */

          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        `}
      >
        <CarListingSearchBoxDesktopContentsArea
          viewmodel={viewmodel}
        />
      </div>
    </form>
  );
});
