/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import '../WrapperPopup/index.scss';
import { borders } from '^/constant/CommonStyles';

import { css } from '@emotion/css';

const TermWrapper: React.FC<{
  title: string,
  content: React.ReactNode,
  open?: boolean,
  classNames: string,
  onCloseModal: () => void,
  loading?: boolean,
  canCloseByClickOutSide?: boolean,
}> = ({
  title, content, open, classNames, onCloseModal, loading,
  canCloseByClickOutSide = true,
}) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div" className="relative mobile:z-[60] tablet:z-40"
          onClose={() => {
            if (canCloseByClickOutSide) onCloseModal();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black bg-opacity-25"
              onClick={() => {
                if (canCloseByClickOutSide) {
                  onCloseModal();
                }
              }}
            />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`relative overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all${classNames}`}
                >
                  {
                    loading && (
                    <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-white opacity-50">
                      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    )
                  }

                  <div className="relative border-b border-neutral-100 py-[20px] text-center font-bold text-primary-gray">
                    {/* <h3 className='text-semilarge'>{title}</h3> */}
                    <Dialog.Title>{title}</Dialog.Title>
                    <img
                      onClick={onCloseModal}
                      src="/ic_x_black/ic_x_black_32.svg"
                      className="absolute top-2/4 left-6 h-8 w-8 -translate-y-2/4 hover:cursor-pointer"
                    />
                  </div>
                  <div
                    id="TermsWrapper::ContentContainer"
                    className={[
                      'hide-scrollbar mobile:transition-height-[70vh] overflow-y-auto px-6 pt-6',
                      css`
                        height: calc(100% - 70px);
                      `,
                    ].join(' ')}
                  >
                    {content}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default TermWrapper;
