/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import { css } from '@emotion/css';
import { Disclosure, Tab, Transition } from '@headlessui/react';

import { borders } from '^/constant/CommonStyles';

import FullScreenWrapper from '../../components/FullScreen/FullScreenWrapper';
import { classNames } from '../../util';

import { FaqContentSearchMobile } from './FAQ.ContentSearch.Mobile';

export const FaqMobile: React.FC<{
  selectedArticle: any;
  t: any;
  faqContents: any;
  setSelectedArticle: any;
  listArticleRef: any;
  item: any;
  isOpen: any;
  setItem: any;
  handleOpenDisclosure: any;
  setIsOpen: any;
  handleSearchByKeyword: any;
  resultTitleQuestionMobile: any;
  handleOpenDisclosureMobile: any;
  listQuesRef: any;
  setDefaultOpenQuestion: any;
  allQuestion: any;
  defaultOpenQuestion: any;
}> = ({
  selectedArticle,
  t,
  faqContents,
  setSelectedArticle,
  listArticleRef,
  isOpen,
  setIsOpen,
  handleSearchByKeyword,
  resultTitleQuestionMobile,
  handleOpenDisclosureMobile,
  listQuesRef,
  setDefaultOpenQuestion,
  allQuestion,
  defaultOpenQuestion,
}) => {
  return (
    <div
      className={[
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      <Tab.Group
        as="div" className="" selectedIndex={selectedArticle}
        onChange={setSelectedArticle}
      >
        <div
          className={[
            // borders.DEBUG_blueBorderDashed,
            css`
              width: 100%;
              padding: 0 24px;
              
              position: sticky;
              top: 0;
              z-index: 30;

              background-color: #fff;
            `,
          ].join(' ')}
        >
          <div
            className={[
              'flex items-center justify-between',
              // borders.DEBUG_goldDashed,
              css`
                padding:20px 0;
              `,
            ].join(' ')}
          >
            <h1
              className="
                text-semihuge font-bold leading-10 tracking-big
              "
            >
              {t('serviceCenter')}
            </h1>
            <button
              type="button"
              onClick={() => setIsOpen(true)}
            >
              <img alt="search-logo" src="./ic_search.svg" className="h-6 w-6" />
            </button>
          </div>

          <Tab.List
            as="div"
            className={[
              'hide-scrollbar gap-x-9  overflow-y-scroll',
              // borders.DEBUG_pinkDashed,
              css`
                display: flex;
              `,
            ].join(' ')}
          >
            <Tab className={({ selected }) => classNames(
              'text-primary-gray pt-0 pb-1.5 rounded-none font-bold outline-none min-w-max',
              selected
                ? 'border-b border-primary-skyblue font-bold'
                : 'font-light border-transparent',
            )}
            >
              {t('all')}
            </Tab>
            {faqContents && Object.keys(faqContents)?.map((category, index) => (
              <Tab
                key={index}
                className={({ selected }) => classNames(
                  'text-primary-gray pt-0 pb-[13px] rounded-none text-big font-bold outline-none min-w-max',
                  selected
                    ? 'border-b border-primary-skyblue font-bold'
                    : 'font-light border-transparent',
                )}
                // eslint-disable-next-line no-param-reassign
                ref={(ref) => { listArticleRef.current[category] = ref; }}
              >
                {category}
              </Tab>
            ))}
          </Tab.List>

          <hr className="-mx-6 h-px bg-secondary-gray" />

        </div>
        {
          (!isOpen && (
          <Tab.Panels
            as="div"
            className={[
              'flex grow flex-col',
              // borders.DEBUG_greenBorderDashed,
              css`
                padding: 0 24px 50px 24px;
              `,
            ].join(' ')}
          >
            <div className={[
              'hide-scrollbar',

            ].join(' ')}
            >
              <Tab.Panel>
                {
                allQuestion?.map((questionItem, index) => (
                  <Disclosure
                    key={`question-all-${index}`}
                    defaultOpen={defaultOpenQuestion.includes(questionItem.question)}
                  >
                    {({ open }) => (
                      <div className="grow">
                        <Disclosure.Button
                          onClick={() => {
                            // If in mobile, click to close this question, update in default list
                            const questionRef = listQuesRef.current[`${questionItem.question}`];
                            if (questionRef && questionRef.getAttribute('aria-expanded') === 'true') {
                              setDefaultOpenQuestion((questions) => questions.filter((qu) => qu !== questionItem.question));
                            }
                          }}
                          className={[
                            'flex w-full items-center justify-between  text-big py-[20px] tracking-default',
                            // borders.DEBUG_purpleBorderDashed,
                          ].join(' ')}
                          ref={(ref) => { listQuesRef.current[`${questionItem.question}`] = ref; }}
                        >
                          <span className="text-start text-default">
                            {questionItem.question}
                          </span>

                          <img alt="" src={open ? './ic_arrow_up.png' : './ic_arrow_down.png'} className="h-6 w-6" />
                        </Disclosure.Button>

                        <Transition
                          show={open}
                          enter="transition-all duration-300 ease-out max-h-0"
                          enterFrom="transform opacity-0 max-h-0"
                          enterTo="transform opacity-100 max-h-[2000px]"
                          leave="transition-all duration-300 ease-out"
                          leaveFrom="transform max-h-[2000px] opacity-1"
                          leaveTo="transform max-h-0 opacity-0"
                        >
                          <Disclosure.Panel
                            static
                            className={[
                              ' bg-trimo-shadow px-6 -mx-6 w-auto py-[20px] text-default font-light leading-6',
                              // borders.DEBUG_greenBorderDashed,
                            ].join(' ')}
                          >
                            {/* {item.content} */}
                            {
                              Array.isArray(questionItem?.answer)
                                ? (
                                  <>
                                    {
                                    questionItem.answer?.map((ans, idx) => (
                                      <p key={`ans-${idx}`}>{ans}</p>
                                    ))
                                  }
                                  </>
                                )
                                : (
                                  <>
                                    {
                                    Object.keys(questionItem.answer)?.map((ans, idx) => {
                                      if (ans === 'title') return <p key={`ans-${idx}`}>{questionItem.answer[ans]}</p>;
                                      return (
                                        <div key={`ans-${idx}`}>
                                          <p className="font-bold">{ans}</p>
                                          {
                                            questionItem.answer[ans]?.map((content) => (<p key={`content-${index}`}>{content}</p>))
                                          }
                                        </div>
                                      );
                                    },
                                    )
                                  }
                                  </>
                                )
                            }
                          </Disclosure.Panel>
                        </Transition>

                        <hr className="-mx-6 h-px bg-secondary-gray" />
                      </div>
                    )}
                  </Disclosure>
                ))
              }
              </Tab.Panel>
              {
              faqContents && Object.values(faqContents)?.map((questions: any, idx) => (
                <Tab.Panel
                  key={`questions-${idx}`}
                  as="div"
                  className={[
                    'flex flex-col gap-0',
                    // borders.DEBUG_blueBorderDashed,
                  ].join(' ')}

                >
                  {
                    questions?.map((questionItem, index) => (
                      <Disclosure key={`question-${index}`} defaultOpen={defaultOpenQuestion.includes(questionItem.question)}>
                        {({ open }) => (
                          <div
                            className={[
                              'grow',
                              // borders.DEBUG_purpleBorderDashed,
                            ].join(' ')}
                          >
                            <h1>{open}</h1>
                            <Disclosure.Button
                              onClick={() => {
                                // If in mobile, click to close this question, update in default list
                                const questionRef = listQuesRef.current[`${questionItem.question}`];
                                if (questionRef && questionRef.getAttribute('aria-expanded') === 'true') {
                                  setDefaultOpenQuestion((questions2) => questions2.filter((qu) => qu !== questionItem.question));
                                }
                              }}
                              className="
                                flex w-full items-center justify-between py-[20px] px-0 text-big tracking-default
                              "
                            >
                              <span className="text-start text-default">
                                {questionItem.question}
                              </span>
                              <img src={open ? './ic_arrow_up.png' : './ic_arrow_down.png'} className="h-6 w-6" />
                            </Disclosure.Button>

                            <Transition
                              show={open}
                              enter="transition-all duration-300 ease-out max-h-0"
                              enterFrom="transform opacity-0 max-h-0"
                              enterTo="transform opacity-100 max-h-[2000px]"
                              leave="transition-all duration-300 ease-out"
                              leaveFrom="transform max-h-[2000px] opacity-1"
                              leaveTo="transform max-h-0 opacity-0"
                            >
                              <Disclosure.Panel
                                static
                                className={[
                                  '-mx-6 px-6 bg-trimo-shadow py-[20px] text-default font-light leading-6',
                                  // borders.DEBUG_blueBorderDashed,
                                ].join(' ')}
                              >
                                {/* {item.content} */}
                                {
                                  Array.isArray(questionItem?.answer)
                                    ? (
                                      <>
                                        {
                                        questionItem.answer?.map((ans, idx2) => (
                                          <p key={`ans-${idx2}`}>{ans}</p>
                                        ))
                                      }
                                      </>
                                    )
                                    : (
                                      <>
                                        {
                                        Object.keys(questionItem.answer)?.map((ans, idx3) => {
                                          if (ans === 'title') return <p key={`ans-${idx3}`}>{questionItem.answer[ans]}</p>;
                                          return (
                                            <div key={`ans-${idx3}`}>
                                              <p className="font-bold">{ans}</p>
                                              {
                                                questionItem.answer[ans]?.map((content, index4) => (<p key={`content-${index4}`}>{content}</p>))
                                              }
                                            </div>
                                          );
                                        },
                                        )
                                      }
                                      </>
                                    )
                                }
                              </Disclosure.Panel>
                            </Transition>
                            <hr className="-mx-6 h-px bg-secondary-gray" />
                          </div>
                        )}
                      </Disclosure>
                    ))
                  }
                </Tab.Panel>
              ))
            }
            </div>
          </Tab.Panels>
          ))
        }
      </Tab.Group>
      <FullScreenWrapper
        isShow={isOpen}
        leftIcon={(
          <img
            alt="" onClick={() => setIsOpen(false)} src="/ic_x_black/ic_x_black_32.svg"
            className="h-8 w-8 hover:cursor-pointer"
          />
)}
      >
        <FaqContentSearchMobile
          handleSearchQuestion={handleSearchByKeyword}
          listQuestion={resultTitleQuestionMobile}
          handleSelectDisclosure={handleOpenDisclosureMobile}
        />
      </FullScreenWrapper>
    </div>
  );
};
