import { makeAutoObservable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AppContext } from '^/AppContext/AppContext';
import { PaymentTypes } from '^/types/__BrandedTypes';
import { ExtractPromiseSuccess } from '^/types/__ResultType';
import { createKey } from '^/util';

import { checkEquips } from '../../api/Search';

import { ListingPage2RouteParams } from './ListingPage2RouteParams';

type CarListItemType = ExtractPromiseSuccess<ReturnType<AppContext['getCarListCore']['getList']>>[number];

export class ListingPage2CardViewModel {
  constructor(
    public appContext: AppContext,
    public data: CarListItemType,
    public routeParams: ListingPage2RouteParams,
  ) {
    makeAutoObservable(this);
  }

  gotoBooking(
    paymentType: PaymentTypes,
    // FIXME: remove after moving booking page to type-router
    navigate: NavigateFunction,
  ) {
    const item = this.data;
    const payment = item[paymentType];
    if (!payment) {
      alert(`No Payment ${paymentType} for ${this.data.core.id}`);
      console.error(
        'No payment ',
        paymentType,
        ' for: ',
        this.data);
      throw new Error(`No payment ${paymentType} for ${this.data.core.id}`);
    }
    const routeParams = {
      ...this.routeParams,
      rLocationCode: this.routeParams.rLocationCode
      || this.routeParams.pLocationCode,
    };
    const key = createKey([
      item.core.company.id,
      item.core.destination,
      routeParams.pLocationCode,
      routeParams.rLocationCode,
      routeParams.pDatetime,
      routeParams.rDatetime,
      item.core.modelCode,
      item.core.codeContext,
    ]);
    checkEquips({
      key,
      vendor: item.core.company.id,
      pickup_location: routeParams.pLocationCode,
      return_location: routeParams.rLocationCode,
      pickup_datetime: routeParams.pDatetime,
      return_datetime: routeParams.rDatetime,
      birth_day: routeParams.birthday,
      car_code: item.core.modelCode,
      code_context_car_code: item.core.codeContext,
      price_code: payment.payment.price_code,
    });
    window.scrollTo(0, 0);

    const routeState = {
      carInfo: {
        key,
        vendor: item.core.company.id,
        rentingInfo: item.core.rentingInfo,
        destination: item.core.destination,
        modelCode: item.core.modelCode,
        codeContext: item.core.codeContext,
        paymentType,
        coupon: payment.payment.coupon,
        currency: payment.payment.foreignCurrencyCode,
        package: payment.payment.price_code,
      },
      listingInfo: {
        ...routeParams,
        retry: 0,
      },
      loading: true,
    };

    sessionStorage.setItem('LOCATION_STATE', JSON.stringify(
      routeState,
    ));
    navigate(`/booking?id=${item.core.id}`, {
      state: {
        carInfo: {
          key,
          vendor: item.core.company.id,
          rentingInfo: item.core.rentingInfo,
          destination: item.core.destination,
          modelCode: item.core.modelCode,
          codeContext: item.core.codeContext,
          paymentType,
          coupon: payment.payment.coupon,
          currency: payment.payment.foreignCurrencyCode,
          package: payment.payment.price_code,
        },
        listingInfo: {
          ...routeParams,
          retry: 0,
        },
        loading: true,
      },
    });
  }
}
