import { CalendarMonthViewModel } from '../Month/Calendar.Month.viewmdel';
// import { DateRangeViewModel } from './DateRange.viewmodel';
import { addToYearMonth, yearMonthFromDate } from '../YearMonth';

const today = new Date();
export class CalendarListViewModel {
  public readonly monthCalendarViewModels: CalendarMonthViewModel[];

  constructor(
    public readonly numCalendars: number,
    public readonly currentYearMonth = yearMonthFromDate(today),

  ) {
    this.monthCalendarViewModels = [...new Array(numCalendars)]
      .map((_, i) => {
      // return addToYearMonth(this.currentYearMonth, i);
        const yearMonth = addToYearMonth(this.currentYearMonth, i);
        return new CalendarMonthViewModel(
          yearMonth,
        );
      });
  }

  paginatedList = (args: {
    offset: number,
    count: number,
  }) => {
    return this.monthCalendarViewModels
      .slice(args.offset, args.offset + args.count);
  };
}
