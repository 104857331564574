/* eslint-disable max-len */
import { useState } from 'react';

import { css, cx } from '@emotion/css';

import { font, SPACING, COLORS2 } from '^/constant/CommonStyles2';

import { TermsPrivacyPopup } from '../Popup/TermPopup/TermsPrivacy';
import { TermsServicePopup } from '../Popup/TermPopup/TermsService';

import { IMS_LOGO } from './constants';

const classNames = {
  FooterRoot: css`
    display: flex;
    flex-direction: column;

    gap: ${SPACING.spacing32};
    padding: ${SPACING.spacing56} 0;
    background-color: ${COLORS2.sub.MrtGray3};
    padding: ${SPACING.spacing24};
  `,
};
export const Footer3Mobile = () => {
  const [isPrivacyPopUpOpen, setIsPrivacyPopUpOpen] = useState<boolean>(false);
  const [isTermPopUpOpen, setIsTermPopUpOpen] = useState<boolean>(false);

  return (
    <>
      <TermsPrivacyPopup
        open={isPrivacyPopUpOpen}
        setOpen={setIsPrivacyPopUpOpen}
      />
      <TermsServicePopup
        open={isTermPopUpOpen}
        setOpen={setIsTermPopUpOpen}
      />

      <div className={classNames.FooterRoot}>
        <div>
          <div
            className={cx(
              css`
              gap: ${SPACING.spacing24};
            `,
              font.Caption1_Bold,
            )}
          >
            고객센터 운영안내
          </div>
          <div
            className={cx(
              css`
              margin-top: ${SPACING.spacing24};
            `,
              font.Caption2_Light,
            )}
          >
            고객센터 운영안내<br />
            연중무휴 09:00~18:00 (점심시간 12:00~13:00)<br />
            주말/공휴일 포함, 한국시간 기준<br />
            유선상담 1670-8208<br />
            ※그 외 시간은 예약하신 현지 영업소로 연락해주세요.<br />
          </div>
        </div>

        <div>
          <div
            className={cx(
              css`
              display: flex;
              gap: ${SPACING.spacing24};
            `,
              font.Caption1_Bold,
            )}
          >
            <button
              type="button"
              onClick={() => setIsPrivacyPopUpOpen(true)}
            >개인정보 취급방침
            </button>
            <button
              type="button"
              onClick={() => setIsTermPopUpOpen(true)}
            >이용약관
            </button>
          </div>
          <div
            className={
            css`
              display: flex;
              flex-direction: column;
              gap: ${SPACING.spacing16};
            `
            }
          >
            <div
              className={
              css`
                margin-top: 24px;
              `
            }
            >
              <img
                alt="ims-mobility-logo"
                className={
              css`
                width: 110px;
                height: 20px;
              `
            }
                src={IMS_LOGO}
              />
            </div>
            <div
              className={cx(
                css`
                display: flex;
                flex-direction: column;
                gap: ${SPACING.spacing16};
              `,
                font.Caption2_Light,
              )}
            >
              상호명 아이엠에스모빌리티 주식회사 | 대표 조영탁 |<br />
              개인정보보호책임자 조영탁 | 사업자등록번호 280-86-00274 |<br />
              통신판매업신고번호 제 2018-서울광진-0968호<br />
              주소 서울특별시 광진구 아차산로 355, 3층 광진아크로텔<br />
              이메일 info@trimo.ai<br />
            </div>
            <div className={cx(css`
                display: flex;
                flex-direction: column;
            `,
            font.Caption2_Light,
            )}
            >
              아이엠에스모빌리티 주식회사는 통신판매중개자로 통신판매의<br />
              당사자가 아닙니다. 상품의 예약, 이용 및 환불 등 상품·거래정보 및 거래에 대하여 책임을 지지않으며 관련한 의무와 책임은 각 판매자에게 있습니다.<br />
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </>
  );
};
