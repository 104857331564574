import axios, { AxiosInstance } from 'axios';

import { API_KEY, BASE_URL } from '^/constant/envConfig';

// export const api2BaseAxios = defaultAxiosClient.instance;

export const api2BaseAxios: AxiosInstance = axios.create({
  baseURL: BASE_URL,

  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-api-key': API_KEY,
  },
  validateStatus() {
    return true;
  },
});
