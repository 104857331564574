import { css } from '@emotion/css';

import { COLORS } from '^/constant/CommonStyles';

export const DefaultPhoneInputStyle = {
  root: css`
    width: 100%;
  `,
  inputRoot: css`
    position: relative;
    height: 56px;
  `,
  input: css`
    position: absolute;
    left: 0px; top: 0;
    bottom: 0; right: 0;
    padding-left: 90px;
    height: 100%;
    width: 100%;

    border: solid 1px ${COLORS.LIGHT_GREY_0};
    border-radius: 5px;
    /* z-index: 2; */
  `,
} as const;

export type PhoneInputClassNameOverrides =
  typeof DefaultPhoneInputStyle;
