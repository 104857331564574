import { css, cx } from '@emotion/css';
import { useNavigate } from 'react-router';

import { COLORS2, font, SPACING } from '^/constant/CommonStyles2';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { MRT_LOGO, NAVIGATION_MRT } from './constants';

const classNames = {
  headerWrapper: css`
    max-width: 1320px;
    height: 72px;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    /* border-bottom: 1px solid #f4f4f4; */
  `,
  navButtonWrapper: cx(css`
    display: flex;
    gap: ${SPACING.spacing16};
  `,
  font.Body1_Bold,
  ),
  navButton: css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 20px;
    height: 36px;

    &:hover {
      background-color: ${COLORS2.sub.MrtGray3};
      border-radius: 18px;
    }
  `,
};
export const Header3Desktop = (props: {
  withoutFullRefresh?: boolean,
}) => {
  const navigate = useNavigate();
  return (
    <header className={classNames.headerWrapper}>
      <button
        type="button"
        onClick={() => {
          if (props.withoutFullRefresh) {
            typeRouter.routes.home2().push();
          } else {
            window.location.href = window.location.origin;
            window.scrollTo(0, 0);
          }
        }}
      >
        <img
          src={MRT_LOGO}
          alt="trimo-logo"
          className={
            css`
              width: 225px;
              height: 28px;
            `
          }
        />
      </button>

      <div className={classNames.navButtonWrapper}>
        {
          NAVIGATION_MRT.map((nav) => (
            <button
              key={nav.name}
              type="button"
              className={classNames.navButton}
              onClick={() => {
                if (nav.name === '예약조회') {
                  // typeRouter.routes.checkReservation().push();
                  navigate(nav.url);
                }
                if (nav.name === 'FAQ') {
                  // typeRouter.routes.help().push();
                  navigate(nav.url);
                }
                window.scrollTo(0, 0);
              }}
            >
              {nav.name}
            </button>
          ))
        }
      </div>
    </header>
  );
};
