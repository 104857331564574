import { action, makeAutoObservable } from 'mobx';

export interface IInputValue<T, TFlags = unknown> {
  value: T;
  setIsFocused(focused: boolean): void;
  setHasBlurred(blurred: boolean): void;
  set(value: T, flags?: TFlags): void;
}
export class MobxInputValue<T, TFlags = unknown> implements IInputValue<T, TFlags> {
  constructor(
    public value: T,
    public onChange: (
      (value: T, flags?: TFlags) => T | void
    ) | null = null,
    public hasBlurred = false,
    public hasEdited = false,
    public isFocused = false,
  ) {
    makeAutoObservable(this, {
      set: action.bound,
      setHasBlurred: action.bound,
    });
  }

  copyFrom(other: MobxInputValue<T>) {
    this.value = other.value;
  }

  clone() {
    return new MobxInputValue<T, TFlags>(
      this.value,
      this.onChange,
      this.hasBlurred,
      this.hasEdited,
      this.isFocused,
    );
  }

  set(value: T) {
    this.hasEdited = true;
    if (this.onChange) {
      const result = this.onChange(value);
      if (result !== undefined && result !== null) {
        this.value = result;
        return;
      }
    }
    this.value = value;
  }

  setHasBlurred(hasBlurred: boolean = true) {
    this.hasBlurred = hasBlurred;
    if (hasBlurred) {
      setTimeout(() => {
        this.setIsFocused(false);
      }, 100);
    }
  }

  setIsFocused(isFocused: boolean = true) {
    this.isFocused = isFocused;
  }
}

export type ExtractIInputValue<T> = T extends IInputValue<infer Value> ? {
  value: Value,
} : never;
