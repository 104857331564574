import itiriri from 'itiriri';
import { useTranslation } from 'react-i18next';

import { CarListItemType } from '^/api2/getCarList/getCarList.convert';
import { ExtractMap } from '^/types/utils/ExtractMap';

// eslint-disable-next-line no-template-curly-in-string
const NUMBER_PLACEHOLDER = '${num}';

type TranslationFuncType = (n: string) => string;
function getServiceInfoString(
  service: ExtractMap<CarListItemType['services']>['value'],
  t: TranslationFuncType,
) {
  const infoAsNum = Number(service.info);
  if (infoAsNum) {
    if (t(service.shortName).includes(NUMBER_PLACEHOLDER)) {
      return '';
    }
    return t(service.info);
  }
  return t(service.info);
}
export function translateServices(
  services: CarListItemType['services'],
  // FIXME: use ReturnType<typeof useTranslation>['t']
  //    after upgrading react-i18next version to 12
  t: TranslationFuncType,
) {
  const b = itiriri(services.values())
    .filter((service) => (
      service.info && service.info !== 'undefined'
    ) || false)
    .map((service) => {
      const shortName = t(service.shortName)
        .replace(
          NUMBER_PLACEHOLDER,
          service.info,
        );
      return `${getServiceInfoString(service, t)} ${shortName}`;
    });
  return b.toArray();
}
