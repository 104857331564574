import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Buffer } from 'buffer';
import { resetHistoryStateOnPageLoad } from './historyFixes';

resetHistoryStateOnPageLoad();
// @ts-ignore
window.Buffer = Buffer;
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import AppContainer from './AppContainer';
import './main.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppContainer />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
