import { MouseEvent } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { ReactComponent as ArrowLeft } from '^/assets/icons/ic_arrow_left_32px.svg';
import { ReactComponent as Xbtn } from '^/assets/icons/ic_x_black_32.svg';
import { font, SPACING } from '^/constant/CommonStyles2';
import {
  BaseSearchBoxStepManager,
} from '^/domainComponents/forms/CarListingSearchBox/SearchBoxStepManager';
import { typeRouter } from '^/typeRoute/typeRoutes';

export const CarListingBottomSheetHeader: React.FC<{
  title: string;
  stepManager: BaseSearchBoxStepManager;
  leftCornorBtnType: 'close' | 'prevStack';
  overideOnLeftButtonClick?: (e: MouseEvent) => void;
}> = observer(function CarListingBottomSheetHeader({
  title,
  stepManager,
  leftCornorBtnType,
  overideOnLeftButtonClick,
}) {
  return (
    <header
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;

        min-height: ${SPACING.spacing56};

        padding: 0 ${SPACING.spacing24};
      `}
    >
      {
        leftCornorBtnType === 'close'
          ? (
            <ArrowLeft
              onClick={
                overideOnLeftButtonClick
                  || (() => stepManager.clearStep())
              }
            />
          )
          : (
            <Xbtn
              onClick={
                overideOnLeftButtonClick
                || (() => typeRouter.session.back())
              }
            />
          )
      }
      <h1
        className={cx(
          font.Headline1_Bold,
          css`
            margin-left: -${SPACING.spacing24};
        `)}
      >
        {title}
      </h1>
      <div></div>
    </header>
  );
});
