import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import Map from '^/components/Map';
import Pin from '^/components/Map/Pin';
import { borders } from '^/constant/CommonStyles';

import { RentalOfficeInputViewModel } from '../RentalOfficeInput.viewmodel';

export const GoogleMap: React.FC<{
  viewmodel: RentalOfficeInputViewModel
}> = observer(function GoogleMap({
  viewmodel,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const office = viewmodel.hoveredOfficeData
    || viewmodel.selectedOfficeData;
  const officePosition = office && {
    lat: Number(office.latitude),
    lng: Number(office.longitude),
  };

  return (
    <div className={cx(
      css`
          position: relative;
          /* top: -24px;  */
          flex-basis: 33.3%;
          height: 100%;
          margin-top: auto;

          @media (max-width: 768px) {
            top: 0; right: 0;
          }
        `,
      // borders.DEBUG_redBorderDashed,
    )}
    >
      <Map
        containerStyle={{ width: '100%', height: isMobile ? '318px' : '100%' }}
        center={officePosition || undefined}
        zoom={17}
      >
        <Pin
          {...officePosition}
          place={{}}
          type={viewmodel.selectedCategory.value || 'LDT'}
        />
      </Map>
    </div>
  );
},
);
