import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as CautionGray } from '^/assets/icons/ic_info_gray.svg';
import { CalendarStateViewModel } from '^/components/calendar2/Calendar.state.viewmodel';
import { getWeekDayFromDate } from '^/components/calendar2/Day/DayType';
import { CalendarListViewModel } from '^/components/calendar2/List/Calendar.List.viewmodel';
import { borders } from '^/constant/CommonStyles';
import {
  COLORS2, FLEX, font, SPACING,
} from '^/constant/CommonStyles2';
import { ScheduleTimeViewModel } from '^/domainComponents/forms/CarListingSearchBox/ScheduleTimeViewModel';
import { BaseSearchBoxStepManager } from '^/domainComponents/forms/CarListingSearchBox/SearchBoxStepManager';
import { OpenCloseTime, OpenCloseTimes } from '^/types/RawOpenHours';
import { MobxInputValue } from '^/util/MobxInputValue';

import { TimeSelect } from '../TimeSelect/TimeSelect';
import {
  TimeSelectHourNullable,
  TimeSelectMinuteNullable,
} from '../TimeSelect/TimeSelect.constants';

const classNames = {
  title: cx(
    css`
      display: flex;
      align-items: center;
      white-space: nowrap;
    `,
    font.Headline1_Bold,
  ),
};
export const CalendarWithTimeChoice: React.FC<{
  calendarListViewModel: CalendarListViewModel,
  calendarStateViewModel: CalendarStateViewModel,
  stepManager: BaseSearchBoxStepManager,
  onAllDateSelected(args: {
    startDate: Date,
    endDate: Date,
  }): void,
  scheduleTimeViewModel: ScheduleTimeViewModel,
  pickupOpenCloseTimes: OpenCloseTimes,
  returnOpenCloseTimes: OpenCloseTimes,
  onSubmit: () => void,
}> = observer(function CalendarWithTimeChoice(props) {
  const isCalendarWidthTimeMaxSize = useMediaQuery({ query: '(max-width: 1240px)' });
  const stateViewModel = props.calendarStateViewModel;
  // eslint-disable-next-line prefer-destructuring
  const startDate = stateViewModel.startDate;

  // eslint-disable-next-line prefer-destructuring
  const endDate = stateViewModel.endDate;

  return (
    <div
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;

          width: 100%;
          height: 100%;
          `,
        isCalendarWidthTimeMaxSize && css`
            height: auto;
        `,
        // borders.DEBUG_blueBorderDashed,
      )}
    >
      <div className={cx(
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          
          gap: ${SPACING.spacing24};

          height: calc(100% - 56px);
          padding: 0 ${SPACING.spacing24};
      `)}
      >
        <div
          id="pickup-time"
          className={cx(
            css`
              display: flex;
              gap: ${SPACING.spacing24};
            `,
            !startDate && css`
              opacity: 0.4;
            `,
            // borders.DEBUG_goldDashed,
          )}
        >
          <span className={classNames.title}>
            대여 시간
          </span>

          <div className={css`
            flex: 1 1 0;
          `}
          >
            <TimeSelect
              disabled={!startDate}
              hour={props.scheduleTimeViewModel.pickupHour}
              minute={props.scheduleTimeViewModel.pickupMinute}
              openCloseTimes={(startDate
                && props
                  .pickupOpenCloseTimes[
                    getWeekDayFromDate(startDate)
                  ]
              ) || []}
            />
          </div>
        </div>
        <div
          id="return-time"
          className={cx(
            css`
              display: flex;
              gap: ${SPACING.spacing24};
            `,
            !endDate && css`
              opacity: 0.4;
            `,
          )}
        >
          <span className={classNames.title}>
            반납 시간
          </span>
          <div className={css`
            flex: 1 1 0;
          `}
          >

            <TimeSelect
              disabled={!endDate}
              hour={props.scheduleTimeViewModel.returnHour}
              minute={props.scheduleTimeViewModel.returnMinute}
              openCloseTimes={(endDate
                && props
                  .returnOpenCloseTimes[
                    getWeekDayFromDate(endDate)
                  ]
              ) || []}
            />
          </div>
        </div>
        <div className={cx(
          font.Caption1_Light,
          css`
            text-align: center;
            color: ${COLORS2.sub.MrtGray1};
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${SPACING.spacing8};
          `,
        )}
        >
          <div style={{ marginBottom: '20px' }}>
            <CautionGray />
          </div>
          <div className={css`
            display: flex;
            flex-direction: column;
          `}
          >
            <span>시간 선택은 영업소 오픈 시간만 선택 가능하며, </span>
            <span>1년 이내 예약만 할 수 있어요. </span>
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={() => {
          if (startDate && endDate) {
            props.onAllDateSelected({
              startDate,
              endDate,
            });
          }
          props.onSubmit();
        }}
        className={cx(
          FLEX.allDirectionCenter,
          css`
            height: ${SPACING.spacing56};
            width: 100%;

            border-radius: 10px;
            background-color: ${startDate && endDate
            ? COLORS2.main.MrtBlue1
            : COLORS2.main.MrtBlue2
            } !important;
            color: ${COLORS2.main.MrtWhite};
          `,
          isCalendarWidthTimeMaxSize && css`
            margin-top: ${SPACING.spacing24};
        `,
        )}
      >적용
      </button>
    </div>
  );
});
